import axios from 'axios';
import _ from 'lodash';

require('dotenv').config();

const BaseUrl = process.env.REACT_APP_BASE_URL;

const Http = {
  async init() {
    axios.defaults.baseURL = BaseUrl;

    const token = await localStorage.getItem('kewirus_t');

    // Intercept the request to make sure the token is injected into the header.
    await axios.interceptors.request.use((config) => {
      // we intercept axios request and add authorizatio header
      // before perform send a request to the server
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    // Intercept the response and…
    await axios.interceptors.response.use(
      async (response) => {
        // …get the token from the header or response data if exists, and save it.
        const tok = response.headers.Authorization || _.get(response, 'data.data.token', null);
        // const user = response.data;

        tok && (await localStorage.setItem('kewirus_t', tok));
        // user && (await localStorage.setItem('kewirus_u', JSON.stringify(user)));

        return response;
      },
      async (error) => {
        // Also, if we receive Unauthorized error
        if (error.message !== 'Network Error') {
          if (error.response.status === 401) {
            // revoke token
            await localStorage.removeItem('kewirus_t');
            await localStorage.removeItem('kewirus_u');
          } else if (error.response.status === 500) {
            // if we receive server error
          }
        }

        return Promise.reject(error);
      }
    );
  },

  async request(
    method,
    url,
    data,
    successCb = null,
    errorCb = null,
    headers = {}
  ) {
    await this.init();

    const response = axios
      .request({
        url,
        data,
        method,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...headers
        }
      })
      .then(successCb)
      .catch(errorCb);

    return response;
  },

  get(url, data, successCb = null, errorCb = null) {
    return this.request('GET', url, data, successCb, errorCb, {});
  },

  post(url, data, successCb = null, errorCb = null, headers = {}) {
    return this.request('POST', url, data, successCb, errorCb, headers);
  },

  postUpload(
    url,
    data,
    successCb = null,
    errorCb = null,
    headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json'
    }
  ) {
    return this.request('POST', url, data, successCb, errorCb, headers);
  },

  patch(url, data, successCb = null, errorCb = null, headers = {}) {
    return this.request('PATCH', url, data, successCb, errorCb, headers);
  },

  put(url, data, successCb = null, errorCb = null, headers = {}) {
    return this.request('PUT', url, data, successCb, errorCb, headers);
  },

  delete(url, data, successCb = null, errorCb = null, headers = {}) {
    return this.request('DELETE', url, data, successCb, errorCb, headers);
  }
};

export default Http;
