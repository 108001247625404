import React from 'react';
import _ from 'lodash';
import parse from 'html-react-parser';

import './styles.scss';

import ApiEvent from '../../services/api/event';

import {
  SearchBar,
  NavTabs,
  PageTitle,
  CardList,
} from '../../components';

import AppLogo from '../../assets/app-logo.png';

class Events extends React.Component {
  constructor(props) {
    super(props);
    const user = localStorage.getItem('kewirus_u');

    this.state = {
      user: JSON.parse(user),
      myEvent: {
        page: 1,
        totalPage: 0,
        total: 0,
        isLoading: true,
      },
      incomingEvent: {
        page: 1,
        totalPage: 0,
        total: 0,
        data: [],
        isLoading: false
      },
      recentEvent: {
        page: 1,
        totalPage: 0,
        total: 0,
        data: [],
        isLoading: false
      },
      sectionSelected: 0,
      title: 'My Events',
      query: '',
      subtitleTotal: 0,
      sections: [
        {
          title: 'Incoming event',
          items: _.range(0, 3),
          renderItems: (it) => this._renderIncomingEvents(it),
        },
        {
          title: 'My event',
          items: _.range(0, 3),
          renderItems: (it) => this._rendermyEvents(it),
        },
      ],

      alertShow: false,
      alertMessage: '',
      alertType: 'error',
    };
  }

  componentDidMount() {
    const location = _.get(this.props, 'location');
    const history = _.get(this.props, 'history');
    if (!_.isNil(location.state)) {
      this.setState({
        alertMessage: location.state.alertMessage,
        alertType: location.state.alertType,
        alertShow: location.state.alertShow
      });

      history.replace(location.pathname, null);
    }
    this._getIncomingEvents();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.alertShow === true) {
      // eslint-disable-next-line react/no-did-update-set-state
      setTimeout(() => {
        this.setState({
          alertShow: false
        });
      }, 3000);
    }
  }

  _handleSearch = ({ target }) => {
    const { value: query } = target;
    this.setState({ query });

    // - search events
    switch (this.state.sectionSelected) {
    case 0:
      this._getMyEvents(query);
      break;
    case 1:
      this._getIncomingEvents(query);
      break;
    default:
      this._getRecentEvents(query);
      break;
    }
  }

  _handleEventItemClicked = (e, item, actionType) => {
    let route;
    e.stopPropagation();
    if (_.isEqual(actionType, 'detail')) route = `/event/detail/${item.slug}`;
    else if (_.isEqual(actionType, 'edit')) route = `/event/edit/${item.slug}`;
    else {
      ApiEvent.deleteEvent(item.slug)
        .then(() => {
          this.setState({
            alertShow: true,
            alertType: 'success',
            alertMessage: `Event dengan nama ${item.name} berhasil dihapus!`
          });

          this._getMyEvents();
        })
        .catch((_e) => {
          const { response } = _e;
          const { message } = response.data;

          const msg = message.map((_msg) => `<li>${_msg.message}</li>`).join('');

          this.setState({
            alertShow: true,
            alertType: 'error',
            alertMessage: `Delete event failed, because of: <br/>${msg}`,
          });
        });
      return;
    }

    // eslint-disable-next-line react/prop-types
    this.props.history.push(route, {
      isCreatorEvent: _.get(item, 'isCreatorEvent'),
      attend: _.get(item, 'attend')
    });
  }

  _getRecentEvents = (query = '') => {
    const page = _.get(this.state, 'recentEvent.page');
    this.setState((prevState) => ({
      recentEvent: {
        ...prevState.recentEvent,
        isLoading: true,
      }
    }));

    ApiEvent.getEvents(
      page,
      query,
      3,
      'updatedAt',
      true
    )
      .then((res) => {
        const data = _.get(res, 'data.data');
        const totalPage = _.get(data, 'meta.last_page');
        const total = _.get(data, 'meta.total');

        this.setState((prevState) => ({
          recentEvent: {
            page,
            totalPage,
            total,
            data,
            isLoading: false
          },
          title: 'Recent Events',
          subtitleTotal: total,
          sections: [
            prevState.sections[0],
            prevState.sections[1],
            {
              ...prevState.sections[2],
              items: data.data
            },
          ]
        }));
      });
  }

  _getMyEvents = (query = '') => {
    const page = _.get(this.state, 'myEvent.page');
    this.setState((prevState) => ({
      myEvent: {
        ...prevState.myEvent,
        isLoading: true,
      },
    }));

    ApiEvent.getMyEvents(page, query)
      .then((res) => {
        const data = _.get(res, 'data.data');
        const lastPage = _.get(data, 'meta.last_page_url').split('/?page=');
        const totalPage = Number(lastPage[1]);
        const total = _.get(data, 'meta.total');

        this.setState((prevState) => ({
          myEvent: {
            page,
            totalPage,
            total,
            data,
            isLoading: false
          },
          title: 'My Events',
          subtitleTotal: total,
          sections: [
            prevState.sections[0],
            {
              ...prevState.sections[1],
              items: data.data
            },
          ]
        }));
      });
  }

  _getIncomingEvents = (query = '') => {
    const page = _.get(this.state, 'incomingEvent.page');
    this.setState((prevState) => ({
      incomingEvent: {
        ...prevState.incomingEvent,
        isLoading: true,
      },
    }));

    ApiEvent.getEvents(page, query)
      .then((res) => {
        const data = _.get(res, 'data.data');
        const totalPage = _.get(data, 'meta.last_page');
        const total = _.get(data, 'meta.total');

        this.setState((prevState) => ({
          incomingEvent: {
            page,
            totalPage,
            total,
            data,
            isLoading: false
          },
          title: 'Incoming Events',
          subtitleTotal: total,
          sections: [
            {
              ...prevState.sections[0],
              items: data.data
            },
            prevState.sections[1],
          ]
        }));
      });
  }

  _handleAddEvent = () => {
    // eslint-disable-next-line react/prop-types
    this.props.history.push('/event/new');
  };

  _renderRecentEvents = (it) => (
    <CardList
      {...this.props}
      items={it}
      type="event"
      isLoading={this.state.recentEvent.isLoading}
      page={this.state.recentEvent.page}
      totalPage={this.state.recentEvent.totalPage}
      onItemClick={(_e, item) => {
        // eslint-disable-next-line react/prop-types
        this.props.history.push(`/event/detail/${item.slug}`, {
          isCreatorEvent: _.get(item, 'isCreatorEvent'),
          attend: _.get(item, 'attend')
        });
      }}
      onNextPage={() => {
        this.setState((prevState) => ({
          recentEvent: {
            ...prevState.recentEvent,
            page: prevState.recentEvent.page + 1,
          },
        }), this._getRecentEvents);
      }}
      onPageNumberChanged={(_page) => {
        this.setState((prevState) => ({
          recentEvent: {
            ...prevState.recentEvent,
            page: _page,
          },
        }), this._getRecentEvents);
      }}
      onPreviousPage={() => {
        this.setState((prevState) => ({
          recentEvent: {
            ...prevState.recentEvent,
            page: prevState.recentEvent.page - 1,
          },
        }), this._getRecentEvents);
      }}
    />
  )

  _renderIncomingEvents = (it) => (
    <CardList
      {...this.props}
      items={it}
      type="event"
      isLoading={this.state.incomingEvent.isLoading}
      page={this.state.incomingEvent.page}
      totalPage={this.state.incomingEvent.totalPage}
      onItemClick={this._handleEventItemClicked}
      onNextPage={() => {
        this.setState((prevState) => ({
          incomingEvent: {
            ...prevState.incomingEvent,
            page: prevState.incomingEvent.page + 1,
          },
        }), this._getIncomingEvents);
      }}
      onPageNumberChanged={(_page) => {
        this.setState((prevState) => ({
          incomingEvent: {
            ...prevState.incomingEvent,
            page: _page,
          },
        }), this._getIncomingEvents);
      }}
      onPreviousPage={() => {
        this.setState((prevState) => ({
          incomingEvent: {
            ...prevState.incomingEvent,
            page: prevState.incomingEvent.page - 1,
          },
        }), this._getIncomingEvents);
      }}
    />
  )

  _rendermyEvents = (it) => (
    <CardList
      {...this.props}
      items={it}
      type="event"
      page={this.state.myEvent.page}
      isLoading={this.state.myEvent.isLoading}
      totalPage={this.state.myEvent.totalPage}
      onItemClick={this._handleEventItemClicked}
      onNextPage={() => {
        this.setState((prevState) => ({
          myEvent: {
            ...prevState.myEvent,
            page: prevState.myEvent.page + 1,
          },
        }), this._getMyEvents);
      }}
      onPageNumberChanged={(_page) => {
        this.setState((prevState) => ({
          myEvent: {
            ...prevState.myEvent,
            page: _page,
          },
        }), this._getMyEvents);
      }}
      onPreviousPage={() => {
        this.setState((prevState) => ({
          myEvent: {
            ...prevState.myEvent,
            page: prevState.myEvent.page - 1,
          },
        }), this._getMyEvents);
      }}
    />
  );

  render() {
    const { sections } = this.state;

    return (
      <div className="col container-page-kewirus">
        {
          this.state.alertShow && (
            <div
              className={`alert ${_.isEqual(this.state.alertType, 'error') ? 'alert-danger' : 'alert-success'} alert-dismissible fade show`}
              role="alert"
              style={{
                position: 'fixed',
                top: '10%',
                zIndex: '10'
              }}
            >
              <p>
                {parse(this.state.alertMessage)}
              </p>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ alertMessage: '', alertShow: false })}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )
        }
        {/* Search Bar and Logo */}
        <div className="row">
          {/* Search Bar */}
          <div className="kewirus-desktop-only col-6 mb-5">
            <SearchBar
              onSearch={this._handleSearch}
            />
          </div>
          {/* Logo */}
          <a href="/" className="kewirus-desktop-only col-auto mb-5 ml-auto">
            <img src={AppLogo} className="app-logo" alt="Cabin Crew Class Logo" />
          </a>
        </div>

        {/* Title */}
        <div className="row mx-0">
          <PageTitle
            title={this.state.title}
            subtitle={`${this.state.subtitleTotal} events`}
          />

        </div>
        {/* Search Bar Mobile Only */}
        <div className="row kewirus-mobile-only">
          {/* Search Bar */}
          <div className="col-12 mt-3 mb-4">
            <SearchBar onSearch={this._handleSearch} />
          </div>
        </div>

        {/* Nav Tabs and Button Add Classes */}
        <div className="row">
          {/* Nav Tabs */}
          <div className="col-12">
            <NavTabs
              sections={sections}
              onTabClick={(e, idx) => {
                const { query } = this.state;
                switch (idx) {
                case 1:
                  this._getMyEvents(query);
                  break;
                case 0:
                  this._getIncomingEvents(query);
                  break;
                default:
                  this._getRecentEvents(query);
                  break;
                }

                this.setState({ sectionSelected: idx });
              }}
            />
          </div>
          {/* Button */}
          <button
            type="button"
            className="btn btn-kewirus btn-add-new"
            onClick={this._handleAddEvent}
            style={{ display: _.get(this.state, 'user.kewirusType', '') !== 'admin' ? 'none' : '' }}
          >
            + Add Event
          </button>
        </div>
        {/* Project Invitation */}
        <div className="row" />
        {/* Total Pending Invitation */}
        <div className="row" />
        {/* Card Project Invitation */}
        <div className="row" />
      </div>
    );
  }
}

export default Events;
