import React from 'react';
import PropType from 'prop-types';

import './styles.scss';

const Loading = ({ loading, ...props }) => (
  loading && (
    <div className="progress-bar" {...props}>
      <div className="line" />
      <div className="block-line inc" />
      <div className="block-line dec" />
    </div>
  )
);

Loading.propTypes = {
  loading: PropType.bool.isRequired
};

export default Loading;
