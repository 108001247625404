/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import _ from 'lodash';
import parse from 'html-react-parser';

import './styles.scss';

import ApiUser from '../../services/api/auth';
import ApiFile from '../../services/api/file';
import ApiTrx from '../../services/api/transaction';
import ApiMisc from '../../services/api/misc';

import {
  NavTabs, PageTitle, Card, Table, Modal
} from '../../components';

import AppLogo from '../../assets/app-logo.png';
import UserThumbnail from '../../assets/user-no-image.png';
import {
  validateEmail,
  validatePhoneNumber,
  validateStringLen,
} from '../../utils';

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '');

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // some state here!
      provinces: [],
      cities: [],
      user: {},
      provinceId: null,
      code: '',
      file: null,
      imageBase64: null,
      // trx histories
      trxTableCols: [
        {
          name: 'Item Name',
          width: '18%',
        },
        {
          name: 'Order ID',
          width: '17%',
        },
        {
          name: 'Item Type',
          width: '15%',
        },
        {
          name: 'Item Price',
          width: '16%',
        },
        {
          name: 'Status',
          width: '10%',
        },
        {
          name: 'Paid Time',
          width: '14%',
        },
        {
          name: 'Action',
          width: '10%',
        },
      ],
      page: 1,
      totalPage: 1,
      // navtabs sections
      sections: [
        {
          title: 'Personal Info',
          items: [{}],
          renderItems: () => this._renderPersonalInfoTab(),
        },
        {
          title: 'Transaction Histories',
          items: [[]],
          renderItems: (it) => this._renderTrxHistoriesTab(it[0]),
        },
        {
          title: 'Change Password',
          items: [{}],
          renderItems: () => this._renderChangePassTab(),
        },
      ],

      isDisplayNameValidated: true,
      isEmailValidated: true,
      isPhoneValidated: true,
      isAddressValidated: true,
      isPassValidated: null,
      isGenderValidated: null,
      isProvinceValidated: null,
      isCityValidated: null,
      isInstitutionValidated: null,
      isConfirmPassValidated: null,
      // isRatePriceValidated: null,
      isMentorAvailableScheduleValidated: null,
      isMentorTitleValidated: null,
      isMentorQuotesValidated: null,
      isResetPass: false,

      alertShow: false,
      alertMsg: '',
      alertType: '',

      showModal: false,

      isPurchaseLoading: false,
    };
  }

  componentDidMount() {
    const u = localStorage.getItem('kewirus_u');
    const qp = new URLSearchParams(_.get(this.props, 'location.search'));
    const code = qp.get('code');
    const isResetPass = qp.get('resetpass') === 'true';
    const { sections: oldSections } = this.state;
    let sections = oldSections;
    const user = JSON.parse(u);
    const userType = _.get(user, 'kewirusType');

    if (!isResetPass) {
      sections = _.dropRight(oldSections, 1);
    } else {
      sections = _.drop(oldSections, 2);
    }

    if (user.provinceId) this._getCitiesById(user.provinceId);

    this.setState({
      ...user.mentor,
      user,
      provinceId: user.provinceId,
      sections,
      code,
      isResetPass,
      isMentorAvailableScheduleValidated:
        userType === 'mentor'
        && !_.isEmpty(_.get(user, 'mentor.available_schedule')),
      isMentorQuotesValidated:
        userType === 'mentor' && !_.isEmpty(_.get(user, 'mentor.description')),
      isMentorTitleValidated:
        userType === 'mentor' && !_.isEmpty(_.get(user, 'mentor.title')),
      isGenderValidated: !_.isNull(user.gender),
      isInstitutionValidated: !_.isNull(user.schoolName),
      isCityValidated: !_.isNil(user.cityId),
      // isRatePriceValidated: userType === 'mentor' && user.ratePrice <= 0 ? null : true
    });
    this._getProvinces();
    this.getPackages();
  }

  async componentDidUpdate(_prevProps, prevState) {
    // update profile after save profile
    if (_.isEqual('success', this.state.alertType)) {
      const { data } = await ApiUser.getUser();
      const usr = data.data;

      await localStorage.setItem('kewirus_u', JSON.stringify(usr));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    if (prevState.page !== this.state.page) this.getTrxHistories();
    // - reset pass changed
    const { isResetPass } = prevState;
    if (isResetPass && !this.state.isResetPass) {
      const sections = [
        {
          title: 'Personal Information',
          items: [{}],
          renderItems: () => this._renderPersonalInfoTab(),
        },
        {
          title: 'Transaction Histories',
          items: [],
          renderItems: (it) => this._renderTrxHistoriesTab(it[0]),
        },
      ];
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        sections,
      });
    }
  }

  _getProvinces = () => {
    ApiMisc.getProvinces().then((res) => {
      const provinces = _.get(res, 'data.data');
      this.setState({
        provinces,
      });
    });
  };

  _getCitiesById = (id) => {
    ApiMisc.getCitiesByProvinceId(id).then((res) => {
      const cities = _.get(res, 'data.data');
      this.setState({
        cities,
      });
    });
  };

  getPackages = async () => {
    const res = await ApiTrx.getSubcriptionPackages();
    const data = _.get(res, 'data.data');
    this.setState({
      packages: data,
    });
  };

  getTrxHistories = async () => {
    const { page } = this.state;
    const { data } = await ApiTrx.getTransactionHistories(page);
    const trxHistories = _.get(data, 'data.data');
    const totalPage = _.get(data, 'data.meta.last_page');
    const sections = _.get(this, 'state.sections');

    _.set(sections[1], 'items', [trxHistories]);
    this.setState({
      sections,
      totalPage,
    });
  };

  _handleInputTextChanged = (e, field) => {
    let { value } = e.target;

    if (_.isEqual(field, 'email')) {
      const validate = validateEmail(value);
      this.setState({
        isEmailValidated: validate,
      });
    } else if (_.isEqual(field, 'displayName')) {
      const validate = validateStringLen(value, 3);
      this.setState({
        isDisplayNameValidated: validate,
      });
    } else if (_.isEqual(field, 'phoneNumber')) {
      const validate = validatePhoneNumber(value);
      this.setState({
        isPhoneValidated: validate,
      });
    } else if (_.isEqual(field, 'address')) {
      const validate = !_.isEmpty(value);
      this.setState({
        isAddressValidated: validate,
      });
    } else if (_.isEqual(field, 'password')) {
      const validate = validateStringLen(value, 8);
      this.setState({
        isPassValidated: validate,
        isConfirmPassValidated: false,
      });
    } else if (_.isEqual(field, 'confirmPass')) {
      const { password } = this.state.user;
      const validate = _.isEqual(password, value);

      this.setState({
        isConfirmPassValidated: validate,
      });
    } else if (_.isEqual(field, 'ratePrice')) {
      const valueInNumber = Number(value.split(',').join(''));
      // const validate = valueInNumber > 0;
      const { user } = this.state;
      _.set(user, 'ratePriceStr', addCommas(removeNonNumeric(valueInNumber)));
      value = valueInNumber;

      this.setState({
        // isRatePriceValidated: validate,
        user,
      });
    } else if (_.isEqual(field, 'title')) {
      const validated = !_.isEmpty(value);
      const { user } = this.state;
      user.mentor[field] = value;

      this.setState({
        isMentorTitleValidated: validated,
        user,
      });

      return;
    } else if (_.isEqual(field, 'description')) {
      const validated = !_.isEmpty(value);
      const { user } = this.state;
      user.mentor[field] = value;

      this.setState({
        isMentorQuotesValidated: validated,
        user,
      });

      return;
    } else if (_.isEqual(field, 'available_schedule')) {
      const validated = !_.isEmpty(value);
      const { user } = this.state;
      user.mentor[field] = value;

      this.setState({
        isMentorAvailableScheduleValidated: validated,
        user,
      });
      return;
    } else if (
      _.isEqual(field, 'instagram')
      || _.isEqual(field, 'linkedin')
      || _.isEqual(field, 'portofolio')
    ) {
      const { user } = this.state;
      user.mentor[field] = value;

      this.setState({ user });
      return;
    } else if (_.isEqual(field, 'gender')) {
      const validated = !_.isEmpty(value);
      this.setState({
        isGenderValidated: validated,
      });
    } else if (_.isEqual(field, 'schoolName')) {
      const validated = !_.isEmpty(value);
      this.setState({
        isInstitutionValidated: validated,
      });
    }

    this.setState({
      user: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.user,
        [field]: value,
      },
    });
  };

  _handleRequestResetLink = () => {
    const { user } = this.state;
    const email = _.get(user, 'email');

    ApiUser.forgotPass(email)
      .then((res) => {
        const { data } = res;
        const message = _.get(data, 'message');
        const alertMsg = message
          .map((msg) => `<li>${msg.message}</li>`)
          .join('');

        this.setState({
          alertType: 'success',
          alertShow: true,
          alertMsg,
        });
      })
      .catch((e) => {
        let alertMsg = _.get(e, 'response.data.message');
        alertMsg = alertMsg.map((msg) => `<li>${msg.message}</li>`);
        alertMsg = alertMsg.join('');

        this.setState({
          alertMsg: `Failed change to new password ${alertMsg}`,
          alertShow: true,
          alertType: 'error',
        });
      });
  };

  _handleDeleteUser = () => {
    const { email } = this.state.user;
    ApiUser.deleteUser(email)
      .then((res) => {
        const { data } = res;
        const message = _.get(data, 'message');
        const alertMsg = message.map((msg) => `${msg.message}`).join('');

        this.setState({
          alertType: 'success',
          alertShow: true,
          alertMsg: `${alertMsg}. Auto redirect to login page in 2 seconds...`,
          showModal: false,
        });

        setTimeout(() => {
          this.props.history.push('/login'); // eslint-disable-line react/prop-types
        }, 2000);
      })
      .catch((e) => {
        let alertMsg = _.get(e, 'response.data.message');
        if (alertMsg instanceof Array) {
          alertMsg = alertMsg.map((msg) => `<li>${msg.message}</li>`);
          alertMsg = alertMsg.join('');
        }
        this.setState({
          alertMsg,
          alertShow: true,
          alertType: 'error',
          showModal: false,
        });
      });
  };

  _handleResetPass = () => {
    const { code, user } = this.state;
    ApiUser.resetPass(code, user.password)
      .then(() => {
        // eslint-disable-next-line react/prop-types
        this.props.history.replace({
          pathname: '/profile',
          query: null,
        });

        setTimeout(() => {
          this.setState({
            alertMsg: 'Success reset password!',
            alertType: 'success',
            alertShow: true,
            isResetPass: false,
          });
        }, 1000);
      })
      .catch((e) => {
        let alertMsg = _.get(e, 'response.data.message');
        alertMsg = alertMsg.map((msg) => `<li>${msg.message}</li>`);
        alertMsg = alertMsg.join('');

        this.setState({
          alertMsg: `Failed change to new password ${`${alertMsg}`}`,
          alertShow: true,
          alertType: 'error',
        });
      });
  };

  _handleSaveProfile = async () => {
    const { user, file } = this.state;
    const {
      email,
      phoneNumber,
      displayName: name,
      lastEducation,
      address,
      photoUrl,
      ratePrice,
      mentor,
      cityId,
      gender,
      schoolName,
    } = user;

    // upload profile picture first
    let imageUrl;
    if (!_.isNull(file)) {
      try {
        imageUrl = await ApiFile.uploadFile(file);
        imageUrl = _.get(imageUrl, 'data.data');
      } catch (e) {
        let alertMsg = _.get(e, 'response.data.message');
        alertMsg = alertMsg.map(
          (msg) => `<li>field: ${msg.field}, msg: ${msg.message}</li>`
        );
        alertMsg = alertMsg.join('');

        this.setState({
          alertMsg,
          alertShow: true,
          alertType: 'error',
        });
        return;
      }
    }

    // update profile
    ApiUser.updateProfile({
      email,
      name,
      phoneNumber,
      address,
      cityId,
      gender,
      schoolName,
      lastEducation,
      image: _.isNil(imageUrl) ? photoUrl : imageUrl,
      ratePrice,
      instagram: _.get(mentor, 'instagram'),
      linkedin: _.get(mentor, 'linkedin'),
      portofolio: _.get(mentor, 'portofolio'),
      title: _.get(mentor, 'title'),
      description: _.get(mentor, 'description'),
      availableSchedule: _.get(mentor, 'available_schedule'),
    })
      .then(async () => {
        // callback when success
        this.setState({
          alertMsg: 'Your profile has been successfully updated!',
          alertShow: true,
          alertType: 'success',
        });
      })
      .catch((e) => {
        let alertMsg = _.get(e, 'response.data.message');
        alertMsg = alertMsg.map((msg) => `<li>${msg.message}</li>`);
        alertMsg = alertMsg.join('');

        this.setState({
          alertMsg,
          alertShow: true,
          alertType: 'error',
        });
      });
  };

  _handlePurchaseSubcrip = (_package) => {
    const { code } = _package;
    // - disable button
    this.setState({
      isPurchaseLoading: true,
    });

    // hit api purchase subs
    ApiTrx.subscribeMaterial(code)
      .then((res) => {
        const token = _.get(res, 'data.data');
        const component = this;
        // - enable button
        this.setState({
          isPurchaseLoading: false,
        });
        // - show midtrans popup client
        window.snap.pay(token, {
          onSuccess: (result) => {
            component.setState({
              alertShow: true,
              alertType: 'success',
              alertMsg: _.get(result, 'status_message'),
            });

            // - replace to courses
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          },
          onError(result) {
            component.setState({
              alertType: 'error',
              alertMsg: _.get(result, 'status_message'),
              alertShow: true,
            });
          },
          onClose() {
            const msg = '<p class="text-center">Checkout failed!</p>';
            component.setState({
              alertType: 'error',
              alertMsg: msg,
              alertShow: true,
            });
          },
        });
      })
      .catch((err) => {
        const { response } = err;
        const { message } = response.data;
        const msg = message
          .map((_msg) => `<li>${_msg.message} for field ${_msg.field}</li>`)
          .join('');

        this.setState({
          alertShow: true,
          alertMsg: `Failed to purchase subscription with code: ${code}. because of: </br>${msg}`,
          alertType: 'error',
        });
      });
  };

  _handleChooseImage = () => {
    this.uploader.click();
  };

  _onChangeFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // get image file
    const file = _.get(e, 'target.files[0]');
    if (_.isNil(file)) return;

    // read file blob and convert to base64
    const reader = new FileReader();
    reader.onload = (_e) => {
      this.setState({
        imageBase64: _.get(_e, 'target.result'),
      });
    };
    reader.readAsDataURL(file);

    this.setState({
      file,
    });
  };

  _renderPersonalInfoTab = () => {
    const { user: it } = this.state;

    // const _renderExtendBtn = (packagee) => {
    //   const userPackage = _.get(it, 'userPackage');

    //   if (!_.isNull(userPackage)) {
    //     const userEndDate = _.get(it, 'userPackage.endDate');
    //     const endDate = moment(userEndDate, 'DD MMMM YYYY');
    //     const duration = _.get(packagee, 'totalDayActive') || 0;

    //     if (moment().isBefore(endDate)) {
    //       const newEndDate = endDate.add(duration, 'days');
    //       const end = newEndDate.format('DD MMMM YYYY');

    //       return (
    //         <>
    //           Extend package until
    //           {' '}
    //           {end}
    //           <br />
    //           (
    //           {formatCurrency(packagee.price)}
    //           )
    //         </>
    //       );
    //     }
    //   }

    //   return (
    //     <>
    //       Purchase (
    //       {formatCurrency(packagee.price)}
    //       )
    //     </>
    //   );
    // };

    return (
      <div className="row">
        <div className="col-md">
          <Card className="p-3 p-md-5 mt-4 card-profile">
            <div className="row mx-0">
              {/* Profile Picture */}
              <div className="col-lg-12 pl-0 text-center">
                <img
                  onError={(e) => {
                    e.target.src = UserThumbnail;
                  }}
                  src={
                    _.isNull(this.state.imageBase64)
                      ? _.isNil(it.institution)
                        ? it.photoUrl
                        : _.get(it, 'institution.logo')
                      : this.state.imageBase64
                  }
                  className="thumbnail-profile"
                  alt="profile"
                  width="100%"
                  onClick={this._handleChooseImage}
                  style={{
                    objectFit: 'cover',
                  }}
                />
                <input
                  type="file"
                  accept="image/*"
                  id="file"
                  ref={(ref) => {
                    this.uploader = ref;
                  }}
                  style={{ display: 'none' }}
                  onChange={this._onChangeFile}
                />
              </div>
              {/* Profile Information */}
              <div className="col-lg-12">
                <div className="row">
                  {/* Full Name */}
                  <div className="col-md mt-3">
                    <div className="form-group">
                      <label htmlFor="fullname">
                        <b>Full name</b>
                      </label>
                      <input
                        type="text"
                        onChange={(e) => this._handleInputTextChanged(e, 'displayName')}
                        className={`form-control ${
                          this.state.isDisplayNameValidated
                            ? 'is-valid'
                            : 'is-invalid'
                        }`}
                        id="fullname"
                        placeholder="Enter your full name"
                        value={it.displayName || ''}
                        required
                      />
                      {!this.state.isDisplayNameValidated && (
                        <div className="invalid-feedback">
                          Please input fullname field
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Phone Number */}
                  <div className="col-md mt-3">
                    <div className="form-group">
                      <label htmlFor="firstname">
                        <b>Phone Number</b>
                      </label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">+62</div>
                        </div>
                        <input
                          type="tel"
                          className={`form-control ${
                            this.state.isPhoneValidated
                              ? 'is-valid'
                              : 'is-invalid'
                          }`}
                          id="phone"
                          placeholder="Enter your phone number"
                          value={it.phoneNumber || ''}
                          required
                          onChange={(e) => this._handleInputTextChanged(e, 'phoneNumber')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* Email */}
                  <div className="col-md mt-3 mt-lg-0">
                    <div className="form-group">
                      <label htmlFor="firstname">
                        <b>Email</b>
                      </label>
                      <input
                        type="email"
                        className={`form-control ${
                          this.state.isEmailValidated
                            ? 'is-valid'
                            : 'is-invalid'
                        }`}
                        id="email"
                        placeholder="E.g. someone@gmail.com"
                        value={it.email || '-'}
                        required
                        onChange={(e) => this._handleInputTextChanged(e, 'email')}
                      />
                      {!this.state.isEmailValidated && (
                        <div className="invalid-feedback">
                          Please enter correct email format
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Gender */}
                  <div className="col-md mt-3 mt-lg-0">
                    <div className="form-group">
                      <label htmlFor="inputGender">
                        <b>Gender</b>
                      </label>
                      <select
                        // eslint-disable-next-line no-nested-ternary
                        className={`form-control ${
                          this.state.isGenderValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isGenderValidated)
                              ? ''
                              : 'is-invalid'
                        }`}
                        id="inputGender"
                        value={it.gender}
                        defaultValue=""
                        onChange={(e) => this._handleInputTextChanged(e, 'gender')}
                      >
                        <option disabled value="">
                          Choose...
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      {!this.state.isGenderValidated && (
                        <div className="invalid-feedback">
                          Please choose your gender
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* Last Education */}
                  <div className="col-md mt-3 mt-lg-0">
                    <div className="form-group">
                      <label htmlFor="firstname">
                        <b>Last Education </b>
                      </label>
                      <select
                        className="custom-select mb-2 mr-sm-2 mb-sm-0"
                        id="inlineFormCustomSelect"
                        value={it.lastEducation}
                        onChange={(e) => this._handleInputTextChanged(e, 'lastEducation')}
                        defaultValue=""
                      >
                        <option disabled value="">
                          Choose...
                        </option>
                        <option value="SD">SD/Sederajat</option>
                        <option value="SMP">SMP/Sederajat</option>
                        <option value="SMA">SMA/SMK/MA/Sederajat</option>
                        <option value="D3">D3 - Diploma</option>
                        <option value="S1">S1 - Sarjana</option>
                        <option value="S2">S2 - Magister</option>
                        <option value="S3">S3 - Doctor</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md mt-3 mt-lg-0">
                    {/* Last Education Institution */}
                    <div className="form-group">
                      <label htmlFor="inputInstitutionSignUp">
                        <b>Last Education Institution</b>
                      </label>
                      <input
                        type="text"
                        // eslint-disable-next-line no-nested-ternary
                        className={`form-control ${
                          this.state.isInstitutionValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isInstitutionValidated)
                              ? ''
                              : 'is-invalid'
                        }`}
                        id="inputInstitutionValidated"
                        placeholder="Enter you last education institution"
                        value={it.schoolName}
                        onChange={(e) => this._handleInputTextChanged(e, 'schoolName')}
                      />
                      {!this.state.isInstitutionValidated && (
                        <div className="invalid-feedback">
                          Please enter this field
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* Province */}
                  <div className="col-md mt-3 mt-lg-0">
                    <div className="form-group">
                      <label htmlFor="dropdownMenuProvinces">
                        <b>Select Province</b>
                      </label>
                      <select
                        // eslint-disable-next-line no-nested-ternary
                        className={`form-control ${
                          this.state.isProvinceValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isProvinceValidated)
                              ? ''
                              : 'is-invalid'
                        }`}
                        value={this.state.provinceId}
                        onChange={(e) => {
                          const { value } = e.target;
                          const provinceId = value;
                          this.setState({
                            isProvinceValidated: true,
                            isCityValidated: false,
                            provinceId,
                            user: {
                              // eslint-disable-next-line react/no-access-state-in-setstate
                              ...this.state.user,
                              cityId: 0,
                            },
                          });
                          this._getCitiesById(provinceId);
                        }}
                        aria-labelledby="dropdownMenuProvinces"
                      >
                        <option value={0} disabled>
                          Choose province
                        </option>
                        {_.map(this.state.provinces, (province) => (
                          <option value={_.get(province, 'province_id')}>
                            {province.name}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        Please select province
                      </div>
                    </div>
                  </div>
                  {/* City */}
                  <div className="col-md mt-3 mt-lg-0">
                    <div className="form-group">
                      <label htmlFor="dropdownMenuCities">
                        <b>Select City</b>
                      </label>
                      <select
                        // eslint-disable-next-line no-nested-ternary
                        className={`form-control ${
                          this.state.isCityValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isCityValidated)
                              ? ''
                              : 'is-invalid'
                        }`}
                        value={it.cityId}
                        aria-labelledby="dropdownMenuCities"
                        onChange={(e) => {
                          const { value } = e.target;
                          const cityId = value;
                          this.setState({
                            isCityValidated: true,
                            user: {
                              // eslint-disable-next-line react/no-access-state-in-setstate
                              ...this.state.user,
                              cityId,
                            },
                          });
                        }}
                      >
                        <option value={0} disabled>
                          Please select province first
                        </option>
                        {_.map(this.state.cities, (city) => (
                          <option value={_.get(city, 'id')}>{city.name}</option>
                        ))}
                      </select>
                      <div className="invalid-feedback">Please select city</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md form-group mt-3 mt-lg-0">
                    <label htmlFor="address">
                      <b>Address</b>
                    </label>
                    <textarea
                      className={`form-control ${
                        this.state.isAddressValidated
                          ? 'is-valid'
                          : 'is-invalid'
                      }`}
                      id="address"
                      placeholder="Enter your address"
                      rows="5"
                      value={it.address}
                      required
                      onChange={(e) => this._handleInputTextChanged(e, 'address')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0 mt-3 mt-lg-5" />
            {_.isEqual(_.get(it, 'kewirusType'), 'mentor') && (
              <div className="row mx-0">
                <div className="col-12 form-group">
                  <label htmlFor="social_media">
                    <b>Social Media + Portofolio</b>
                  </label>
                  {/* Instagram */}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <span>
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </span>
                      </div>
                    </div>
                    <input
                      type="url"
                      className="form-control"
                      id="social_media_ig"
                      placeholder="E.g. https://instagram.com/kewirus"
                      value={_.get(it, 'mentor.instagram')}
                      onChange={(e) => this._handleInputTextChanged(e, 'instagram')}
                    />
                  </div>
                  {/* Linkedin */}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <span>
                          <i className="fa fa-linkedin" aria-hidden="true" />
                        </span>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="social_media_linked_in"
                      placeholder="E.g. https://linkedin.com/kewirus"
                      value={_.get(it, 'mentor.linkedin')}
                      onChange={(e) => this._handleInputTextChanged(e, 'linkedin')}
                    />
                  </div>
                  {/* Portofolio */}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <span>
                          <i className="fa fa-link" aria-hidden="true" />
                        </span>
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="social_media_linked_in"
                      placeholder="E.g. https://kewirus.com"
                      value={_.get(it, 'mentor.portofolio')}
                      onChange={(e) => this._handleInputTextChanged(e, 'portofolio')}
                    />
                  </div>
                </div>
                <div className="col-12 form-group">
                  <label htmlFor="social_media">
                    <b>Title & Description</b>
                  </label>
                  {/* Title */}
                  <div className="input-group mb-3">
                    <input
                      type="url"
                      className={`form-control ${
                        this.state.isMentorTitleValidated
                          ? 'is-valid'
                          : 'is-invalid'
                      }`}
                      id="title_jabatan"
                      placeholder="E.g. Dosen"
                      value={_.get(it, 'mentor.title')}
                      onChange={(e) => this._handleInputTextChanged(e, 'title')}
                    />
                  </div>
                  {/* Quotes */}
                  <div className="input-group mb-3">
                    <textarea
                      className={`form-control ${
                        this.state.isMentorQuotesValidated
                          ? 'is-valid'
                          : 'is-invalid'
                      }`}
                      id="quotes"
                      placeholder="E.g quotes from you"
                      rows="5"
                      value={_.get(it, 'mentor.description')}
                      required
                      onChange={(e) => this._handleInputTextChanged(e, 'description')}
                    />
                  </div>
                </div>
                <div className="col-12 form-group">
                  <label htmlFor="social_media">
                    <b>Jadwal Aktif</b>
                  </label>
                  {/* Available schedule */}
                  <div className="input-group mb-3">
                    <textarea
                      type="url"
                      className={`form-control ${
                        this.state.isMentorAvailableScheduleValidated
                          ? 'is-valid'
                          : 'is-invalid'
                      }`}
                      id="available_schedule"
                      placeholder="E.g. Senin 08:00 - 12:00"
                      rows="5"
                      value={_.get(it, 'mentor.available_schedule')}
                      onChange={(e) => this._handleInputTextChanged(e, 'available_schedule')}
                    />
                  </div>
                </div>
                {/* <div className="col-md-4 mb-5">
                <label htmlFor="rate"><b>Rate Per Hour</b></label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Rp</div>
                  </div>
                  <input
                    onChange={(e) => this._handleInputTextChanged(e, 'ratePrice')}
                    type="text"
                    id="rate"
                    defaultValue="0"
                    value={
                      this.state.user.ratePriceStr
                      || addCommas(it.ratePrice)
                    }
                    placeholder="Enter your rate"
                    className={`form-control ${this.state.isRatePriceValidated
                      ? 'is-valid'
                      : _.isNull(this.state.isRatePriceValidated)
                        ? ''
                        : 'is-invalid'
                    }`}
                  />
                  {
                    !this.state.isEventPriceValidated && (
                      <div className="invalid-feedback">
                        Rate price must be above IDR 0,-
                      </div>
                    )
                  }
                </div>
              </div> */}
              </div>
            )}
            {/* Reset Password Link & Delete Account */}
            <div className="row mx-0">
              <div className="col-12 px-0">
                <a
                  onClick={this._handleRequestResetLink}
                  className="col reset-pass-link"
                  href={`${_.get(this.props, 'location.pathname')}#reset-pass`}
                >
                  Send me reset password link
                </a>
              </div>
              <div className="col-12 px-0 mt-4 mb-5 mb-md-0">
                <a
                  onClick={() => {
                    this.setState({ showModal: true });
                  }}
                  className="col reset-pass-link"
                  href={`${_.get(this.props, 'location.pathname')}#delete-user`}
                >
                  Request to delete account?
                </a>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-4" />
              <div className="col-8">
                <button
                  type="button"
                  className="btn btn-kewirus my-auto text-right"
                  style={{ position: 'absolute', right: '5%', bottom: 0 }}
                  onClick={this._handleSaveProfile}
                  disabled={
                    !(
                      this.state.isAddressValidated
                      && this.state.isDisplayNameValidated
                      && this.state.isEmailValidated
                      && this.state.isPhoneValidated
                      && this.state.isGenderValidated
                      && this.state.isInstitutionValidated
                      && this.state.isCityValidated
                      && ((_.get(this, 'state.user.kewirusType') === 'mentor'
                        && this.state.isMentorAvailableScheduleValidated
                        && this.state.isMentorQuotesValidated
                        && this.state.isMentorTitleValidated)
                        || _.get(this, 'state.user.kewirusType') !== 'mentor')
                    )
                  }
                >
                  Save Changes
                </button>
              </div>
            </div>
          </Card>
        </div>

        {/* <div className="col-md">
          <Card
            className="p-3 p-md-5 mt-4 card-package"
            style={{
              display:
                !_.isEqual(this.state.user.kewirusType, "institution") &&
                !_.isEqual(this.state.user.kewirusType, "mentor")
                  ? ""
                  : "none",
            }}
          >
            <div className="row mx-0">
              {!_.isEqual(it.kewirusType, "institution") &&
                !_.isEqual(it.kewirusType, "mentor") && (
                  <div className="col-12 px-0 form-group">
                    <label className="col-4">
                      <h2>Your package:</h2>
                    </label>
                    <span className="col-8" style={{ fontWeight: "normal" }}>
                      {_.isNull(it.userPackage)
                        ? "No subscription"
                        : _.get(it, "userPackage.name")}
                    </span>
                  </div>
                )}
              {!_.isNull(it, "userPackage") &&
                !_.isEqual(it.kewirusType, "institution") &&
                !_.isEqual(it.kewirusType, "mentor") && (
                  <div className="col-12 px-0">
                    <label className="col-4">
                      <h2>Expiry Date:</h2>
                    </label>
                    <span className="col-8" style={{ fontWeight: "normal" }}>
                      {_.get(it, "userPackage.endDate")}
                    </span>
                  </div>
                )}
            </div>

            <div className="row mx-0">
              {_.map(packages, (packagee) => (
                <div className="col-md-6 mt-5" key={packagee.code}>
                  <h1 className="mb-4">{packagee.name}</h1>
                  <ul className="pl-0">
                    {_.map(packagee.contents, (content) => (
                      <li className="my-2" key={content}>
                        <div
                          strokeWidth="1"
                          aria-hidden="true"
                          className="checkmark"
                        >
                          <img
                            src={CheckMarkSvg}
                            height="24px"
                            width="24px"
                            alt="check"
                          />
                        </div>
                        {content}
                      </li>
                    ))}
                  </ul>
                  <button
                    type="button"
                    className="col-12 my-2 btn btn-kewirus"
                    disabled={
                      !packagee.enable
                      || isPurchaseLoading
                    }
                    onClick={() => this._handlePurchaseSubcrip(packagee)}
                  >
                    {packagee.enable ? (
                      <>
                        {_renderExtendBtn(packagee)}
                      </>
                    ) : (
                      <>Not Available</>
                    )}
                  </button>
                </div>
              ))}
            </div>
          </Card>
        </div> */}
      </div>
    );
  };

  _renderTrxHistoriesTab = (data) => (
    <Card className="p-5 mt-4 card-profile">
      <div className="row">
        <div className="col-12 form-group mb-4">
          <Table
            colHeader={this.state.trxTableCols}
            data={data}
            onNextPage={() => {
              this.setState((prevState) => ({
                page: prevState.page + 1,
              }));
            }}
            onPageNumberChanged={(_page) => {
              this.setState({
                page: _page,
              });
            }}
            page={this.state.page}
            totalPage={this.state.totalPage}
            onPreviousPage={() => {
              this.setState((prevState) => ({
                page: prevState.page - 1,
              }));
            }}
            {...this.props}
          />
        </div>
      </div>
    </Card>
  );

  _renderChangePassTab = () => (
    <Card className="p-5 card-profile">
      <div className="row">
        <div className="col-12 form-group mb-4">
          <label htmlFor="new_pass">
            <b>New Password</b>
          </label>
          <input
            type="password"
            // eslint-disable-next-line no-nested-ternary
            className={`form-control ${
              this.state.isPassValidated
                ? 'is-valid'
                : _.isNull(this.state.isPassValidated)
                  ? ''
                  : 'is-invalid'
            }`}
            value={this.state.user.password}
            id="new_pass"
            placeholder="Enter new password"
            onChange={(e) => this._handleInputTextChanged(e, 'password')}
          />
          {!this.state.isPassValidated && (
            <div className="invalid-feedback">
              Please enter your new password with minimal 8 characters
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group mb-4">
          <label htmlFor="confirm_pass">
            <b>Confirm Password</b>
          </label>
          <input
            type="password"
            // eslint-disable-next-line no-nested-ternary
            className={`form-control ${
              this.state.isConfirmPassValidated
                ? 'is-valid'
                : _.isNull(this.state.isConfirmPassValidated)
                  ? ''
                  : 'is-invalid'
            }`}
            value={this.state.user.confirmPass}
            onChange={(e) => this._handleInputTextChanged(e, 'confirmPass')}
            id="confirm_pass"
            placeholder="Re-enter new password"
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 ml-auto text-right">
          <button
            type="button"
            className="btn btn-kewirus my-auto"
            onClick={() => {
              if (this.state.isResetPass) {
                this._handleResetPass();
              } else {
                this._handleSaveProfile();
              }
            }}
            disabled={
              !(this.state.isConfirmPassValidated && this.state.isPassValidated)
            }
          >
            Save Changes
          </button>
        </div>
      </div>
    </Card>
  );

  _renderDeleteUserModal = () => (
    <Modal show={this.state.showModal}>
      <div className="container mentor-modal mt-5 mb-4 mx-3">
        <div className="row">
          <h1 className="col mb-3">Are you sure want to delete account?</h1>
        </div>
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={() => {
                this.setState({ showModal: false });
              }}
              className="btn m-up mt-4 col-12 border-blue-kewirus"
            >
              <b className="text-blue-kewirus">Cancel</b>
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              onClick={this._handleDeleteUser}
              className="mt-4 btn submit col-12 btn-kewirus"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );

  render() {
    const { sections } = this.state;

    return (
      <div className="col container-page-kewirus">
        {this.state.alertShow && (
          <div
            className={`alert ${
              _.isEqual(this.state.alertType, 'error')
                ? 'alert-danger'
                : 'alert-success'
            } alert-dismissible fade show`}
            role="alert"
            style={{
              position: 'fixed',
              top: '10%',
              zIndex: '10',
            }}
          >
            <p>{parse(this.state.alertMsg)}</p>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => this.setState({ alertMsg: '', alertShow: false })}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        {/* render modal */}
        {this._renderDeleteUserModal()}
        {/* Search Bar and Logo */}
        <div className="row">
          {/* Logo */}
          <a href="/" className="kewirus-desktop-only col-auto mb-5 ml-auto">
            <img
              src={AppLogo}
              className="app-logo"
              alt="Cabin Crew Class Logo"
            />
          </a>
        </div>
        {/* Title */}
        <div className="row mx-0 mt-2">
          <PageTitle title="Profile" />
        </div>
        {/* Nav Tabs and Button Add Schedule */}
        <div className="row">
          {/* Nav Tabs */}
          <div className="col-12">
            <NavTabs
              sections={(() => {
                const kewirusType = _.get(
                  this,
                  'state.user.kewirusType',
                  'institution'
                );
                if (kewirusType !== 'institution') {
                  return sections;
                }

                return sections.filter(
                  (section) => section.title !== 'Transaction Histories'
                );
              })()}
              onTabClick={(_e, idx) => {
                switch (idx) {
                case 0:
                  this.getPackages();
                  break;
                case 1:
                  this.getTrxHistories();
                  break;
                default:
                  break;
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;
