/* eslint-disable camelcase */
import Http from '../http';

const endpoint = 'api/admin';

export default {
  getExperts() {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/mentors`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  }
};
