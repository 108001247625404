import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const FallbackComponent = ({ componentStack, error }) => (
  <div className={styles.containerFallback}>
    <p className={styles.title}>
      <strong>Terjadi Kesalahan! Silakan laporkan pada pengembang</strong>
    </p>
    <p className={styles.subtitle}>Mohon kirim tangkapan layar berikut</p>
    <p className={styles.description}>
      <strong>Error:</strong>
      {' '}
      {error.toString()}
    </p>
    <p className={styles.description}>
      <strong>Stacktrace:</strong>
      {' '}
      {componentStack}
    </p>
  </div>
);

FallbackComponent.propTypes = {
  componentStack: PropTypes.any.isRequired,
  error: PropTypes.any.isRequired
};

export default FallbackComponent;
