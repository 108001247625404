import Http from '../http';

const endpoint = 'api/user/meeting';

export default {
  getInvitations() {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/invitations`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getMySchedule() {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/schedule`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  confirmMeet(action, code) {
    return new Promise((resolve, reject) => {
      Http.post(
        'api/user/project/mentor/invitation/confirm',
        { action, code }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  }
};
