import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Card = (props) => {
  const { children } = props;
  return (
    <div className="container-card">
      {/* Content */}
      <div className="all-card" {...props}>
        { children }
      </div>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  onClick: PropTypes.func
};

Card.defaultProps = {
  onClick: () => {}
};

export default Card;
