import React from 'react';
import './styles.scss';

// eslint-disable-next-line react/prefer-stateless-function
class LandingPage extends React.Component {
  render() {
    return (
      <div className="container-landingpage">
        <iframe title="Cabin Crew Class" src="https://www.kewirus.com" />
      </div>
    );
  }
}

export default LandingPage;
