/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import parse from 'html-react-parser';
import moment from 'moment';

import './styles.scss';
import ApiTransaction from '../../services/api/transaction';
import Pagination from '../Pagination';
import { formatCurrency } from '../../utils';

const dateTimeTmplt = 'DD/MM/YYYY HH:mm:ss';
const Table = (props) => {
  const {
    data, onNextPage, page, colHeader,
    onPreviousPage, onPageNumberChanged, totalPage
  } = props;
  const [alertShow, setAlertShow] = useState(false);
  const [alertMessage, setAlertMsg] = useState('');
  const [alertType, setAlertType] = useState('');

  const _handleTrxMeetingInvitation = (code) => {
    ApiTransaction.payMeetingInvitation(code)
      .then((res) => {
        const token = _.get(res, 'data.data');
        // - show midtrans popup client
        window.snap.pay(token, {
          onSuccess: (result) => {
            setAlertShow(true);
            setAlertMsg(_.get(result, 'status_message'));
            setAlertType('success');

            // - replace to courses
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          },
          onError(result) {
            setAlertShow(true);
            setAlertMsg(_.get(result, 'status_message'));
            setAlertType('error');

            // - replace to courses
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          },
          onClose() {
            const msg = '<p class="text-center">Checkout failed!</p>';
            setAlertShow(true);
            setAlertMsg(msg);
            setAlertType('error');

            // - replace to courses
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        });
      })
      .catch((err) => {
        const { response } = err;
        const { message } = response.data;
        const msg = message.map((_msg) => `<li>${_msg.message} for field ${_msg.field}</li>`).join('');

        setAlertMsg(`Failed to purchase meeting invitation. because of: </br>${msg}`);
        setAlertShow(true);
        setAlertType('error');
      });
  };
  const renderTrxRow = (trx, idx) => (
    <tr key={trx.code}>
      <th>{ ((page - 1) * 10) + (idx + 1) }</th>
      <td>
        {String(trx.code).startsWith('EVT')
          ? 'Event'
          : String(trx.code).startsWith('MEN')
            ? 'Mentor Invitation'
            : trx.item }
      </td>
      <td>{trx.code}</td>
      <td>{trx.type}</td>
      <td>{formatCurrency(trx.total)}</td>
      <td className={_.isNull(trx.paidTime) ? 'text-danger' : 'text-success'}>
        { _.capitalize(trx.status) }
      </td>
      <td>
        {
          !_.isNull(trx.paidTime) ? (
            <>
              { ` ${moment(trx.paidTime).format(dateTimeTmplt)}` }
            </>
          )
            : (
              <>
                -
              </>
            )
        }
      </td>
      <td>
        <button
          type="button"
          className="btn btn-kewirus"
          style={{ display: _.isEqual('meeting-invitation', trx.type) && _.isNull(trx.paidTime) ? 'block' : 'none' }}
          onClick={() => _handleTrxMeetingInvitation(trx.code)}
        >
          PAY
        </button>
        { !_.isNull(trx.paidTime)
          ? 'No action needed'
          : _.isEqual('meeting-invitation', trx.type) ? ''
            : 'No action needed'}
      </td>
    </tr>
  );

  return (
    <div>
      {
        alertShow && (
          <div className={`alert ${_.isEqual(alertType, 'error') ? 'alert-danger' : 'alert-success'} alert-dismissible fade show`} role="alert">
            <p>
              {parse(alertMessage)}
            </p>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => {
                setAlertShow(false);
                setAlertMsg('');
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )
      }
      <table className="table table-hover table-borderless table-responsive">
        <thead>
          <tr>
            <th>No</th>
            {
              _.map(colHeader, (header) => (
                <th scope="col" style={{ width: header.width }} key={header.name}>
                  {header.name}
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {_.map(data, renderTrxRow)}
          {
            _.isEmpty(data)
            && (
              <tr>
                <td colSpan={colHeader.length + 1} className="text-center py-5">
                  No data
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
      {
        totalPage > 1 && (
          <Pagination
            className="mt-5"
            activePage={page}
            totalPage={totalPage}
            onNextClick={onNextPage}
            onPageNumberClick={onPageNumberChanged}
            onPreviousClick={onPreviousPage}
            {...props}
          />
        )
      }
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  onNextPage: PropTypes.func.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  onPageNumberChanged: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  colHeader: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      width: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default Table;
