import Http from '../http';

require('dotenv').config();

const endpoint = 'api/auth';

export default {
  login(email, password) {
    return new Promise((resolve, reject) => {
      Http.post(
        'api/login',
        {
          email,
          password
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  deleteUser(email) {
    return new Promise((resolve, reject) => {
      Http.delete(
        'api/admin/user/remove',
        { email }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  register({
    email, name, password, phoneNumber, address, lastEducation,
    gender, cityId, schoolName
  }) {
    return new Promise((resolve, reject) => {
      Http.post(
        'api/register',
        {
          email,
          name,
          password,
          phoneNumber,
          address,
          lastEducation,
          gender,
          cityId,
          schoolName,
          frontendUrl: process.env.REACT_APP_FRONTEND_URL || 'http://localhost:4200'
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  mentorRegister(data) {
    return new Promise((resolve, reject) => {
      Http.post(
        'api/register/mentor',
        data
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  guestRegister(data) {
    return new Promise((resolve, reject) => {
      Http.post(
        'api/register/project-member',
        data
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  verify(verificationCode) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}/email/verification`,
        {
          verificationCode
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  resendCode(email) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}/email/verification/resend`,
        {
          email,
          frontendUrl: process.env.REACT_APP_FRONTEND_URL || 'http://localhost:4200'
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getUser() {
    return new Promise((resolve, reject) => {
      Http.get(
        `${endpoint}/user`
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  forgotPass(email) {
    return new Promise((resolve, reject) => {
      Http.post(
        'api/forgot/password',
        {
          email,
          frontendUrl: process.env.REACT_APP_FRONTEND_URL || 'http://localhost:4200'
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  resetPass(code, password) {
    return new Promise((resolve, reject) => {
      Http.post(
        'api/reset/password',
        {
          code,
          password
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  updateProfile({
    email, name, phoneNumber, address, lastEducation, image, ratePrice,
    instagram, linkedin, portofolio, title, description, availableSchedule,
    cityId, gender, schoolName
  }) {
    return new Promise((resolve, reject) => {
      Http.post(
        'api/profile/update',
        {
          email,
          name,
          phoneNumber,
          address,
          lastEducation,
          image,
          ratePrice,
          cityId,
          gender,
          schoolName,
          instagram,
          linkedin,
          portofolio,
          title,
          description,
          availableSchedule
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getUserListByEmail(email = null) {
    return new Promise((resolve, reject) => {
      Http.get(
        `api/admin/users?${email !== null ? `query=${email}` : ''}`
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  }
};
