import React from 'react';
import _ from 'lodash';
import './styles.scss';

import { Link } from 'react-router-dom';
import {
  SearchBar, NavTabs, PageTitle, CardList, Shimmer
} from '../../components';

import ApiMaterial from '../../services/api/material';
import AppLogo from '../../assets/app-logo.png';
import Thumbnail from '../../assets/no-image.png';

import mycourses from '../../dummy/mycoursecategories';

class CoursesCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myCourses: {
        total: 0,
        isLoading: true,
        data: []
      },
      courseCategory: {
        total: 0,
        displayed: 5
      },
      sections: [
        {
          title: 'All Courses',
          items: mycourses,
          renderItems: (it) => this._renderCategoryCourses(it),
        },
        {
          title: 'Purchased Courses',
          items: _.range(0, 3),
          renderItems: (it) => this._renderMyCourses(it)
        },
      ],
      selectedTab: 0,

      isLoadingProgress: true
    };
  }

  componentDidMount() {
    const location = _.get(this.props, 'location');

    if (!_.isNil(location.state)) {
      const idx = location.state.tabIndex;
      if (idx === 1) {
        const elmt = document.getElementById('pills-My Courses-tab');
        elmt.click();
      }
    }
    this.getMyCourses();
    this.getCategoryCourse();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.selectedTab !== this.state.selectedTab) {
      const { selectedTab } = this.state;
      switch (selectedTab) {
      case 0: // @TODO: hit api get course category
        break;
      case 1:
        this.getMyCourses();
        break;
      default:
        break;
      }
    }
  }

  getCategoryCourse = () => {
    ApiMaterial.getCategories()
      .then((res) => {
        const data = _.get(res, 'data.data');
        const categorySection = _.head(this.state.sections);
        let { sections } = this.state;
        // assign items
        categorySection.items = data;
        sections = _.drop(sections, 1);
        sections.unshift(categorySection);

        this.setState({
          courseCategory: {
            total: data.length,
            displayed: 5
          },
          sections
        });
      });
  }

  getMyCourses = (query = '') => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      ...this.state,
      myCourses: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.myCourses,
        isLoading: true
      },
    });

    ApiMaterial.getMyMaterials(query)
      .then((res) => {
        const data = _.get(res, 'data.data');
        const myCourseSection = _.get(this.state, 'sections[1]');
        let { sections } = this.state;
        // parse tags
        _.each(data, (d) => {
          const { tags } = d;
          const parsedTags = _.isString(tags) ? JSON.parse(tags) : tags;
          _.set(d, 'tags', parsedTags);
        });
        // replace mycourse section
        sections = _.dropRight(sections, 1);
        myCourseSection.items = data;
        sections.push(myCourseSection);

        this.setState((prevState) => ({
          ...prevState,
          myCourses: {
            total: data.length,
            isLoading: false,
            data
          },
          sections
        }));
      });
  }

  _handleSearch = ({ target }) => {
    const { value: query } = target;
    const { selectedTab } = this.state;
    if (_.isEqual(selectedTab, 1)) this.getMyCourses(query);
  }

  _renderCategoryCourses = (it) => (
    <CardList
      {...this.props}
      items={it}
      isLoading
      limit={this.state.courseCategory.displayed}
      type="course-category"
      onItemClick={(_e, item) => {
        // eslint-disable-next-line react/prop-types
        this.props.history.push(`/courses/browse/${item.name}`);
      }}
      onViewMoreClick={() => {
        this.setState((prevState) => ({
          courseCategory: {
            ...prevState.courseCategory,
            displayed: prevState.courseCategory.displayed + 3
          }
        }));
      }}
    />
  );

  _renderMyCourses = (it) => (
    <CardList
      {...this.props}
      items={it}
      type="course"
      // eslint-disable-next-line react/prop-types
      onItemClick={(_e, item) => this.props.history.push(`/course/detail/${item.slug}`)}
      isLoading={this.state.myCourses.isLoading}
      page={1}
      totalPage={1}
      onNextPage={() => {}}
      onPageNumberChanged={() => {}}
      onPreviousPage={() => {}}
    />
  )

  _renderCourseProgress = () => (
    _.map(_.get(this, 'state.myCourses.data'), (item, idx) => (
      <Link
        to={`/course/detail/${item.slug}`}
        // eslint-disable-next-line react/no-children-prop
        children={(
          <div
            className={`row card-course-progress mx-0 ${idx > 0 && 'mt-3'}`}
            key={idx}
          >
            {/* Thumbnail Course */}
            <div className="col-auto py-0 pl-0 pr-3">
              { this.state.myCourses.isLoading ? (
                <Shimmer style={{ height: '75px', width: '216px' }} />
              ) : (
                <img
                  className="img-thumbnail-course-progress"
                  onError={(e) => { e.target.src = Thumbnail; }}
                  width="75px"
                  src={item.thumbnail || Thumbnail}
                  alt="course progress"
                />
              )}
            </div>
            {/* Title & Progress */}
            <div className="col align-self-center">
              <div className="row mx-0">
                {/* Title Course */}
                <div className="col-12 px-0 pb-2">
                  <b className="text-dark">{ item.name }</b>
                </div>
                {/* Progress Bar */}
                <div className="col-12 px-0">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${Number(_.get(item, 'progress', 0)).toFixed(2)}%`,
                        height: '100%',
                        borderRadius: '.25rem'
                      }}
                      aria-valuenow={Number(_.get(item, 'progress', 0)).toFixed(2)}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {Number(_.get(item, 'progress', 0)).toFixed(2)}
                      {' '}
                      %
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Lesson */}
            {/* <div className="col-md-3 ml-auto align-self-center">3/36 Lesson</div> */}
            {/* Button go to course */}
            <div className="col-auto ml-auto kewirus-desktop-only align-self-center">
              <button
                // eslint-disable-next-line react/prop-types
                onClick={() => this.props.history.push(`/course/detail/${item.slug}`)}
                type="button"
                className="btn btn-kewirus"
              >
                <span>
                  <i className="fa fa-arrow-circle-right" />
                </span>
              </button>
            </div>
          </div>
        )}
      />

    ))
  );

  render() {
    const { sections, selectedTab } = this.state;

    return (
      <div className="container-courses-category col container-page-kewirus">
        {/* Search Bar and Logo */}
        <div className="row">
          {/* Search Bar */}
          <div className="kewirus-desktop-only col-6 mb-5">
            <SearchBar
              onSearch={this._handleSearch}
            />
          </div>
          {/* Logo */}
          <a href="/" className="kewirus-desktop-only col-auto mb-5 ml-auto">
            <img src={AppLogo} className="app-logo" alt="Cabin Crew Class Logo" />
          </a>
        </div>

        {/* Title */}
        <div className="row mx-0">
          <PageTitle
            title={`${sections[selectedTab].title}`}
            subtitle={
              selectedTab === 1 ? `${this.state.myCourses.total} owned` : ''
            }
          />
        </div>

        {/* Search Bar Mobile Only */}
        <div className="row kewirus-mobile-only">
          {/* Search Bar */}
          <div className="col-12 mt-3 mb-4">
            <SearchBar onSearch={this._handleSearch} />
          </div>
        </div>

        {/* Nav Tabs and Button Add Courses */}
        <div className="row">
          {/* Nav Tabs */}
          <div className="col-12">
            <NavTabs
              sections={sections}
              onTabClick={(_e, idx) => this.setState({
                selectedTab: idx
              })}
            />
          </div>
        </div>
        {/* Progress */}
        <div className="row mx-0 pt-5">
          <PageTitle title="Progress" subtitle="All started courses" />
        </div>
        {/* Card Progress */}
        {this._renderCourseProgress()}
        {
          _.get(this, 'state.myCourses.data.length') === 0 && (
            <p className="text-center">Nothing here</p>
          )
        }
      </div>
    );
  }
}

export default CoursesCategory;
