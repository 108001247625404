/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import parse from 'html-react-parser';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import DatePicker from 'react-datepicker';
import './styles.scss';
import AppLogo from '../../assets/app-logo.png';
import WebAppLogo from '../../assets/logo.png';
import InstagramIcon from '../../assets/instagram.png';
import LinkedinIcon from '../../assets/linkedin.png';
import Thumbnail from '../../assets/example-thumbnail.png';
import UserThumbnail from '../../assets/user-profile-2.png';
import UserImage from '../../assets/user-no-image.png';
import {
  NavTabs, PageTitle, Modal
} from '../../components';

import ApiMeeting from '../../services/api/meeting';
import ApiProject from '../../services/api/project';
import ApiEvent from '../../services/api/event';
import ApiExpert from '../../services/api/expert';
import { dateTimeTmplt, formatCurrency, validateStringLen } from '../../utils';

class Schedules extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('kewirus_u'));
    this.state = {
      user,
      showModal: false,
      showMeetModal: false,
      selectedEvent: {},

      mySchedule: {
        total: 0
      },
      meetingRequests: [],
      eventRequests: [],
      mentors: [],

      // modal state
      isMeetingStartDateValidated: null,
      isMeetingEndDateValidated: null,
      isNotesValidated: null,
      isMentorSelected: null,
      requestMeet: {},
      minStartDate: moment().toDate(),

      alertShow: false,
      alertMessage: '',
      alertType: 'error',

      // - tabs
      sections: [
        {
          title: 'Calendar',
          items: [[]],
          renderItems: (it) => this._rendermySchedules(it[0]),
        },
        {
          title: 'Meeting',
          items: [],
          renderItems: () => this._renderMeetingRequests(),
        },
        {
          title: 'Event',
          items: [],
          renderItems: () => this._renderEventRequests(),
        },
      ],
    };
  }

  componentDidMount() {
    this._getMyEvents();
    // this._getProjectMentors();
    this._getExpert();
    this._getInvitations();
  }

  _getInvitations = () => {
    const { user } = this.state;
    ApiMeeting.getInvitations()
      .then((res) => {
        const data = _.get(res, 'data.data');
        this.setState({
          meetingRequests: data
        });
      });

    ApiMeeting.getMySchedule()
      .then((res) => {
        const data = _.get(res, 'data.data');
        // - append schedule in calendar sections
        const { sections } = this.state;
        const calendarSection = _.head(sections);
        const items = _.get(calendarSection, 'items[0]');
        _.map(data, (it, idx) => {
          const addToItems = () => {
            items.push({
              thumbnail: _.get(it, 'project.thumbnail', null),
              eventId: idx,
              title: it.project !== null
                ? `Meeting in ${_.get(it, 'project.name')}`
                : `Meeting with ${_.get(it, user.kewirusType === 'mentor' ? 'user2.name' : 'user.name')}`,
              start: moment(
                _.get(it, 'started_at'),
                dateTimeTmplt
              ).toDate(),
              end: moment(
                _.get(it, 'end_at'),
                dateTimeTmplt
              ).toDate(),
              isProjectMeet: !_.isNil(it.project_id),
              seat: _.get(it, 'project.members.length', 0),
              members: _.get(it, 'project.members'),
              allDay: false
            });
          };
          const expertEmail = _.get(it, 'user.email');
          const isMeeting1On1 = _.isNil(it.project) || !_.find(_.get(it, 'project.members'), { user: { email: expertEmail } });
          if (isMeeting1On1) {
            const status = 'paid';
            if (status === it.status) addToItems();
          } else {
            addToItems();
          }
        });
        calendarSection.items = [items];
        sections[0] = calendarSection;

        this.setState({
          // eslint-disable-next-line react/no-access-state-in-setstate
          ...this.state,
          mySchedule: {
            total: items.length
          },
          sections
        });
      });
  }

  _getProjectMentors = () => {
    ApiProject.getProjectMentors()
      .then((res) => {
        const data = _.get(res, 'data.data');
        let { mentors } = this.state;
        mentors = mentors.concat(data);
        this.setState({
          mentors
        });
      });
  }

  _getMyEvents = () => {
    ApiEvent.getMyEvents(
      1,
      '',
      9999
    ).then((res) => {
      const { data } = _.get(res, 'data.data');
      const { sections } = this.state;
      const calendarSection = _.head(sections);
      const items = _.get(calendarSection, 'items[0]');
      // - map data
      let eventRequests = [];
      _.map(data, (it, idx) => {
        items.push({
          eventId: idx,
          title: it.name,
          start: moment(
            _.get(it, 'startedAt'),
            dateTimeTmplt
          ).toDate(),
          end: moment(
            _.get(it, 'endAt'),
            dateTimeTmplt
          ).toDate(),
          seat: _.toLength(
            _.get(it, 'eventAttendances')
          ),
          thumbnail: _.get(it, 'thumbnail'),
          allDay: false
        });

        const isCreatorEvent = _.get(it, 'isCreatorEvent');
        if (isCreatorEvent) {
          const attendances = _.get(it, 'eventAttendances');
          eventRequests = _.map(attendances, (attendance) => ({
            ...attendance,
            eventName: it.name
          }));
        }
      });
      _.set(calendarSection, 'items', [items]);
      sections[0] = calendarSection;

      // - change state
      const stateChange = (prevState) => ({
        ...prevState,
        eventRequests,
        mySchedule: {
          total: items.length
        },
        sections
      });

      this.setState(stateChange);
    });
  }

  _getExpert = () => {
    // - hit api experts
    ApiExpert.getExperts()
      .then((res) => {
        const data = _.get(res, 'data.data');
        let { mentors } = this.state;
        mentors = mentors.concat(data);

        this.setState({
          mentors
        });
      });
  }

  _handleAddSchedule = () => {
    // direct to event creation page
    // eslint-disable-next-line react/prop-types
    this.props.history.push('/event/new');
  };

  _handleInputTextChanged = (e, field, additionalValue = {}) => {
    let { value } = e.target;

    if (_.isEqual(field, 'notes')) {
      const validate = validateStringLen(value, 1);
      this.setState({ isNotesValidated: validate });
    } else if (_.isEqual(field, 'mentor')) {
      value = {
        email: additionalValue.email,
        projectSlug: additionalValue.projectSlug,
        rate_price: additionalValue.rate_price
      };
      this.setState({ isMentorSelected: true });
    }

    this.setState({
      requestMeet: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.requestMeet,
        [field]: value
      }
    });
  }

  _handleDateChanged = (_e, date, type) => {
    const validation = {};
    if (_.isEqual(type, 'start_date')) {
      const endDate = _.get(this.state, 'requestMeet.end_date');
      if (!_.isUndefined(endDate)) {
        const endDateMoment = moment(endDate);
        if (moment(date).isBefore(endDateMoment, 'day')) {
          _.set(validation, 'isMeetingStartDateValidated', true);
          _.set(validation, 'isMeetingEndDateValidated', true);
        } else {
          _.set(validation, 'isMeetingEndDateValidated', false);
          _.set(validation, 'isMeetingStartDateValidated', false);
        }
      } else {
        _.set(validation, 'isMeetingStartDateValidated', true);
      }
    } else {
      const startDate = _.get(this.state, 'requestMeet.start_date');
      if (!_.isNil(startDate)) {
        const d = moment(startDate).format('DD');
        let strEndDate = moment(date).format('DD/MM/YYYY HH:mm');
        strEndDate = strEndDate.substr(2, strEndDate.length - 1);
        strEndDate = `${d}${strEndDate}`;
        date = moment(strEndDate, 'DD/MM/YYYY HH:mm').toDate();
      }
      if (!_.isUndefined(startDate)) {
        const startDateMoment = moment(startDate);
        if (moment(date).isAfter(startDateMoment)) {
          _.set(validation, 'isMeetingStartDateValidated', true);
          _.set(validation, 'isMeetingEndDateValidated', true);
        } else {
          _.set(validation, 'isMeetingStartDateValidated', false);
          _.set(validation, 'isMeetingEndDateValidated', false);
        }
      } else {
        _.set(validation, 'isMeetingEndDateValidated', true);
      }
    }
    this.setState({
      requestMeet: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.requestMeet,
        [type]: date
      },
      ...validation
    });
  }

  _rendermySchedules = (it) => {
    const localizer = momentLocalizer(moment);
    const events = it.map((_it) => ({
      ..._it,
      start: moment(_it.start),
      end: moment(_it.end),
    }));

    // render calendar here
    return (
      <Calendar
        localizer={localizer}
        events={events}
        allDayAccessor="end"
        startAccessor="start"
        endAccessor="end"
        className="mt-3"
        onSelectSlot={(slot) => {
          const momentStart = moment(slot.start, 'ddd, MMM DD YYYY HH:mm:ss [GMT]ZZ');
          if (slot.action === 'click' && moment().isBefore(momentStart)) {
            // - create new event
            this.setState({
              showMeetModal: true,
              requestMeet: {
                // eslint-disable-next-line react/no-access-state-in-setstate
                ...this.state.requestMeet,
                start_date: momentStart.add(8, 'hours').toDate(),
                end_date: moment(momentStart).add(1, 'hours').toDate(),
              },
              isMeetingStartDateValidated: true,
              isMeetingEndDateValidated: true
            });
          }
        }}
        selectable
        onSelectEvent={(e) => {
          this.setState({
            selectedEvent: e,
            // eslint-disable-next-line react/no-access-state-in-setstate
            showModal: !this.state.showModal,
          });
        }}
      />
    );
  };

  _renderEventRequests = () => (
    <div className="container-schedules-table mt-3">
      <table className={`table table-hover table-borderless ${window.innerWidth > 480 ? '' : 'table-responsive'}`}>
        <caption className="ml-3 mt-4">
          { this.state.eventRequests.length }
          {' '}
          requests
        </caption>
        <thead>
          <tr>
            <th scope="col" style={{ width: '5%' }} className="text-center">
              No.
            </th>
            <th scope="col" style={{ width: '25%' }}>
              Event Name
            </th>
            <th scope="col" style={{ width: '25%' }}>
              Name
            </th>
            <th scope="col" style={{ width: '22%' }}>
              Email
            </th>
            <th scope="col" style={{ width: '23%' }}>
              Phone Number
            </th>
          </tr>
        </thead>
        <tbody>
          {
            _.map(this.state.eventRequests, (request, idx) => (
              <tr>
                <th scope="row" className="text-center">
                  { idx + 1 }
                </th>
                <td>{ request.eventName }</td>
                <td>{ request.name }</td>
                <td>{ request.email }</td>
                <td>{ `+62${request.phone_number}` }</td>
              </tr>
            ))
          }
          {
            _.isEqual(this.state.eventRequests.length, 0) && (
              <tr>
                <td colSpan="5" className="text-center py-5">
                  No data
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );

  _renderMeetingRequests = () => (
    <div className="container-schedules-table mt-3">
      <table className={`table table-hover table-borderless ${window.innerWidth > 480 ? '' : 'table-responsive'}`}>
        <caption className="ml-3 mt-4">
          {this.state.meetingRequests.length}
          {' '}
          requests
        </caption>
        <thead>
          <tr>
            <th scope="col" style={{ width: '5%' }} className="text-center">
              No.
            </th>
            <th scope="col" style={{ width: '15%' }}>
              Title
            </th>
            <th scope="col" style={{ width: '16%' }}>
              Date
            </th>
            <th scope="col" style={{ width: '12%' }}>
              Time
            </th>
            <th scope="col" style={{ width: '30%' }}>
              Notes
            </th>
            <th scope="col" style={{ width: '22%' }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {
            _.map(this.state.meetingRequests, (request, idx) => (
              <tr>
                <th scope="row" className="text-center">
                  { idx + 1 }
                </th>
                <td>
                  { _.get(request, 'project.name', `Meeting one-on-one with ${_.get(request, 'user2.name')} (${_.get(request, 'user2.email')})`) }
                </td>
                <td>
                  { moment(_.get(request, 'started_at')).format('ddd, DD MMM YYYY') }
                </td>
                <td>
                  { `${moment(_.get(request, 'started_at', dateTimeTmplt)).format('HH:mm')} ` }
                  -
                  { ` ${moment(_.get(request, 'end_at', dateTimeTmplt)).format('HH:mm')}` }
                </td>
                <td>{ _.get(request, 'notes') }</td>
                {
                  _.isEqual(request.status, 'pending') ? (
                    <td>
                      <div className="row align-items-start">
                        {/* Decline Button */}
                        <div className="col-auto pr-0">
                          <button
                            onClick={(e) => this._handleConfirmInvitation(e, 'rejected', request.code)}
                            type="button"
                            className="btn btn-decline-kewirus"
                          >
                            Decline
                          </button>
                        </div>
                        {/* Accept Button */}
                        <div className="col">
                          <button
                            onClick={(e) => this._handleConfirmInvitation(e, 'confirmed', request.code)}
                            type="button"
                            className="btn btn-accept-kewirus mt-md-0 mt-3"
                          >
                            Accept
                          </button>
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td>
                      { String(request.status).toUpperCase() }
                    </td>
                  )
                }
              </tr>
            ))
          }
          {
            _.isEqual(this.state.meetingRequests.length, 0) && (
              <tr>
                <td colSpan="6" className="text-center py-5">
                  No data
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );

  _renderEventDetailModal = () => (
    <Modal
      show={this.state.showModal}
      onClose={() => this.setState({ showModal: false })}
    >
      <img
        onError={(e) => { e.target.src = Thumbnail; }}
        src={this.state.selectedEvent.thumbnail || Thumbnail}
        className="thumbnail-event"
        alt="event-thumbnail"
      />
      <div className="container my-3">
        <div className="row">
          <div className="col-3 text-center">
            <h1>{moment(this.state.selectedEvent.start).format('ddd')}</h1>
            <h2>{moment(this.state.selectedEvent.start).format('DD')}</h2>
          </div>
          <div className="col-9">
            <h1>{this.state.selectedEvent.title}</h1>
            <p>
              {moment(this.state.selectedEvent.start).format(
                'hh:mm [-] '
              )}
              {moment(this.state.selectedEvent.end).format(
                'hh:mmA [GMT] Z'
              )}
            </p>
          </div>
        </div>
        {/* Participants */}
        {
          this.state.selectedEvent.isProjectMeet && (
            <div className="row col mx-4 mt-3 mb-3">
              {
                this.state.selectedEvent.members.map((member, idx) => (
                  idx < 5 && (
                    <img
                      onError={(e) => { e.target.src = UserThumbnail; }}
                      src={_.get(member, 'user.photoUrl') || UserThumbnail}
                      className="participant-event"
                      alt="user"
                    />
                  )
                ))
              }
              {
                this.state.selectedEvent.members.length === 0 && (
                  <img
                    onError={(e) => { e.target.src = UserThumbnail; }}
                    src={this.state.user.photoUrl || UserThumbnail}
                    className="participant-event"
                    alt="user"
                  />
                )
              }
              <span className="d-flex align-items-center">
                <p className="mb-0 ml-3">
                  {this.state.selectedEvent.seat + 1}
                  +
                </p>
              </span>
            </div>
          )
        }
      </div>
    </Modal>
  )

  _renderMeetRequestModal = () => {
    const startDate = _.get(this.state, 'requestMeet.start_date');
    const endDate = _.get(this.state, 'requestMeet.end_date');
    let diff = Math.ceil(Math.abs(moment(startDate).diff(endDate, 'minutes')) / 60);
    if (_.isNil(startDate) || _.isNil(endDate)) diff = 0;
    const ratePrice = _.get(this.state, 'requestMeet.mentor.rate_price', 0);
    const total = diff * ratePrice;

    return (
      <Modal
        show={this.state.showMeetModal}
        // onClose={() => this.setState({ showMeetModal: false })}
        className="modal-meeting-request"
      >
        <div className="container mentor-modal mt-5 mb-4 mx-3">
          <div className="row">
            <h1 className="col mb-5">Mentoring</h1>
          </div>
          {/* Mentor Select */}
          <div className="row">
            <label htmlFor="schedule_mentor" className="col-12">Choose a Mentor</label>
            <div className="col-12">
              <div className="toggle">
                <div className="row">
                  {/* Mentor */}
                  { _.map(this.state.mentors, (mentor, key) => (
                    <div className={`col-md-6 mt-3 mt-md-0 ${key > 1 ? 'mt-md-3' : ''}`} key={key}>
                      <div className="card">
                        <input
                          onChange={(e) => this._handleInputTextChanged(e, 'mentor', mentor)}
                          type="radio"
                          name="listMentor"
                          id={`mentor-${key}`}
                        />
                        <label htmlFor={`mentor-${key}`}>
                          <div className="row mx-0">
                            {/* Mentor Image */}
                            <div className="col-3 px-0">
                              <img
                                onError={(e) => { e.target.src = UserImage; }}
                                src={mentor.image || UserImage}
                                className="mentor-img rounded-circle"
                                alt="user"
                              />
                            </div>
                            {/* Mentor Info */}
                            <div className="col-9 pl-4 pr-0">
                              <b>{ _.get(mentor, 'name') }</b>
                              <p className="mb-2">
                                <p className="mb-0 text-blue-kewirus">{!_.isNil(mentor.projectName) ? 'Mentor In Project' : 'Cabin Crew Class Expert'}</p>
                                {/* {' '}
                                <b>
                                  {_.get(mentor, 'projectName')}
                                </b> */}
                              </p>
                              <p>
                                <p className="mb-1">Active Schedule: </p>
                                <p className="text-dark" style={{ whiteSpace: 'pre-line' }}>
                                  {_.get(mentor, 'mentorInfo.available_schedule', '-') || '-'}
                                </p>
                              </p>
                              {/* Expert Portofolio */}
                              <div className="col-12 mx-0 px-0">
                                <div className="social-media mx-auto">
                                  <ul>
                                    {_.get(mentor, 'mentorInfo.linkedin', null) !== null && (
                                      _.get(mentor, 'mentorInfo.linkedin', null) !== ''
                                    ) && (
                                      <li>
                                        <a href={_.get(mentor, 'mentorInfo.linkedin', '#')}>
                                          <img src={LinkedinIcon} alt="linkedin" />
                                        </a>
                                      </li>
                                    ) }
                                    {_.get(mentor, 'mentorInfo.instagram', null) !== null
                                && _.get(mentor, 'mentorInfo.instagram', null) !== '' && (
                                    // eslint-disable-next-line react/jsx-indent
                                      <li>
                                        <a href={_.get(mentor, 'mentorInfo.instagram', '#')}>
                                          <img src={InstagramIcon} alt="ig" />
                                        </a>
                                      </li>
                                    ) }
                                    {_.get(mentor, 'mentorInfo.portofolio', null) !== null
                                && _.get(mentor, 'mentorInfo.portofolio', null) !== '' && (
                                    // eslint-disable-next-line react/jsx-indent
                                      <li>
                                        <a href={_.get(mentor, 'mentorInfo.portofolio', '#')}>
                                          <img src={WebAppLogo} alt="ig" />
                                        </a>
                                      </li>
                                    ) }
                                    { (_.get(mentor, 'mentorInfo.linkedin', null) === null
                                  && _.get(mentor, 'mentorInfo.instagram', null) === null
                                  && _.get(mentor, 'mentorInfo.portofolio', null) === null) && (
                                      // eslint-disable-next-line react/jsx-indent
                                      <p>No profile info</p>
                                    ) }
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  )) }
                  {
                    this.state.mentors.length === 0 && (
                      <label className="text-center my-3 mx-auto">
                        No mentors
                      </label>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mt-3">
              <label htmlFor="schedule_date_picker">Pick Start Date</label>
              <DatePicker
                id="request_meeting_start_date"
                className={`form-control w-100 ${this.state.isMeetingStartDateValidated
                  ? 'is-valid'
                  : _.isNull(this.state.isMeetingStartDateValidated)
                    ? ''
                    : 'is-invalid'
                }`}
                minDate={this.state.minStartDate}
                autoComplete="off"
                showTimeSelect
                placeholderText="Click here!"
                dateFormat="dd/MM/yyyy HH:mm"
                selected={_.get(this.state, 'requestMeet.start_date')}
                onChange={(date, e) => this._handleDateChanged(e, date, 'start_date')}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="schedule_date_picker">Pick End Date</label>
              <DatePicker
                id="request_meeting_end_date"
                className={`form-control w-100 ${this.state.isMeetingEndDateValidated
                  ? 'is-valid'
                  : _.isNull(this.state.isMeetingEndDateValidated)
                    ? ''
                    : 'is-invalid'
                }`}
                minDate={
                  _.isNil(_.get(this.state, 'requestMeet.start_date'))
                    ? this.state.minStartDate
                    : moment(_.get(this.state, 'requestMeet.start_date')).toDate()
                }
                maxDate={
                  _.isNil(_.get(this.state, 'requestMeet.start_date'))
                    ? null
                    : moment(_.get(this.state, 'requestMeet.start_date')).toDate()
                }
                autoComplete="off"
                showTimeSelect
                placeholderText="Click here!"
                dateFormat="dd/MM/yyyy HH:mm"
                selected={_.get(this.state, 'requestMeet.end_date')}
                onChange={(date, e) => this._handleDateChanged(e, date, 'end_date')}
              />
            </div>
          </div>
          <div className="row mt-3">
            <label htmlFor="schedule_notes" className="col-12">Notes</label>
            <div className="col-12">
              <textarea
                className={`form-control ${this.state.isNotesValidated
                  ? 'is-valid'
                  : _.isNull(this.state.isNotesValidated)
                    ? ''
                    : 'is-invalid'
                }`}
                rows="5"
                onChange={(e) => this._handleInputTextChanged(e, 'notes')}
              />
              {
                !this.state.isNotesValidated && (
                  <div className="invalid-feedback">
                    Please input notes field
                  </div>
                )
              }
            </div>
          </div>
          <div className="row mt-3">
            <label htmlFor="schedule_price" className="col-12">Base Price (per hour)</label>
            <div className="col-12">
              <h1>{ formatCurrency(ratePrice) }</h1>
            </div>
          </div>
          <div className="row mt-3">
            <label htmlFor="schedule_price" className="col-12">Total Price</label>
            <div className="col-12">
              <h1>{ `${formatCurrency(total)} (${diff} hours)` }</h1>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-6">
              <button
                type="button"
                onClick={this._handleCloseModal}
                className="btn btn-secondary-kewirus col-12"
              >
                Close
              </button>
            </div>
            <div className="col-6 mb-4">
              <button
                type="submit"
                disabled={
                  !(
                    this.state.isMeetingEndDateValidated
                      && this.state.isMeetingStartDateValidated
                      && this.state.isNotesValidated
                      && this.state.isMentorSelected
                  )
                }
                onClick={this._handleInviteMentor}
                className="btn btn-kewirus col-12"
              >
                Invite
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  _handleInviteMentor = () => {
    const { requestMeet } = this.state;
    const startDate = _.get(requestMeet, 'start_date');
    const endDate = _.get(requestMeet, 'end_date');
    const { email, projectSlug } = _.get(requestMeet, 'mentor');
    const inv = _.omit(requestMeet, ['mentor']);
    _.set(inv, 'email', email);
    _.set(inv, 'projectSlug', projectSlug);

    const filteredInv = {
      ...inv,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      startTime: moment(startDate).format('HH:mm'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      endTime: moment(endDate).format('HH:mm'),
    };

    ApiProject.inviteMeetMentor(filteredInv)
      .then((res) => {
        const { data } = res;
        const { message } = data;
        const msg = message.map((_msg) => `${_msg.message}`).join('');

        this.setState({
          alertShow: true,
          alertMessage: `${msg}`,
          alertType: 'success',
          showMeetModal: false
        });
      })
      .catch((e) => {
        const { response } = e;
        const { message } = response.data;
        const msg = message.map((_msg) => `<li>${_msg.message} for field ${_msg.field}</li>`).join('');

        this.setState({
          alertShow: true,
          alertMessage: `Failed to invite mentor. because of: </br>${msg}`,
          alertType: 'error',
        });
      });
  }

  _handleConfirmInvitation = (_e, action = 'confirmed', code) => {
    ApiMeeting.confirmMeet(action, code)
      .then((res) => {
        const { data } = res;
        const { message } = data;
        const msg = message.map((_msg) => `${_msg.message}`).join('');
        const { sections } = this.state;
        const calendarSection = _.head(sections);
        calendarSection.items = [[]];
        sections[0] = calendarSection;

        this.setState({
          alertShow: true,
          alertMessage: `${msg}`,
          alertType: 'success',
          sections
        }, this._getInvitations);
      })
      .catch((e) => {
        const { response } = e;
        const { message } = response.data;
        const msg = message.map((_msg) => `<li>${_msg.message} for field ${_msg.field}</li>`).join('');

        this.setState({
          alertShow: true,
          alertMessage: `Failed to confirm meeting. because of: </br>${msg}`,
          alertType: 'error',
        });
      });
  }

  _handleCloseModal = () => {
    this.setState({
      showMeetModal: false,
    });
  }

  render() {
    const { sections } = this.state;

    return (
      <div className="container-schedules col container-page-kewirus">
        {
          this.state.alertShow && (
            <div
              className={`alert ${_.isEqual(this.state.alertType, 'error') ? 'alert-danger' : 'alert-success'} alert-dismissible fade show`}
              role="alert"
              style={{
                position: 'fixed',
                top: '10%',
                zIndex: '10'
              }}
            >
              <p>
                {parse(this.state.alertMessage)}
              </p>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ alertMessage: '', alertShow: false })}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )
        }
        {/* Search Bar and Logo */}
        <div className="row">
          {/* Logo */}
          <a href="/" className="kewirus-desktop-only col-auto mb-5 ml-auto">
            <img src={AppLogo} className="app-logo" alt="Cabin Crew Class Logo" />
          </a>
        </div>
        {/* Modal: event detail modal */}
        { this._renderEventDetailModal() }
        {/* Modal: meeting request modal */}
        { this._renderMeetRequestModal() }
        {/* Title */}
        <div className="row mx-0">
          <PageTitle
            title="My Schedule"
            subtitle={`${this.state.mySchedule.total} schedule(s)`}
          />
        </div>
        {/* Nav Tabs and Button Add Schedule */}
        <div className="row">
          {/* Nav Tabs */}
          <div className="col-12">
            <NavTabs
              sections={(() => {
                const kewirusType = _.get(this, 'state.user.kewirusType');
                if (kewirusType !== 'institution') {
                  return sections;
                }

                return sections.filter((section) => section.title !== 'Meeting requests');
              })()}
              onTabClick={() => {}}
            />
          </div>
          {/* Button */}
          <button
            type="button"
            className="btn btn-kewirus btn-add-new"
            onClick={() => this.setState({ showMeetModal: true })}
            style={{
              display: !_.isEqual('institution', _.get(this, 'state.user.kewirusType', null))
                ? '' : 'none'
            }}
          >
            + Mentoring
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(Schedules);
