/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import parse from 'html-react-parser';

import DatePicker from 'react-datepicker';
import Autosuggest from 'react-autosuggest';
import { PageTitle } from '../../components';

import ApiEvent from '../../services/api/event';
import ApiAuth from '../../services/api/auth';

import AppLogo from '../../assets/app-logo.png';
import UploadImg from '../../assets/upload-image.png';
import { validateEmail, validateStringLen } from '../../utils';

import 'react-datepicker/dist/react-datepicker.css';

const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const addDots = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '');
class CreateEditEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
      slug: '',
      imageBase64: null,

      isEventNameValidated: null,
      isEventPriceValidated: null,
      isEventDescValidated: null,
      isEventQuotaValidated: null,
      isEventProfPicValidated: null,
      isEventStartDateValidated: false,
      isEventEndDateValidated: false,
      isEventCreatorValidated: null,

      userSuggestions: [],

      minStartDate: moment().toDate(),

      alertShow: false,
      alertMessage: '',
      alertType: 'error',
    };
  }

  componentDidMount() {
    // get event if operation is update
    const slug = _.get(this.props, 'match.params.slug');
    ApiEvent.getEventDetail(slug)
      .then((res) => {
        const data = _.get(res, 'data.data');
        const startDate = moment(data.startedAt).toDate();
        const endDate = moment(data.endAt).toDate();
        this.setState({
          slug,
          event: {
            ...data,
            quotaStr: addDots(
              removeNonNumeric(data.quota)
            ),
            priceStr: addCommas(
              removeNonNumeric(data.price)
            ),
            start_date: startDate,
            end_date: endDate
          },
          isEventNameValidated: true,
          isEventPriceValidated: true,
          isEventDescValidated: true,
          isEventQuotaValidated: true,
          isEventProfPicValidated: true,
          isEventStartDateValidated: true,
          isEventEndDateValidated: true,
          isEventCreatorValidated: true,
        });
      });
    this.getUsersByEmail();
  }

  _handleInputTextChanged = (e, field) => {
    let { value } = e.target;

    if (_.isEqual(field, 'name')) {
      const validate = validateStringLen(value, 1);
      // transform to upperCase for first letter only
      value = value.split(' ').map((word) => {
        if (typeof word[0] === 'string') {
          return word[0].toUpperCase() + word.slice(1);
        }
        return word;
      }).join(' ');
      this.setState({
        isEventNameValidated: validate
      });
    } else if (_.isEqual(field, 'description')) {
      const validate = validateStringLen(value, 200);
      this.setState({
        isEventDescValidated: validate
      });
    } else if (_.isEqual(field, 'creatorEmail')) {
      const validate = validateEmail(value);
      this.setState({
        isEventCreatorValidated: validate
      });
    }

    this.setState({
      event: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.event,
        [field]: value
      }
    });
  }

  _handleInputComma = (e, type) => {
    const { value } = e.target;
    const isPriceField = _.isEqual(type, 'price');
    const isQoutaField = _.isEqual(type, 'quota');

    if (isPriceField) {
      const valueInNumber = Number(value.split(',').join(''));
      const validate = valueInNumber >= 0;

      this.setState({
        event: {
          // eslint-disable-next-line react/no-access-state-in-setstate
          ...this.state.event,
          price: valueInNumber,
          priceStr: addCommas(
            removeNonNumeric(value)
          )
        },
        isEventPriceValidated: validate
      });
    } else if (isQoutaField) {
      const valueInNumber = Number(value.split('.').join(''));
      const validate = valueInNumber > 0;

      this.setState({
        event: {
          // eslint-disable-next-line react/no-access-state-in-setstate
          ...this.state.event,
          quota: valueInNumber,
          quotaStr: addDots(
            removeNonNumeric(value)
          )
        },
        isEventQuotaValidated: validate
      });
    }
  }

  _handleSaveEvent = () => {
    const { event, slug } = this.state;
    const startDate = _.get(event, 'start_date');
    const endDate = _.get(event, 'end_date');
    const evt = _.omit(event, ['quotaStr', 'priceStr', 'start_date', 'end_date']);

    const filteredEvent = {
      ...evt,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      start_time: moment(startDate).format('HH:mm'),
      start_offset: '+07',
      end_date: moment(endDate).format('YYYY-MM-DD'),
      end_time: moment(endDate).format('HH:mm'),
      end_offset: '+07'
    };

    if (_.isEmpty(slug) || _.isUndefined(slug)) {
    // hit event api
      ApiEvent.createEvent(filteredEvent)
        .then((res) => {
          const { data } = res;
          const { message } = data;
          const msg = message.map((_msg) => `${_msg.message}`).join('');

          // eslint-disable-next-line react/prop-types
          this.props.history.replace('/events', {
            alertShow: true,
            alertMessage: `${msg}`,
            alertType: 'success'
          });
        })
        .catch((e) => {
          const { response } = e;
          const { message } = response.data;
          const msg = message.map((_msg) => `<li>${_msg.message} for field ${_msg.field}</li>`).join('');

          this.setState({
            alertShow: true,
            alertMessage: `Failed to create event. because of: </br>${msg}`,
            alertType: 'error',
          });
        });
    } else {
      ApiEvent.editEvent(filteredEvent, slug)
        .then((res) => {
          const { data } = res;
          const { message } = data;
          const msg = message.map((_msg) => `${_msg.message}`).join('');

          // eslint-disable-next-line react/prop-types
          this.props.history.replace('/events', {
            alertShow: true,
            alertMessage: `${msg}`,
            alertType: 'success'
          });
        })
        .catch((e) => {
          const { response } = e;
          const { message } = response.data;
          const msg = message.map((_msg) => `<li>${_msg.message} for field ${_msg.field}</li>`).join('');

          this.setState({
            alertShow: true,
            alertMessage: `Failed to update event. because of: </br>${msg}`,
            alertType: 'error',
          });
        });
    }
  }

  _handleDateChanged = (_e, date, type) => {
    const validation = {};
    if (_.isEqual(type, 'start_date')) {
      const endDate = _.get(this.state, 'event.end_date');
      if (!_.isUndefined(endDate)) {
        const endDateMoment = moment(endDate);
        if (moment(date).isBefore(endDateMoment)) {
          _.set(validation, 'isEventStartDateValidated', true);
          _.set(validation, 'isEventEndDateValidated', true);
        } else {
          _.set(validation, 'isEventStartDateValidated', false);
        }
      } else _.set(validation, 'isEventStartDateValidated', true);
    } else {
      const startDate = _.get(this.state, 'event.start_date');
      if (!_.isUndefined(startDate)) {
        const startDateMoment = moment(startDate);
        if (moment(date).isAfter(startDateMoment)) {
          _.set(validation, 'isEventEndDateValidated', true);
          _.set(validation, 'isEventStartDateValidated', true);
        } else {
          _.set(validation, 'isEventEndDateValidated', false);
        }
      } else _.set(validation, 'isEventEndDateValidated', true);
    }
    this.setState({
      event: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.event,
        [type]: date
      },
      ...validation
    });
  }

  _handleChooseImage = () => {
    this.uploader.click();
  }

  _onChangeFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // get image file
    const file = _.get(e, 'target.files[0]');
    if (_.isNil(file)) return;

    // read file blob and convert to base64
    const reader = new FileReader();
    reader.onload = (_e) => {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        imageBase64: _.get(_e, 'target.result'),
        isEventProfPicValidated: true
      });
    };
    reader.readAsDataURL(file);

    this.setState({
      event: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.event,
        thumbnail: file
      }
    });
  }

  getUsersByEmail = (email) => {
    ApiAuth.getUserListByEmail(email || 'a')
      .then((res) => {
        const { data } = res;
        if (typeof (data) === 'object') {
          const { data: list } = data;
          this.setState({ userSuggestions: list });
        } else {
          this.setState({ userSuggestions: data });
        }
      });
  }

  render() {
    return (
      <div className="container-event-create col container-page-kewirus">
        {
          this.state.alertShow && (
            <div
              className={`alert ${_.isEqual(this.state.alertType, 'error') ? 'alert-danger' : 'alert-success'} alert-dismissible fade show`}
              role="alert"
              style={{
                position: 'fixed',
                top: '10%',
                zIndex: '10'
              }}
            >
              <p>
                {parse(this.state.alertMessage)}
              </p>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ alertMessage: '', alertShow: false })}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )
        }
        {/* Search Bar and Logo */}
        <div className="row">
          {/* Logo */}
          <a href="/" className="kewirus-desktop-only col-auto mb-5 ml-auto">
            <img src={AppLogo} className="app-logo" alt="Cabin Crew Class Logo" />
          </a>
        </div>

        {/* Title */}
        <div className="row mx-0">
          <PageTitle
            title={`${_.isEmpty(this.state.slug) ? 'Create New' : 'Edit'} Event`}
            subtitle=""
          />
        </div>

        {/* Card */}
        <div className="row pt-5 mt-3 mb-3 mx-0 event-card">
          {/* Event Thumbnail */}
          <div className="col-md-5">
            <label htmlFor="event_thumbnail"><b>Thumbnail</b></label>
            <br />
            <img
              onError={(e) => { e.target.src = UploadImg; }}
              src={_.isNull(this.state.imageBase64)
                ? this.state.event.thumbnail || UploadImg
                : this.state.imageBase64}
              style={{
                padding: _.isNull(this.state.imageBase64)
                  && _.isNil(this.state.event.thumbnail)
              }}
              className="thumbnail-event-create"
              width="100%"
              onClick={this._handleChooseImage}
            />
            <input
              type="file"
              accept="image/*"
              id="file"
              ref={(ref) => { this.uploader = ref; }}
              style={{ display: 'none' }}
              onChange={this._onChangeFile}
            />
          </div>
          {/* Event Information */}
          <div className="col-md-7">
            <div className="row">
              {/* Event Name */}
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="event_name"><b>Title</b></label>
                  <input
                    type="text"
                    onChange={(e) => this._handleInputTextChanged(e, 'name')}
                    className={`form-control ${this.state.isEventNameValidated
                      ? 'is-valid'
                      : _.isNull(this.state.isEventNameValidated)
                        ? ''
                        : 'is-invalid'
                    }`}
                    id="event_name"
                    placeholder="E.g. Webinar Industri 4.0"
                    value={this.state.event.name}
                    required
                  />
                  {
                    !this.state.isEventNameValidated && (
                      <div className="invalid-feedback">
                        Please input event name field
                      </div>
                    )
                  }
                </div>
              </div>
              {/* Select User */}
              <div className="col-12 form-group">
                {this.state.slug === ''
                && (
                  <>
                    <label htmlFor="company_location"><b>Event Creator (by email)</b></label>
                    <Autosuggest
                      suggestions={this.state.userSuggestions}
                      className={`form-control ${this.state.isEventCreatorValidated
                        ? 'is-valid'
                        : _.isNull(this.state.isEventCreatorValidated)
                          ? ''
                          : 'is-invalid'
                      }`}
                      id="project_company_loc"
                      inputProps={{
                        value: this.state.event.creatorEmail || '',
                        placeholder: 'E.g. johndoe@example.com',
                        onChange: (_e, { newValue }) => this._handleInputTextChanged({ target: { value: newValue } }, 'creatorEmail'),
                        onClick: (e) => {
                          this._handleInputTextChanged(e, 'creatorEmail');
                        },
                      }}
                      theme={{
                        input: 'form-control',
                        suggestionsList: 'autocomplete-suggest-list',
                        suggestion: 'autocomplete-suggest-item'
                      }}
                      renderSuggestion={(suggestion) => (
                        <div
                          className="form-control"
                        >
                          { `${suggestion.name} (${_.get(suggestion, 'email')})` }
                        </div>
                      )}
                      onSuggestionsFetchRequested={({ value }) => {
                        // - hit suggestions
                        this.getUsersByEmail(value);
                      }}
                      onSuggestionsClearRequested={() => {
                        this.setState({ userSuggestions: [] });
                      }}
                      getSuggestionValue={(sugg) => sugg.email}
                    />
                  </>
                )}
                {
                  this.state.slug !== '' && (
                    <>
                      <label htmlFor="company_location"><b>Creator Name</b></label>
                      <input
                        type="text"
                        className="form-control"
                        id="creator_name"
                        placeholder="E.g. john doe"
                        value={this.state.event.creator}
                      />
                    </>
                  )
                }
              </div>
              {/* Event Price */}
              <div className="col-md-6 mt-3">
                <div className="form-group">
                  <label htmlFor="event_price"><b>Price</b></label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">Rp</div>
                    </div>
                    <input
                      type="text"
                      autoComplete="off"
                      onInput={(e) => this._handleInputComma(e, 'price')}
                      className={`form-control ${this.state.isEventPriceValidated
                        ? 'is-valid'
                        : _.isNull(this.state.isEventPriceValidated)
                          ? ''
                          : 'is-invalid'
                      }`}
                      id="event_price"
                      placeholder="E.g. 100000"
                      value={this.state.event.priceStr}
                      required
                    />
                    {
                      !this.state.isEventPriceValidated && (
                        <div className="invalid-feedback">
                          Event price must be greater than or equal to IDR 0,-
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              {/* Event Quota */}
              <div className="col-md-6 mt-3">
                <div className="form-group">
                  <label htmlFor="event_quota"><b>Quota</b></label>
                  <div className="input-group">
                    <input
                      type="text"
                      autoComplete="off"
                      onInput={(e) => this._handleInputComma(e, 'quota')}
                      className={`form-control ${this.state.isEventQuotaValidated
                        ? 'is-valid'
                        : _.isNull(this.state.isEventQuotaValidated)
                          ? ''
                          : 'is-invalid'
                      }`}
                      id="event_quota"
                      placeholder="E.g. 40"
                      value={this.state.event.quotaStr}
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="basic-addon2"> slot</span>
                    </div>
                    {
                      !this.state.isEventPriceValidated && (
                        <div className="invalid-feedback">
                          Event quota must be greater than zero slot
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              {/* Event Start Date */}
              <div className="col-md-6 mt-3">
                <div className="form-group">
                  <label htmlFor="event_start_date"><b>Start Date</b></label>
                  <br />
                  <DatePicker
                    id="event_start_date"
                    className={`form-control ${this.state.isEventStartDateValidated
                      ? 'is-valid'
                      : _.isNull(this.state.isEventStartDateValidated)
                        ? ''
                        : 'is-invalid'
                    }`}
                    showTimeSelect
                    autoComplete="off"
                    minDate={this.state.minStartDate}
                    placeholderText="Click here!"
                    dateFormat="dd/MM/yyyy HH:mm"
                    selected={_.get(this.state, 'event.start_date')}
                    onChange={(date, e) => this._handleDateChanged(e, date, 'start_date')}
                  />
                </div>
              </div>
              {/* Event End Date */}
              <div className="col-md-6 mt-3">
                <div className="form-group">
                  <label htmlFor="event_end_date"><b>End Date</b></label>
                  <br />
                  <DatePicker
                    id="event_end_date"
                    className={`form-control ${this.state.isEventEndDateValidated
                      ? 'is-valid'
                      : _.isNull(this.state.isEventEndDateValidated)
                        ? ''
                        : 'is-invalid'
                    }`}
                    showTimeSelect
                    autoComplete="off"
                    minDate={
                      _.isNil(_.get(this.state, 'event.start_date'))
                        ? this.state.minStartDate
                        : moment(_.get(this.state, 'event.start_date')).toDate()
                    }
                    placeholderText="Click here!"
                    dateFormat="dd/MM/yyyy HH:mm"
                    selected={_.get(this.state, 'event.end_date')}
                    onChange={(date, e) => this._handleDateChanged(e, date, 'end_date')}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Event Description */}
          <div className="col-12 mt-5">
            <div className="form-group">
              <label htmlFor="event_desc"><b>Description</b></label>
              <textarea
                className={`form-control ${this.state.isEventDescValidated
                  ? 'is-valid'
                  : _.isNull(this.state.isEventDescValidated)
                    ? ''
                    : 'is-invalid'
                }`}
                style={{
                  resize: 'vertical'
                }}
                id="event_desc"
                placeholder="Enter your event description"
                rows="15"
                value={this.state.event.description}
                required
                onChange={(e) => this._handleInputTextChanged(e, 'description')}
              />
              {
                !this.state.isEventDescValidated && (
                  <div className="invalid-feedback">
                    Please input event description with minimal 200 characters
                  </div>
                )
              }
            </div>
          </div>
          <div className="col-12 mt-5 mb-3 text-right">
            <button
              type="button"
              className="btn btn-kewirus"
              onClick={this._handleSaveEvent}
              disabled={!(
                this.state.isEventDescValidated && this.state.isEventNameValidated
                && this.state.isEventQuotaValidated && this.state.isEventPriceValidated
                && this.state.isEventProfPicValidated && this.state.isEventEndDateValidated
                && this.state.isEventStartDateValidated && this.state.isEventCreatorValidated
              )}
            >
              Save Event
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateEditEvent;
