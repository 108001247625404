import currencyFormatter from 'currency-formatter';

const isLogin = () => {
  if (localStorage.getItem('kewirus_t')) {
    return true;
  }

  return false;
};

const validateEmail = (value) => {
  // eslint-disable-next-line no-useless-escape
  const pat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return pat.test(String(value).toLowerCase());
};

const validateStringLen = (value, minLength = 8) => value.length >= minLength;

const validatePhoneNumber = (value) => {
  const pat = /^8[0-9]{9,}$/;
  return pat.test(String(value));
};

const dateTimeTmplt = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
const formatCurrency = (value) => currencyFormatter.format(value, {
  symbol: 'Rp',
  decimal: ',',
  thousand: '.',
  precision: 2,
  format: '%s %v' // %s is the symbol and %v is the value
});

export {
  isLogin,
  validateEmail,
  validatePhoneNumber,
  validateStringLen,
  formatCurrency,

  dateTimeTmplt
};
