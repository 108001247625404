import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import './styles.scss';
import { dateTimeTmplt, formatCurrency } from '../../utils';
import ExampleProfile from '../../assets/user-no-image.png';
import ExampleThumbnail from '../../assets/no-image.png';
import InstagramIcon from '../../assets/instagram.png';
import LinkedinIcon from '../../assets/linkedin.png';
import DefaultProjectThumbnail from '../../assets/project-no-image.png';
import AppLogo from '../../assets/logo.png';

import Card from '../Card';
import Pagination from '../Pagination';
import Shimmer from '../Shimmer';

const location = window.location.pathname;
const isPublicPath = _.isEqual(location, '/published-projects');
const jsonUsr = localStorage.getItem('kewirus_u');
const user = JSON.parse(jsonUsr);

/**
 * render `class` cards
 * url: /class
 * @param {object} items data
 * @param {func} onItemClick callback function when card is clicked
 * @param {bool} isLoading loading state indicate if image is still in load process
 */
const _renderProjectCards = (items, onItemClick, isLoading) => (
  items.map((item, idx) => (
    <div
      // eslint-disable-next-line react/no-array-index-key
      key={`card-project-${idx}`}
      className="col-lg-4 col-xs-6 col-md-6 mt-4"
    >
      <Card onClick={(e) => {
        if (_.isObject(item) && !_.isNil(item)) {
          onItemClick(e, item, 'viewAction');
        }
      }}
      >
        {/* Thumbnail */}
        <div className="row mx-0">
          { isLoading ? (
            <Shimmer />
          ) : (
            <img
              onError={(e) => { e.target.src = DefaultProjectThumbnail; }}
              src={item.thumbnail || DefaultProjectThumbnail}
              className="thumbnail-project"
              alt="thumbnail project"
              width="100%"
            />
          ) }
          {/* <iframe width="100%" height="200"
            src="https://www.youtube.com/embed/tgbNymZ7vqY">
          </iframe> */}
        </div>
        {/* Institution */}
        <div className="row">
          {
            !_.isEqual(_.get(user, 'kewirusType', ''), 'institution')
            && _.get(item, 'institution', null) !== null && (
              <div className="col-auto pr-0 align-items-center">
                <img
                  className="float-left mt-3 ml-3 rounded-circle"
                  src={_.get(item, 'institution.logo') || AppLogo}
                  width="40px"
                  height="40px"
                  onError={(e) => { e.target.src = AppLogo; }}
                  alt="project_institution_logo"
                  style={{
                    objectFit: 'cover'
                  }}
                />
              </div>
            )
          }
          {/* Category */}
          <div className="col-auto">
            <div className="row mx-0 px-3 py-1 mt-2">
              { item.type }
            </div>
            {/* Title Project */}
            <div className="row mx-0 px-3 pt-1 pb-2">
              <h2>{ item.name }</h2>
            </div>
          </div>
        </div>
        {/* Published Date */}
        <div className="row mx-0 pt-1 pb-3">
          <div className="col-12 text-blue-kewirus">
            <span className="pr-2">
              <i className="fa fa-clock-o" aria-hidden="true" />
              {
                isLoading && (
                  <Shimmer
                    inline
                    style={{
                      verticalAlign: 'top',
                      paddingLeft: '8px',
                      height: '20px',
                      width: '50%',
                    }}
                  />
                )
              }
            </span>
            <span>
              {
                !isLoading && (
                  <>
                    Created at
                    {` ${_.get(item, 'createdAt')}`}
                  </>
                )
              }
            </span>
          </div>
        </div>
        {/* Button Publish */}
        {
          // @TODO: hideTemporary
          // _.isNil(item.projectType) && (
          //   <div className="row mx-0 publish-button">
          //     <button
          //       type="button"
          //       className="btn btn-kewirus px-4"
          //       disabled={item.isPublish}
          //       onClick={(e) => onItemClick(e, item, 'publishAction')}
          //     >
          //       { item.isPublish ? 'Published' : 'Publish' }
          //     </button>
          //   </div>
          // )
        }
        {/* Action */}
        {
          // _.isEqual(_.get(user, 'kewirusType'), 'admin')
          // && (
          <div
            className="row mx-auto"
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
            }}
          >
            <div className="col px-0">
              <button
                type="button"
                className="action-button-delete btn"
                onClick={(e) => {
                  if (_.isObject(item) && !_.isNil(item)) {
                    onItemClick(e, item, 'delete');
                  }
                }}
                style={{ display: item.access !== 'admin' && _.isNil(item.id) ? 'none' : '' }}
              >
                <span className="text-white">
                  <i className="fa fa-trash" aria-hidden="true" />
                </span>
              </button>
            </div>
          </div>
          // )
        }
      </Card>
    </div>
  ))
);

/**
 * render `published-projects` cards
 * url: /class, /published-projects
 * @param {object} items data
 * @param {func} onItemClick callback function when card is clicked
 * @param {bool} isLoading loading state indicate if image is still in load process
 */
const _renderPublishedProjectCards = (items, onItemClick, isLoading) => (
  items.map((item, idx) => (
    idx < 12 && (
      // eslint-disable-next-line react/no-array-index-key
      <div className={`col-md-6 col-xs-12 ${isPublicPath && items.length === 1 ? 'col-lg-10' : 'col-lg-4'} mt-4`} key={`published-${idx}`}>
        <Card
          onClick={(e) => {
            if (_.isObject(item) && !_.isNil(item)) {
              onItemClick(e, item);
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          <div className="row mx-0">
            {/* Thumbnail */}
            { isLoading ? (
              <Shimmer />
            ) : (
              <img
                onError={(e) => { e.target.src = DefaultProjectThumbnail; }}
                src={item.thumbnail || DefaultProjectThumbnail}
                className="thumbnail-project"
                alt="thumbnail project"
                width="100%"
              />
            ) }
          </div>
          {/* Project type */}
          <div className="row mx-0 px-3 py-1 mt-2">
            { item.projectType }
          </div>
          {/* Logo, Title, Location */}
          <div className="row mx-0 mt-2">
            {/* Logo */}
            <div className="col-auto pr-0 pb-3">
              <img
                height="50px"
                width="50px"
                onError={(e) => { e.target.src = AppLogo; }}
                src={_.get(item, 'companyLogo') === ''
                || _.get(item, 'companyLogo') === null ? AppLogo : item.companyLogo}
                alt="institusi"
                className="rounded-circle"
                style={{
                  objectFit: 'cover'
                }}
              />
            </div>
            <div className="col-auto pr-0 pb-3">
              <img
                height="50px"
                width="50px"
                onError={(e) => { e.target.src = AppLogo; }}
                src={_.get(item, 'institutionLogo') === ''
                || _.get(item, 'institutionLogo') === null ? AppLogo : item.institutionLogo}
                alt="institusi"
                className="rounded-circle"
                style={{
                  display: _.get(item, 'institutionLogo') === null ? 'none' : '',
                  objectFit: 'cover'
                }}
              />
            </div>
            {/* Title & Location */}
            <div className="col align-self-center">
              <h2>{ item.name }</h2>
              <p className="text-grey-kewirus">
                { item.companyName }
              </p>
            </div>
            {/* Action */}
            {
              // (_.isEqual(_.get(user, 'kewirusType'), 'admin')
              // && (
              <div
                className="row mx-auto"
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  display: isPublicPath ? 'none' : ''
                }}
              >
                <div className="col pl-0 pr-1">
                  <button
                    type="button"
                    className="action-button-edit btn"
                    onClick={(e) => {
                      if (_.isObject(item) && !_.isNil(item)) {
                        onItemClick(e, item, 'edit');
                      }
                    }}
                  >
                    <span className="text-white">
                      <i className="fa fa-pencil" aria-hidden="true" />
                    </span>
                  </button>
                </div>
                <div className="col px-0">
                  <button
                    type="button"
                    className="action-button-delete btn"
                    onClick={(e) => {
                      if (_.isObject(item) && !_.isNil(item)) {
                        onItemClick(e, item, 'delete');
                      }
                    }}
                  >
                    <span className="text-white">
                      <i className="fa fa-trash" aria-hidden="true" />
                    </span>
                  </button>
                </div>
              </div>
              // )
            }
          </div>
        </Card>
      </div>
    )
  ))
);

/**
 * render `course category` cards
 * url: /courses
 * @param {object} items data
 * @param {func} onItemClick callback function when card is clicked
 * @param {number} limit current card(s) that will display to user
 */
const _renderCourseCategoryCards = (items, onItemClick, onViewMoreClick, limit) => (
  items.map((item, idx) => {
    if (limit > 3 || item.length < 3) {
      // if total card is less than 3 or viewmore has clicked before
      const tempCard = (
        <div
          className={
            `${item.name === 'All'
              ? 'col-lg-4 col-xs-12 col-md-6 mt-4 order-first'
              : 'col-lg-4 col-xs-12 col-md-6 mt-4'}`
          }
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.name}-${idx}`}
        >
          <Card onClick={(e) => {
            if (_.isObject(item) && !_.isNil(item)) {
              onItemClick(e, item);
            }
          }}
          >
            <img
              onError={(e) => { e.target.src = ExampleThumbnail; }}
              src={item.image || ExampleThumbnail}
              className="thumbnail-category-course"
              alt="thumbnail course"
            />
            <div className="thumbnail-category-course-label px-4 py-2">
              <b className="text-white">
                {item.name}
              </b>
              {/* <p className="text-white">
                {item.total}
                {' '}
                Lessons
              </p> */}
            </div>
          </Card>
        </div>
      );
      if (idx < limit) {
        if (idx < limit - 1) {
          return tempCard;
        }
        return (
          <>
            {tempCard}
            <div className="col-lg-4 col-xs-12 col-md-6 mt-4" key="view-more">
              <Card
                className="viewmore-card"
                onClick={onViewMoreClick}
              >
                <h1 className="text-white mx-auto my-0 text-center">View More</h1>
              </Card>
            </div>
          </>
        );
      }

      return null;
    }
    return null;
  })
);

/**
 * render `courses` cards
 * url: /course/browse/:categorySlug
 * @param {object} items data
 * @param {func} onItemClick callback function when card is clicked
* */
const _renderCourseCards = (items, onItemClick, isLoading) => (
  items.map((item, idx) => (
    idx < 6 && (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={`${item.slug}-${idx}`}
        className="col-lg-4 col-xs-12 col-md-6 mt-4"
      >
        <Card onClick={(e) => {
          if (_.isObject(item) && !_.isNil(item)) {
            onItemClick(e, item, 'view');
          }
        }}
        >
          {/* Thumbnail */}
          <div className="row mx-0 container-category-course">
            { isLoading ? (
              <Shimmer />
            ) : (
              <img
                onError={(e) => { e.target.src = ExampleThumbnail; }}
                src={item.thumbnail || ExampleThumbnail}
                className="thumbnail-course"
                alt="thumbnail course"
                width="100%"
              />
            ) }
            {/* Included Category */}
            <div className="container-category-course-list row mx-0">
              {
                _.map(item.tags, (tag, key) => (
                  <div
                    key={key}
                    className="container-category-course-list-item col-auto mb-2"
                  >
                    <span className="text-white text-center">{tag}</span>
                  </div>
                ))
              }
            </div>
          </div>
          {/* Course Title */}
          <div className="row mx-0 px-3 pt-1 mt-2">
            <h2>{item.name}</h2>
          </div>
          {/* Course Description */}
          <div className="row mx-0 px-3 pb-1">
            {item.description}
          </div>
          {/* Created Date Course */}
          <div className="text-grey-kewirus row mx-0 px-3 pt-1 pb-1">
            <span className="pr-2"><i className="fa fa-clock-o" aria-hidden="true" /></span>
            Created at
            {' '}
            {moment(_.get(item, 'createdAt')).format('DD MMMM YYYY')}
          </div>
          <hr />
          {/* Author and Price */}
          <div className="row mx-0 pt-2 pb-4 px-2">
            {/* Author Profile */}
            <div className="col-2 align-self-center pl-1">
              <img
                onError={(e) => { e.target.src = ExampleProfile; }}
                src={_.get(item, 'creator.avatar') || ExampleProfile}
                alt="author"
                width="40"
                height="40"
                className="rounded-circle"
              />
            </div>
            {/* Author Name */}
            <div className="col-auto align-self-center px-1">
              <b>{_.get(item, 'creator.name')}</b>
            </div>
            {/* Price */}
            <div className="col-auto align-self-center ml-auto px-1">
              <h1 className="text-blue-kewirus float-right">{item.price > 0 && formatCurrency(item.price)}</h1>
              <h1 className="text-blue-kewirus float-right">{item.price === 0 && 'Free!'}</h1>
            </div>
          </div>
          {/* Add cart button */}
          {
            _.isEqual(item.allowed, false) && item.price > 0 && (
              <div className="row mx-0 add-cart-button">
                <button
                  type="button"
                  onClick={(e) => onItemClick(e, item, 'buy')}
                  className="btn btn-kewirus px-4"
                >
                  Buy
                </button>
              </div>
            )
          }
        </Card>
      </div>
    )
  ))
);

/**
 * render `events` cards
 * url: /events
 * @param {object} items data
 * @param {func} onItemClick callback function when card is clicked
 */
const _renderEventCards = (items, onItemClick, isLoading) => (
  items.map((item, idx) => (
    idx < 3 && (
      // eslint-disable-next-line react/no-array-index-key
      <div className="col-lg-4 col-xs-12 col-md-6 mt-4" key={`event-card-${idx}`}>
        <Card onClick={(e) => {
          if (_.isObject(item) && !_.isNil(item)) {
            onItemClick(e, item, 'detail');
          }
        }}
        >
          <div className="container-card-event">
            {/* Thumbnail */}
            <div className="row mx-0">
              { isLoading ? (
                <Shimmer />
              ) : (
                <img
                  onError={(e) => { e.target.src = ExampleThumbnail; }}
                  src={item.thumbnail || ExampleThumbnail}
                  className="thumbnail-event"
                  alt="thumbnail event"
                  width="100%"
                />
              ) }
            </div>
            {/* Category
            <div className="row mx-0 px-3 py-1 mt-2">
              { item.category || '-' }
            </div> */}
            {/* Title Event */}
            <div className="row mx-0 px-3 pt-1 mt-2">
              <h2>{ item.name }</h2>
            </div>
            {/* Author Event */}
            <div className="row mx-0 px-3 pb-1">
              <b>
                { !isLoading && (
                  <>
                    By
                    {' '}
                    { item.creator }
                  </>
                )}
              </b>
            </div>
            {/* Deadline Date Event */}
            <div className="text-grey-kewirus row mx-0 px-3 pt-1">
              <span className="pr-2"><i className="fa fa-clock-o" aria-hidden="true" /></span>
              { isLoading ? (
                <Shimmer
                  inline
                  style={{
                    verticalAlign: 'top',
                    paddingLeft: '8px',
                    height: '20px',
                    width: '65%',
                  }}
                />
              ) : (
                <>
                  Until
                  {' '}
                  { moment(item.endAt, dateTimeTmplt).format('HH:mm A, DD MMMM YYYY') }
                </>
              )}
            </div>
            {/* Kuota Event */}
            <div className="row mx-0 pb-3 pt-1">
              {/* Total Seat */}
              { isLoading ? (
                <Shimmer
                  inline
                  style={{
                    verticalAlign: 'top',
                    paddingLeft: '8px',
                    marginTop: '4px',
                    height: '20px',
                    width: '60%',
                  }}
                />
              ) : (
                <div className="col">
                  { item.quota }
                  {' '}
                  Seat
                </div>
              )}

              {
                !isLoading && (_.isEqual(item.attend, true)
                  ? (
                    <div className="col text-right text-success">Registered</div>
                  )
                  : (
                    <div className="col text-right text-danger">
                      { item.remainingQuota }
                      {' '}
                      seats left
                    </div>
                  ))
              }
            </div>
            {/* Free flag */}
            {
              !isLoading && _.isEqual(item.price, 0) && (
                <div className="row mx-0 publish-button">
                  <button
                    type="button"
                    className="btn btn-kewirus px-4"
                    disabled={item.published}
                  >
                    Free
                  </button>
                </div>
              )
            }
            {/* Action */}
            {
              _.isEqual(_.get(user, 'kewirusType'), 'admin')
              && (
                <div
                  className="row mx-auto"
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                  }}
                >
                  <div className="col pl-0 pr-1">
                    <button
                      type="button"
                      className="action-button-edit btn"
                      onClick={(e) => {
                        if (_.isObject(item) && !_.isNil(item)) {
                          onItemClick(e, item, 'edit');
                        }
                      }}
                    >
                      <span className="text-white">
                        <i className="fa fa-pencil" aria-hidden="true" />
                      </span>
                    </button>
                  </div>
                  <div className="col px-0">
                    <button
                      type="button"
                      className="action-button-delete btn"
                      onClick={(e) => {
                        if (_.isObject(item) && !_.isNil(item)) {
                          onItemClick(e, item, 'delete');
                        }
                      }}
                    >
                      <span className="text-white">
                        <i className="fa fa-trash" aria-hidden="true" />
                      </span>
                    </button>
                  </div>
                </div>
              )
            }
          </div>
        </Card>
      </div>
    )
  ))
);

/**
 * render `experts` cards
 * url: /experts
 * @param {object} items data
 * @param {func} onItemClick callback function when card is clicked
* */
const _renderExpertCards = (items, onItemClick, isLoading) => (
  items.map((item, idx) => (
    <div
      // eslint-disable-next-line react/no-array-index-key
      key={`expert-${item.code}-${idx}`}
      className="col-lg-3 col-xs-12 col-md-6 mt-4"
    >
      <Card onClick={() => {}}>
        {/* Thumbnail */}
        <div className="row mx-0 container-category-course">
          { isLoading ? (
            <Shimmer />
          ) : (
            <img
              onError={(e) => { e.target.src = ExampleProfile; }}
              src={item.photoUrl || ExampleProfile}
              className="thumbnail-expert"
              alt="thumbnail expert"
              width="100%"
            />
          ) }
        </div>
        {/* Expert Name */}
        <div className="row mx-0 px-3 mt-4">
          <h2 className="mx-auto">{item.name}</h2>
        </div>
        {/* Expert Title */}
        <div className="row mx-0 px-3 pb-1">
          <b className="mx-auto text-blue-kewirus">{_.get(item, 'mentorInfo.title')}</b>
        </div>
        {/* Expert Quotes */}
        <div className="text-grey-kewirus row mx-0 px-3 pt-1 pb-1">
          <p className="mx-auto text-center">
            {_.get(item, 'mentorInfo.description') !== ''
            && _.get(item, 'mentorInfo.description') !== null && (
              <>
                {'"'}
                {_.get(item, 'mentorInfo.description')}
                {'"'}
              </>
            )}
          </p>
        </div>
        {/* Expert Portofolio */}
        <div className="row mx-0 px-3 pb-1">
          <div className="social-media mx-auto">
            <ul>
              {_.get(item, 'mentorInfo.linkedin', null) !== null && (
                _.get(item, 'mentorInfo.linkedin', null) !== ''
              ) && (
                <li>
                  <a href={_.get(item, 'mentorInfo.linkedin', '#')}>
                    <img src={LinkedinIcon} alt="linkedin" />
                  </a>
                </li>
              ) }
              {_.get(item, 'mentorInfo.instagram', null) !== null
              && _.get(item, 'mentorInfo.instagram', null) !== '' && (
                <li>
                  <a href={_.get(item, 'mentorInfo.instagram', '#')}>
                    <img src={InstagramIcon} alt="ig" />
                  </a>
                </li>
              ) }
              {_.get(item, 'mentorInfo.portofolio', null) !== null
              && _.get(item, 'mentorInfo.portofolio', null) !== '' && (
                <li>
                  <a href={_.get(item, 'mentorInfo.portofolio', '#')}>
                    <img src={AppLogo} alt="ig" />
                  </a>
                </li>
              ) }
              { (_.get(item, 'mentorInfo.linkedin', null) === null
              && _.get(item, 'mentorInfo.instagram', null) === null
              && _.get(item, 'mentorInfo.portofolio', null) === null) && (
                <p>No profile info</p>
              ) }
            </ul>
          </div>
        </div>
        <hr />
        {/* Button Request Mentoring */}
        <div className="row mx-0 px-3 pb-3">
          <button
            onClick={(e) => {
              if (_.isObject(item) && !_.isNil(item)) {
                onItemClick(e, item);
              }
            }}
            type="button"
            className="w-100 mx-auto btn btn-kewirus"
          >
            Request Mentoring
          </button>
        </div>
      </Card>
    </div>
  ))
);

/** CardList component */
const CardList = (props) => {
  const {
    onItemClick, onNextPage, page, items, type,
    onPreviousPage, onPageNumberChanged, totalPage,
    limit, onViewMoreClick, isLoading, ...prps
  } = props;

  const cards = [];
  // - validate callback func & props that need to provide by parent component
  if (_.includes(['event', 'project', 'course'], type)) {
    if (_.isUndefined(onNextPage)
    || _.isUndefined(onPreviousPage)
    || _.isUndefined(onPageNumberChanged)) {
      return (
        <div>
          <ul>
            <li>onNextPage need to provide in props</li>
            <li>onPreviousPage need to provide in props</li>
            <li>onPageNumberChanged need to provide in props</li>
          </ul>
        </div>
      );
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (_.isUndefined(onViewMoreClick) && _.isEqual(type, 'course-category')) {
      return (<div>onViewMoreClick need to provide in props</div>);
    }
  }

  if (_.isEmpty(items)) {
    return (
      <div className="container-listprojects mt-5">
        <div className="row">
          <div className="col text-center">
            <p>Nothing here</p>
          </div>
        </div>
      </div>
    );
  }

  // - render cards from items
  switch (type) {
  case 'course-category':
    // render course-category card
    cards.push(..._renderCourseCategoryCards(items, onItemClick, onViewMoreClick, limit));
    break;
  case 'event':
    // render custom ui for event
    cards.push(..._renderEventCards(items, onItemClick, isLoading));
    break;
  case 'course':
    // render custom ui (course in category)
    cards.push(..._renderCourseCards(items, onItemClick, isLoading));
    break;
  case 'expert':
    cards.push(..._renderExpertCards(items, onItemClick, isLoading));
    break;
  case 'published-project':
    cards.push(..._renderPublishedProjectCards(items, onItemClick, isLoading));
    break;
  default:
    // render "projects" card
    cards.push(..._renderProjectCards(items, onItemClick, isLoading));
    break;
  }

  return (
    <div className="container-listprojects">
      <div className="row">
        { cards }
      </div>
      {
        (_.isEqual(type, 'event')
        || _.isEqual(type, 'course')
        ) && totalPage > 1
        && (
          <Pagination
            {...prps}
            className="mt-5"
            activePage={page}
            totalPage={totalPage}
            onNextClick={onNextPage}
            onPageNumberClick={onPageNumberChanged}
            onPreviousClick={onPreviousPage}
          />
        )
      }
    </div>
  );
};

CardList.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  onPageNumberChanged: PropTypes.func,
  onViewMoreClick: PropTypes.func,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  items: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['project', 'course', 'event', 'course-category', 'expert', 'published-project']),
  isLoading: PropTypes.bool.isRequired,

  // - case: courses with viewmore button
  limit: PropTypes.number,
};

CardList.defaultProps = {
  type: 'project',
  limit: 3,
  onNextPage: undefined,
  onPreviousPage: undefined,
  onPageNumberChanged: undefined,
  onViewMoreClick: undefined,
  page: 1,
  totalPage: 1
};

export default CardList;
