import Http from '../http';

const endpoint = 'api/file';

export default {
  uploadFile(file, type = 'image') {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}/host`,
        formData,
        null,
        null,
        {
          'Content-Type': 'multipart/form-data'
        }
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  }
};
