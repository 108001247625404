import React, { useEffect } from 'react';
import _ from 'lodash';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../../utils';

const PublicRoute = (props) => {
  // eslint-disable-next-line react/prop-types
  const { component: Component } = props;

  useEffect(() => {
    const usr = localStorage.getItem('kewirus_u');
    const jsonUsr = JSON.parse(usr);

    if (!_.get(jsonUsr, 'emailVerified')) {
      localStorage.removeItem('kewirus_t');
      localStorage.removeItem('kewirus_u');
    }
  }, []);

  if (isLogin()) {
    // redirect to class page
    return <Redirect to="/class" />;
  }
  return (
    <Route
      {...props}
      render={(_props) => (
        <Component {..._props} />
      )}
    />
  );
};

export default PublicRoute;
