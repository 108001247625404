import React from 'react';
import _ from 'lodash';
import parse from 'html-react-parser';

import ApiMaterial from '../../services/api/material';

import './styles.scss';

import { PageTitle } from '../../components';
import Stepper from '../../components/Stepper/collapsed';

import AppLogo from '../../assets/app-logo.png';

class CourseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      materialName: '',
      certificateUrl: '',
      totalLesson: 0,
      selectedLesson: {},

      idSelectedLesson: {
        stepID: 0,
        lessonID: 0,
      },
      isSubmitted: false,
      answers: {},
      correctAnswers: [],
      score: null,

      // alert state
      alertShow: false,
      alertMessage: '',
      alertType: 'error',
    };
  }

  componentDidMount() {
    const { slug } = _.get(this.props, 'match.params');
    const materialName = _.get(this.props, 'location.state.materialName');
    const certificateUrl = _.get(this.props, 'location.state.certificateUrl');
    const redirectPath = _.get(this.props, 'location.state.redirectPath');

    // get lessons from course
    ApiMaterial.getMaterialLessons(slug)
      .then((res) => {
        const data = _.get(res, 'data.data');
        const totalLesson = _.reduce(
          data,
          (prev, it) => prev + it.lessons.length,
          0
        );
        if (totalLesson >= 1) {
          // if lesson is available
          // get lesson detail
          const { code, type } = _.head(_.head(data).lessons);
          this.getLessonDetail(code, type);
        }

        this.setState({
          data,
          materialName,
          certificateUrl,
          totalLesson,
        });
      })
      .catch((e) => {
        const { response } = e;
        const { status } = response;
        if (status === 403) {
          // eslint-disable-next-line react/prop-types
          setTimeout(() => this.props.history.replace(redirectPath, {
            alertShow: true,
            alertMessage: (
              <div>
                Get free access to all the materials by simply
                subscribing package
                <br />
                <a href="/profile">Go to profile and please scroll down</a>
              </div>
            ),
            alertType: 'error'
          }), 10);
        }
      });
  }

  _handleInputTextChanged = (e, field) => {
    const { value } = e.target;
    const answerObj = 'answer.';
    const isAnswerObj = field.includes(answerObj);

    if (isAnswerObj) {
      const answers = _.get(this, 'state.answers');
      const code = field.substring(answerObj.length, field.length);
      answers[code] = value;

      this.setState({
        answers
      });
    }
  }

  _handleSaveAnswer = () => {
    const answersObj = _.get(this.state, 'answers');
    const lessonCode = _.get(this.state, 'selectedLesson.lessonCode');
    const isAnswered = !_.isEqual(Object.keys(answersObj).length, 0);
    const answers = [];

    Object.keys(answersObj).map((code) => {
      answers.push({
        questionCode: code,
        optionCode: answersObj[code]
      });
      return true;
    });

    if (isAnswered) {
      ApiMaterial.answerQuiz({
        lessonCode,
        answers
      })
        .then((res) => {
          const alertMessage = _.get(res, 'data.message[0].message');
          const data = _.get(res, 'data.data');
          const score = _.get(data, 'score');
          const correctAnswers = _.get(data, 'correctAnswers');
          this.setState({
            alertMessage,
            alertType: 'success',
            alertShow: true,
            score,
            correctAnswers,
            isSubmitted: true
          });
        })
        .catch((e) => {
          let alertMsg = _.get(e, 'response.data.message');
          alertMsg = alertMsg.map((msg) => `<li>${msg.message}</li>`);
          alertMsg = alertMsg.join('');

          this.setState({
            alertMessage: `Failed to answer: ${`${alertMsg}`}`,
            alertShow: true,
            alertType: 'error'
          });
        });
    }
  }

  _renderContent = () => {
    const type = _.get(this.state, 'selectedLesson.type');
    const checkRenderIfYoutubeLink = (text) => {
      const $ytPattern = /(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/;
      const isYoutube = $ytPattern.test(text);
      if (isYoutube) {
        const iFrame = (
          <iframe
            title="step-video"
            allowFullScreen
            frameBorder="0"
            src={
              _.replace(
                _.split(text, '&')[0],
                'watch?v=',
                'embed/'
              )
            }
          />
        );
        return iFrame;
      }
      return text;
    };
    const renderMainContent = (additionalView = null) => (
      <div className="col-12 p-0">
        { additionalView && !(_.isEqual(type, 'pdf') || _.isEqual(type, 'quiz')) }
        <h2>{_.get(this.state, 'selectedLesson.name')}</h2>
        <pre className="mt-4">
          {
            checkRenderIfYoutubeLink(_.get(this.state, 'selectedLesson.text'))
          }
        </pre>
        { !_.isEqual(type, 'text')
          ? (
            <p className="mt-mines">
              {_.get(this.state, 'selectedLesson.description')}
            </p>
          ) : null}
        { _.isEqual(type, 'pdf') || _.isEqual(type, 'quiz') ? additionalView : null }
      </div>
    );

    switch (type) {
    case 'text':
      return renderMainContent();
    case 'youtube':
      // eslint-disable-next-line no-case-declarations
      const iFrame = (
        <iframe
          title="step-video"
          allowFullScreen
          frameBorder="0"
          src={_.get(this.state, 'selectedLesson.url')}
        />
      );
      return renderMainContent(iFrame);
    case 'pdf':
      // eslint-disable-next-line no-case-declarations
      const anchorTag = (
        <a className="mt-4" href={_.get(this.state, 'selectedLesson.filePath')}>
          View pdf here
        </a>
      );
      return renderMainContent(anchorTag);
    case 'quiz':
      // eslint-disable-next-line no-case-declarations
      const questions = _.get(this.state, 'selectedLesson.questions');
      // eslint-disable-next-line no-case-declarations
      const questionsView = [];
      // eslint-disable-next-line no-case-declarations
      let isAnswered = false;
      // eslint-disable-next-line no-case-declarations
      _.each(questions, (question, idx) => {
        const { options } = question;
        const optionsView = [];
        _.each(options, (opt) => { isAnswered = isAnswered || opt.isAnswer; });
        _.each(options, (opt) => {
          optionsView.push(
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    type="radio"
                    onChange={(e) => {
                      this._handleInputTextChanged(e, `answer.${question.code}`);
                    }}
                    value={opt.code}
                    {...(this.state.isSubmitted ? { checked: opt.isAnswer } : null)}
                    {
                      ...(
                        _.isString(this.state.answers[question.code])
                          && { checked: this.state.answers[question.code] === opt.code }
                      )
                    }
                    disabled={this.state.isSubmitted}
                  />
                </div>
              </div>
              <p className="m-0 ml-3">{ opt.name }</p>
            </div>
          );
        });
        questionsView.push(
          <div className="row mt-5 p-0">
            <div className="col-md-12">
              <b>
                Question
                {' '}
                {idx + 1}
              </b>
              <p>{ question.name }</p>
            </div>
            <div className="col">
              { optionsView }
            </div>
          </div>
        );
      });
      questionsView.push(
        <>
          { !_.isNil(this.state.score) && (
            <div className="col-12 px-0 mt-5">
              <h1>
                Your Score:
              </h1>
              <h1 className="text-blue-kewirus" style={{ fontSize: '58px', }}>
                { ` ${this.state.score}` }
              </h1>
              <div className="mt-5">
                <h2> Correct Answers </h2>
                {_.map(this.state.correctAnswers, (item, index) => (
                  <div className={index > 0 ? 'pt-3' : 'pt-2'}>
                    <p>
                      {index + 1}
                      {'. '}
                      { item.name }
                    </p>
                    <h4 className="pb-4">{ item.correctAnswer }</h4>
                  </div>
                ))}
              </div>
            </div>
          ) }
        </>
      );
      // - add save btn
      questionsView.push(
        <div className="col-12 px-0 mt-5">
          <button
            type="button"
            className="btn btn-kewirus float-left"
            {...{ disabled: isAnswered }}
            disabled={
              Object.keys(this.state.answers).length < questions.length
            }
            style={{ display: _.isNull(this.state.score) ? '' : 'none' }}
            onClick={() => this._handleSaveAnswer()}
          >
            Save answer
          </button>
          <button
            type="button"
            className="btn btn-kewirus float-left"
            style={{ display: !_.isNull(this.state.score) ? '' : 'none' }}
            onClick={() => this.setState({ answers: {}, score: null, isSubmitted: false })}
          >
            Retake answer
          </button>
        </div>
      );
      return renderMainContent(questionsView);
    default:
      break;
    }
    return null;
  }

  _renderStepper = () => (
    <div>
      {
        this.state.data.map((step, idx) => (
          <Stepper
            // eslint-disable-next-line react/no-array-index-key
            key={`stepper-${idx}`}
            index={idx + 1}
            name={step.name}
            lessons={step.lessons}
            stepID={idx}
            selected={this.state.idSelectedLesson}
            onStepperClick={(stepID, lessonID, lessonCode, lessonType) => {
              this.setState({
                idSelectedLesson: {
                  stepID,
                  lessonID
                }
              }, () => this.getLessonDetail(lessonCode, lessonType));
            }}
          />
        ))
      }
    </div>
  );

  getLessonDetail = (code, type) => {
    if (type === 'quiz') {
      ApiMaterial.getQuizDetailByCode(code)
        .then((res) => {
          const data = _.get(res, 'data.data');
          _.set(data, 'type', 'quiz');
          _.set(data, 'lessonCode', code);
          this.setState({
            selectedLesson: data
          });
        });
    }

    ApiMaterial.getLessonDetail(code)
      .then((res) => {
        const data = _.get(res, 'data.data');

        if (!_.isEqual(data.type, 'quiz')) {
          this.setState({
            selectedLesson: data
          });
        }
      });
  }

  render() {
    return (
      <div className="container-courses-detail col container-page-kewirus">
        {/* Alert */}
        {
          this.state.alertShow && (
            <div
              className={`alert ${_.isEqual(this.state.alertType, 'error') ? 'alert-danger' : 'alert-success'} alert-dismissible fade show`}
              role="alert"
              style={{
                position: 'fixed',
                top: '10%',
                zIndex: '10'
              }}
            >
              <p>
                {parse(this.state.alertMessage)}
              </p>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ alertMessage: '', alertShow: false })}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )
        }
        {/* Search Bar and Logo */}
        <div className="row">
          {/* Logo */}
          <a href="/" className="kewirus-desktop-only col-2 ml-auto mb-5">
            <img src={AppLogo} className="app-logo" alt="Cabin Crew Class Logo" />
          </a>
        </div>
        {/* Title */}
        <div className="row">
          <div className="col-10">
            <PageTitle title={_.get(this.state, 'materialName')} />
          </div>
          {
            !_.isEmpty(this.state.certificateUrl) && (
              <div className="col-2">
                <button
                  type="button"
                  className="btn btn-kewirus float-right"
                  onClick={() => window.open(this.state.certificateUrl, '_blank')}
                >
                  <i className="fa fa-download" />
                </button>
              </div>
            )
          }
          <div className="col-md-8 mt-4 container-content-course">
            {this._renderContent()}
          </div>
          <div className="col-md-4 mt-3 mt-md-3 container-next-step-course">
            <PageTitle
              title=""
              subtitle={`${_.get(this.state, 'totalLesson')} Lessons`}
            />
            {this._renderStepper()}
          </div>
        </div>
      </div>
    );
  }
}

export default CourseDetail;
