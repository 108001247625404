import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Shimmer = (props) => (
  !props.inline
    ? (
      <div className={`container-shimmer ${props.className}`} style={{ ...props.style }}>
        {/* Content */}
        <div className="card-shimmer" style={{ ...props.style }} />
      </div>
    )
    : (
      <span className={`container-shimmer ${props.className}`} style={{ ...props.style }}>
        {/* Content */}
        <div className="card-shimmer" style={{ ...props.style }} />
      </span>
    )
);

Shimmer.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  inline: PropTypes.bool,
};

Shimmer.defaultProps = {
  style: {},
  className: '',
  inline: false
};

export default Shimmer;
