/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';

import ApiAuth from '../../services/api/auth';
import ApiMeeting from '../../services/api/meeting';
import ApiEvent from '../../services/api/event';

import './styles.scss';
import UserImage from '../../assets/user-no-image.png';

const SideBar = (props) => {
  const [active, setActive] = useState(0);
  const [collapsed] = useState(false);
  const [user, setUser] = useState({});
  const [badge, setNotifBadge] = useState(0);

  // render badge
  const renderBadge = (needRender) => {
    if (needRender) {
      return <span className="badge badge-pill">{badge}</span>;
    }
    return null;
  };

  // eslint-disable-next-line react/prop-types
  const { menus, location } = props;

  // eslint-disable-next-line react/prop-types
  const { pathname } = location;
  let rootPath = String(pathname).split('/')[1];
  rootPath = rootPath.substring(0, rootPath.length - 1);
  useEffect(() => {
    const initialActive = _.head(
      menus
        .map((menu, idx) => (menu.url.includes(rootPath) ? idx : -1))
        .filter((val) => val > -1)
    );
    setActive(initialActive);

    async function fetchUserInfo() {
      const { data } = await ApiAuth.getUser();
      const usr = data.data;

      await localStorage.setItem('kewirus_u', JSON.stringify(usr));
      setUser(usr);
    }

    async function fetchTotalSchedule() {
      const res = await ApiMeeting.getInvitations();
      const res2 = await ApiEvent.getMyEvents();
      let eventItems = _.get(res2, 'data.data.data');
      eventItems = _.chain(eventItems)
        .filter((it) => it.isCreatorEvent)
        .reduce((sum, curr) => sum + _.toLength(curr.eventAttendances), 0)
        .value();
      const items = _.get(res, 'data.data');

      const total = _.toLength(items) + eventItems;
      setNotifBadge(total);
    }

    fetchUserInfo();
    fetchTotalSchedule();
  }, [menus, rootPath]);

  const sideMenus = menus.map(
    (menu, idx) => idx < menus.length - 1
      && _.includes(menu.viewAccess, user.kewirusType) && (
      <a
        key={`sidemenu-${menu.name}`}
        className={`nav-link ${active === idx ? 'active' : ''}`}
        id={`v-pills-${menu.name}-tab`}
        data-toggle="pill"
        onClick={() => {
          setActive(idx);
          // eslint-disable-next-line react/prop-types
          props.history.push(`/${menu.url}`);
        }}
        href={menu.url}
        role="tab"
        aria-controls={`v-pills-${menu.name}`}
        // eslint-disable-next-line jsx-a11y/aria-proptypes
        aria-selected={`${active === idx ? 'true' : 'false'}`}
      >
        <div className="row mx-0 h-100">
          <div className="col-2 px-0 d-flex align-items-center">
            <span>
              <i className={menu.icon} aria-hidden="true" />
            </span>
          </div>
          <div className="col px-2 d-flex align-items-center">
            <b />
            <b />
            <span>{menu.name}</span>
            {renderBadge(menu.badge)}
          </div>
        </div>
      </a>
    )
  );

  //   const _handleToggle = () => setCollapse(!collapsed);
  const _handleLogout = async () => {
    await localStorage.removeItem('kewirus_t');
    await localStorage.removeItem('kewirus_u');

    window.location.reload();
  };

  const lastMenu = _.last(menus);
  const bottomMenu = (
    <div className="row mx-0 bottom-menu" onClick={_handleLogout}>
      <div className="col-2">
        <span>
          <i className={lastMenu.icon} aria-hidden="true" />
        </span>
      </div>
      {/* @TODO: please change to <a> tag */}
      <div className="col">
        <b>{lastMenu.name}</b>
      </div>
    </div>
  );

  return (
    <div
      className={
        collapsed ? 'container-sidebar-collapsed' : 'container-sidebar'
      }
    >
      {/* Toggle */}
      {/* <div className="row mx-0">
        <div className="hamburger p-3">
          <div />
        </div>
      </div> */}
      <Link to="/profile">
        {/* User Profile */}
        <div role="button" className="row mx-0 mb-3">
          <div className="container-user-image mx-auto">
            <img
              onError={(e) => {
                e.target.src = UserImage;
              }}
              className="user-image mx-auto"
              src={
                _.isEmpty(user)
                  ? UserImage
                  : _.isNil(user.institution)
                    ? user.photoUrl
                    : _.get(user, 'institution.logo')
              }
              alt="User Profile"
              style={{
                objectFit: 'cover',
              }}
            />
          </div>
        </div>
        {/* Username */}
        <div className="row pt-3">
          <div className="mx-auto">
            <h2 className="text-white">
              Hi,&ensp;
              {_.get(user, 'displayName', '-')}
            </h2>
          </div>
        </div>
      </Link>
      {/* Member Status */}
      <div className="row mb-4">
        <div className="mx-auto">
          <p className="text-white">
            {_.get(user, 'userPackage.name', null)}
            {` ${
              _.get(user, 'kewirusType') === 'institution'
                ? 'Institution'
                : 'Member'
            }`}
          </p>
        </div>
      </div>
      {/* Menu */}
      <div className="row mx-0 pl-3 menu-title">
        <p className="text-white">MENU</p>
      </div>

      {/* Menu Routing */}
      <div
        className="nav flex-column nav-pills"
        id="v-pills-tab"
        role="tablist"
        aria-orientation="vertical"
      >
        {/* Menus */}
        <b />
        <b />
        {sideMenus}
      </div>

      <div />
      {/* Bottom Menu */}
      {bottomMenu}
    </div>
  );
};

SideBar.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      viewAccess: PropTypes.array.isRequired,
    }).isRequired
  ).isRequired,
};

export default withRouter(SideBar);
