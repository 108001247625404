/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import _ from 'lodash';
// import parse from 'html-react-parser';
// import { Link } from 'react-router-dom';
import './styles.scss';

import {
  SearchBar, PageTitle, CardList, Modal
} from '../../components';

import ApiMaterial from '../../services/api/material';
import ApiTransaction from '../../services/api/transaction';

import AppLogo from '../../assets/app-logo.png';
import NoImageThumbnail from '../../assets/no-image.png';
import { formatCurrency } from '../../utils';

class Courses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: {
        page: 1,
        totalPage: 0,
        total: 0,
      },
      category: '',
      data: _.range(0, 3),
      selectedCourse: null,
      isLoading: true,
      isLoadingCheckout: false,

      alertType: '',
      alertShow: false,
      alertMessage: '',

      showModal: false,
    };
  }

  componentDidMount() {
    const category = _.get(this, 'props.match.params.categorySlug');

    this.setState({
      category,
      isLoading: true,
    });

    this.getMaterials('', category);
  }

  getMaterials = (query = '', tag = '') => {
    const { page } = this.state.courses;

    ApiMaterial.getMaterials(page, query, tag).then((res) => {
      const d = _.get(res, 'data.data');
      const { meta, data } = d;
      const courses = {
        page,
        totalPage: meta.last_page,
        total: meta.total,
      };
      this.setState({
        courses,
        query,
        data,
        isLoading: false,
      });
    });
  };

  _renderCoursesList = () => (
    <CardList
      {...this.props}
      items={this.state.data}
      type="course"
      isLoading={this.state.isLoading}
      page={this.state.courses.page}
      totalPage={this.state.courses.totalPage}
      onItemClick={(e, item, actionType) => {
        const allowed = _.get(item, 'allowed');
        e.stopPropagation();

        switch (actionType) {
        case 'buy':
          // - show success message
          this.setState({
            showModal: true,
            selectedCourse: item,
          });
          break;
        default:
          // view course
          if (allowed || _.isEqual(item.price, 0)) {
            // eslint-disable-next-line react/prop-types
            this.props.history.push(`/course/detail/${item.slug}`, {
              materialName: item.name,
              certificateUrl: item.certificateUrl
            });
          } else {
            this.setState({
              alertShow: true,
              alertMessage: (
                <div>
                  You have to purchase for this course
                  <br />
                  {/* <a href="/profile">Go to profile and please scroll down</a> */}
                </div>
              ),
              alertType: 'error',
            });
          }
          break;
        }
      }}
      onNextPage={() => {
        this.setState(
          (prevState) => ({
            courses: {
              ...prevState.courses,
              page: prevState.courses.page + 1,
            },
          }),
          () => this.getMaterials(this.state.query, this.state.category)
        );
      }}
      onPageNumberChanged={(_page) => {
        this.setState(
          (prevState) => ({
            courses: {
              ...prevState.courses,
              page: _page,
            },
          }),
          () => this.getMaterials(this.state.query, this.state.category)
        );
      }}
      onPreviousPage={() => {
        this.setState(
          (prevState) => ({
            courses: {
              ...prevState.courses,
              page: prevState.courses.page - 1,
            },
          }),
          () => this.getMaterials(this.state.query, this.state.category)
        );
      }}
    />
  );

  _renderCheckoutModal = () => (
    <Modal
      show={this.state.showModal}
      onClose={() => this.setState({ showModal: false })}
    >
      <img
        onError={(e) => {
          e.target.src = NoImageThumbnail;
        }}
        src={
          _.get(this.state, 'selectedCourse.thumbnail', null)
          || NoImageThumbnail
        }
        height="300"
        alt="course-thumbnail"
        style={{
          objectFit: 'cover',
        }}
      />
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-12">
            <h2>{_.get(this.state, 'selectedCourse.name')}</h2>
          </div>
        </div>
        <div className="row mt-3">
          <label className="col-auto">Total Amount:</label>
          <div className="col-auto">
            <h1 className="text-blue-kewirus">
              {formatCurrency(_.get(this.state, 'selectedCourse.price'))}
            </h1>
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="mt-2 py-3 btn btn-kewirus col-12"
        style={{
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        }}
        disabled={this.state.isLoadingCheckout}
        onClick={this._handleCheckout}
      >
        {this.state.isLoadingCheckout ? 'Checkout..' : 'Checkout'}
      </button>
    </Modal>
  );

  _handleCheckout = (e) => {
    e.stopPropagation();
    this.setState({
      isLoadingCheckout: true,
    });
    const { selectedCourse } = this.state;

    ApiTransaction.buyMaterial([selectedCourse.slug])
      .then((res) => {
        const token = _.get(res, 'data.data');
        const component = this;
        // - show midtrans popup client
        window.snap.pay(token, {
          onSuccess: (result) => {
            component.setState({
              alertShow: true,
              alertType: 'success',
              alertMessage: _.get(result, 'status_message'),
            });

            // - replace to courses
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          },
          onError(result) {
            component.setState({
              alertType: 'error',
              alertMessage: _.get(result, 'status_message'),
              alertShow: true,
            });
          },
          onClose() {
            const msg = '<p class="text-center">Checkout failed!</p>';
            component.setState({
              alertType: 'error',
              alertMessage: msg,
              alertShow: true,
            });
          },
        });

        this.setState({
          isLoadingCheckout: false,
          showModal: false,
        });
      })
      .catch((err) => {
        const { response } = err;
        const { message } = response.data;
        const msg = message
          .map((_msg) => `<li>${_msg.message} for field ${_msg.field}</li>`)
          .join('');

        this.setState({
          alertShow: true,
          alertMessage: `Failed to purchase course. because of: </br>${msg}`,
          alertType: 'error',
        });
      });
  };

  _handleSearch = ({ target }) => {
    const { value: query } = target;
    const { category } = this.state;
    this.getMaterials(query, category);
  };

  render() {
    return (
      <div className="container-courses col container-page-kewirus">
        {this.state.alertShow && (
          <div
            className={`alert ${
              _.isEqual(this.state.alertType, 'error')
                ? 'alert-danger'
                : 'alert-success'
            } alert-dismissible fade show`}
            role="alert"
            style={{
              position: 'fixed',
              top: '10%',
              zIndex: '10',
            }}
          >
            <p>{this.state.alertMessage}</p>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => this.setState({ alertMessage: '', alertShow: false })}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        {this._renderCheckoutModal()}
        {/* Search Bar and Logo */}
        <div className="row">
          {/* Search Bar */}
          <div className="kewirus-desktop-only col-6 mb-5">
            <SearchBar onSearch={this._handleSearch} />
          </div>
          {/* Logo */}
          <a href="/" className="kewirus-desktop-only col-auto mb-5 ml-auto">
            <img
              src={AppLogo}
              className="app-logo"
              alt="Cabin Crew Class Logo"
            />
          </a>
        </div>

        {/* Title */}
        <div className="row mx-0">
          <PageTitle
            title={`Category: ${this.state.category}`}
            subtitle={`${this.state.courses.total} total`}
          />
        </div>

        {/* Search Bar Mobile Only */}
        <div className="row kewirus-mobile-only">
          {/* Search Bar */}
          <div className="col-12 mt-3 mb-4">
            <SearchBar onSearch={this._handleSearch} />
          </div>
        </div>

        {/* Courses List */}
        <div>{this._renderCoursesList()}</div>
      </div>
    );
  }
}

export default Courses;
