import React from 'react';
import './styles.scss';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../../utils';

import Sidebar from '../Sidebar';
import NavBar from '../NavBar';
import nav from '../../_nav';

const PrivateRoute = (props) => {
  // eslint-disable-next-line react/prop-types
  const { component: Component } = props;

  if (isLogin()) {
    return (
      <>
        <div className="col-auto kewirus-desktop-only px-0">
          <Sidebar
            menus={nav}
            {...props}
          />
        </div>
        <div className="col-12 kewirus-mobile-only px-0 sticky-navbar">
          <NavBar title="Default" menus={nav} {...props} />
        </div>
        <Route
          {...props}
          render={(_props) => (
            <Component {..._props} />
          )}
        />
      </>
    );
  }

  return (
    <Redirect to="/login" />
  );
};

export default PrivateRoute;
