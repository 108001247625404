import Http from '../http';

const endpoint = 'api/user';

export default {
  getMaterials(page = 1, query = '', tag = '') {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/materials?page=${page}&query=${query}&tags=${tag}&limit=6`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getCategories() {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/material-tags/active`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getMaterialLessons(materialSlug) {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/material/lessons/${materialSlug}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getLessonDetail(code) {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/lesson/${code}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getQuizDetailByCode(code) {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/quiz/${code}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  answerQuiz({
    lessonCode,
    answers
  }) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}/quiz/answer`,
        {
          lessonCode,
          answers
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getMyMaterials(query = '') {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/materials/me?query=${query}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  }
};
