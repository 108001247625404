/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import parse from 'html-react-parser';
import DatePicker from 'react-datepicker';

import './styles.scss';
import AppLogo from '../../assets/app-logo.png';

import {
  PageTitle,
  CardList,
  Modal,
} from '../../components';

import ApiExpert from '../../services/api/expert';
import ApiProject from '../../services/api/project';
import { formatCurrency, validateStringLen } from '../../utils';

class Experts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: _.range(0, 4),
      isLoading: true,

      showMeetModal: false,

      // modal state
      isMeetingStartDateValidated: null,
      isMeetingEndDateValidated: null,
      isNotesValidated: null,
      isMentorSelected: null,
      requestMeet: {},
      minStartDate: moment().add(1, 'days').toDate(),

      alertMessage: '',
      alertShow: false,
      alertType: ''
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    // - hit api experts
    ApiExpert.getExperts()
      .then((res) => {
        const data = _.get(res, 'data.data');
        this.setState({
          data,
          isLoading: false
        });
      });
  }

  _handleCloseModal = () => {
    this.setState({
      showMeetModal: false,
    });
  }

  _handleInputTextChanged = (e, field) => {
    const { value } = e.target;

    if (_.isEqual(field, 'notes')) {
      const validate = validateStringLen(value, 1);
      this.setState({ isNotesValidated: validate });
    }

    this.setState({
      requestMeet: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.requestMeet,
        [field]: value
      }
    });
  }

  _handleDateChanged = (_e, date, type) => {
    const validation = {};
    if (_.isEqual(type, 'start_date')) {
      const endDate = _.get(this.state, 'requestMeet.end_date');
      if (!_.isUndefined(endDate)) {
        const endDateMoment = moment(endDate);
        if (moment(date).isSame(endDateMoment, 'day')) {
          _.set(validation, 'isMeetingStartDateValidated', true);
        } else {
          _.set(validation, 'isMeetingStartDateValidated', false);
        }
      } else _.set(validation, 'isMeetingStartDateValidated', true);
    } else {
      const startDate = _.get(this.state, 'requestMeet.start_date');
      if (!_.isUndefined(startDate)) {
        const startDateMoment = moment(startDate);
        if (moment(date).isAfter(startDateMoment)) {
          _.set(validation, 'isMeetingEndDateValidated', true);
        } else {
          _.set(validation, 'isMeetingEndDateValidated', false);
        }
      } else _.set(validation, 'isMeetingEndDateValidated', true);
    }
    this.setState({
      requestMeet: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.requestMeet,
        [type]: date
      },
      ...validation
    });
  }

  _renderexpertLists = () => (
    <CardList
      {...this.props}
      items={this.state.data}
      type="expert"
      isLoading={this.state.isLoading}
      onItemClick={(_e, item) => {
        // eslint-disable-next-line react/prop-types
        this.setState({
          showMeetModal: true,
          requestMeet: {
            mentor: item
          }
        });
      }}
    />
  );

  _handleInviteMentor = () => {
    const { requestMeet } = this.state;
    const startDate = _.get(requestMeet, 'start_date');
    const endDate = _.get(requestMeet, 'end_date');
    const { email } = _.get(requestMeet, 'mentor');
    const inv = _.omit(requestMeet, ['mentor']);
    _.set(inv, 'email', email);

    const filteredInv = {
      ...inv,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      startTime: moment(startDate).format('HH:mm'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      endTime: moment(endDate).format('HH:mm'),
    };

    ApiProject.inviteMeetMentor(filteredInv)
      .then((res) => {
        const { data } = res;
        const { message } = data;
        const msg = message.map((_msg) => `${_msg.message}`).join('');

        this.setState({
          alertShow: true,
          alertMessage: `${msg}`,
          alertType: 'success',
          showMeetModal: false
        });

        setTimeout(() => {
          // eslint-disable-next-line react/prop-types
          this.props.history.replace('/schedule');
        }, 2000);
      })
      .catch((e) => {
        const { response } = e;
        const { message } = response.data;
        const msg = message.map((_msg) => `<li>${_msg.message} for field ${_msg.field}</li>`).join('');

        this.setState({
          alertShow: true,
          alertMessage: `Failed to invite expert. because of: </br>${msg}`,
          alertType: 'error',
        });
      });
  }

  _renderMeetRequestModal = () => {
    const startDate = _.get(this.state, 'requestMeet.start_date');
    const endDate = _.get(this.state, 'requestMeet.end_date');
    let diff = Math.ceil(Math.abs(moment(startDate).diff(endDate, 'minutes')) / 60);
    if (_.isNil(startDate) || _.isNil(endDate)) diff = 0;
    const ratePrice = _.get(this.state, 'requestMeet.mentor.rate_price', 0);
    const total = diff * ratePrice;

    return (
      <Modal
        show={this.state.showMeetModal}
        // onClose={() => this.setState({ showMeetModal: false })}
        className="modal-meeting-request"
      >
        <div className="container mentor-modal mt-5 mb-4 mx-3">
          <div className="row">
            <h1 className="col mb-2">Mentoring</h1>
          </div>
          <div className="row mt-3">
            <label htmlFor="available_schedule" className="col-12">Jadwal Aktif</label>
            <p className="col-12">
              <b style={{ whiteSpace: 'pre-line' }}>
                {_.get(this.state.requestMeet, 'mentor.mentorInfo.available_schedule', '-') || '-'}
              </b>
            </p>
          </div>
          <div className="row">
            <div className="col-md-12 mt-3">
              <label htmlFor="schedule_date_picker">Pick Start Date</label>
              <DatePicker
                id="request_meeting_start_date"
                className={`form-control w-100 ${this.state.isMeetingStartDateValidated
                  ? 'is-valid'
                  : _.isNull(this.state.isMeetingStartDateValidated)
                    ? ''
                    : 'is-invalid'
                }`}
                autoComplete="off"
                minDate={this.state.minStartDate}
                showTimeSelect
                placeholderText="Click here!"
                dateFormat="dd/MM/yyyy HH:mm"
                selected={startDate}
                onChange={(date, e) => this._handleDateChanged(e, date, 'start_date')}
              />
            </div>
            <div className="col-md-12 mt-3">
              <label htmlFor="schedule_date_picker">Pick End Date</label>
              <DatePicker
                autoComplete="off"
                id="request_meeting_end_date"
                className={`form-control w-100 ${this.state.isMeetingEndDateValidated
                  ? 'is-valid'
                  : _.isNull(this.state.isMeetingEndDateValidated)
                    ? ''
                    : 'is-invalid'
                }`}
                minDate={
                  _.isNil(_.get(this.state, 'requestMeet.start_date'))
                    ? this.state.minStartDate
                    : moment(_.get(this.state, 'requestMeet.start_date')).toDate()
                }
                maxDate={
                  _.isNil(_.get(this.state, 'requestMeet.start_date'))
                    ? null
                    : moment(_.get(this.state, 'requestMeet.start_date')).toDate()
                }
                showTimeSelect
                placeholderText="Click here!"
                dateFormat="dd/MM/yyyy HH:mm"
                selected={endDate}
                onChange={(date, e) => this._handleDateChanged(e, date, 'end_date')}
              />
            </div>
          </div>
          <div className="row mt-3">
            <label htmlFor="schedule_notes" className="col-12">Notes</label>
            <div className="col-12">
              <textarea
                className={`form-control ${this.state.isNotesValidated
                  ? 'is-valid'
                  : _.isNull(this.state.isNotesValidated)
                    ? ''
                    : 'is-invalid'
                }`}
                rows="5"
                onChange={(e) => this._handleInputTextChanged(e, 'notes')}
              />
              {
                !this.state.isNotesValidated && (
                  <div className="invalid-feedback">
                    Please input notes field
                  </div>
                )
              }
            </div>
          </div>
          <div className="row mt-3">
            <label htmlFor="schedule_price" className="col-12">Base Price (per hour)</label>
            <div className="col-12">
              <h1>{ formatCurrency(ratePrice) }</h1>
            </div>
          </div>
          <div className="row mt-3">
            <label htmlFor="schedule_price" className="col-12">Total Price</label>
            <div className="col-12">
              <h1>{ `${formatCurrency(total)} (${diff} hours)` }</h1>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-6">
              <button
                type="button"
                onClick={this._handleCloseModal}
                className="btn btn-secondary-kewirus col-12"
              >
                Close
              </button>
            </div>
            <div className="col-6 mb-4">
              <button
                type="submit"
                disabled={
                  !(
                    this.state.isMeetingEndDateValidated
                        && this.state.isMeetingStartDateValidated
                        && this.state.isNotesValidated
                  )
                }
                onClick={this._handleInviteMentor}
                className="btn btn-kewirus col-12"
              >
                Invite
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <div className="col container-page-kewirus">
        {/* Alert */}
        {
          this.state.alertShow && (
            <div
              className={`alert ${_.isEqual(this.state.alertType, 'error') ? 'alert-danger' : 'alert-success'} alert-dismissible fade show`}
              role="alert"
              style={{
                position: 'fixed',
                top: '10%',
                zIndex: '10'
              }}
            >
              <p>
                {parse(this.state.alertMessage)}
              </p>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ alertMessage: '', alertShow: false })}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )
        }
        {/* Modal: meeting request modal */}
        { this._renderMeetRequestModal() }
        {/* Search Bar and Logo */}
        <div className="row">
          {/* Search Bar */}
          {/* <div className="kewirus-desktop-only col-6 mb-5">
            <SearchBar />
          </div> */}
          {/* Logo */}
          <a href="/" className="kewirus-desktop-only col-auto mb-5 ml-auto">
            <img src={AppLogo} className="app-logo" alt="Cabin Crew Class Logo" />
          </a>
        </div>
        {/* Title */}
        <div className="row mx-0">
          <PageTitle
            title="All Experts"
            subtitle={`${this.state.data.length} experts`}
          />
        </div>
        {/* Search Bar Mobile Only */}
        {/* <div className="row kewirus-mobile-only"> */}
        {/* Search Bar */}
        {/* <div className="col-12 mt-3 mb-4">
            <SearchBar onSearch={this._handleSearch} />
          </div>
        </div> */}
        {/* Experts List */}
        <div>
          {this._renderexpertLists()}
        </div>
      </div>
    );
  }
}

export default Experts;
