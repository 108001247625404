import React from 'react';
import _ from 'lodash';
import { isLogin } from '../../utils/index';

const NotFound = (props) => {
  const path = _.get(props, 'location.pathname');
  const isNotFound = _.isEqual(path, '/404-not-found');

  if (isNotFound) {
    // eslint-disable-next-line no-unused-vars
    import('./styles.scss').then((_condition) => {});
  }
  return (
    <>
      <section className="error-container">
        <span className="four"><span className="screen-reader-text">4</span></span>
        <span className="zero"><span className="screen-reader-text">0</span></span>
        <span className="four"><span className="screen-reader-text">4</span></span>
      </section>
      <div className="link-container">
        <a
          href={isLogin() ? '/class' : '/'}
          className="more-link"
        >
          Visit Home
        </a>
      </div>
    </>
  );
};

export default NotFound;
