import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Modal = ({
  children, show, onClose, className
}) => {
  const [isshow, showModal] = useState(false);
  const [display, setDisplay] = useState('none');
  const timeout = 500;

  const openModal = () => {
    showModal(true);
    setDisplay('block');
  };

  const closeModal = () => {
    showModal(false);
    setTimeout(() => setDisplay('none'), timeout);
  };

  useEffect(() => {
    if (show) openModal();
    else closeModal();
  });

  return (
    <div
      className={`modal fade ${isshow ? 'show' : 'hide'}`}
      id="modal-schedule"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display }}
      onClick={onClose}
    >
      <div className={`${className} modal-dialog modal-dialog-centered`} role="document">
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

Modal.defaultProps = {
  onClose: () => {},
  className: ''
};

export default Modal;
