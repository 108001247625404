import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './styles.scss';

const Pagination = (props) => {
  const {
    activePage, totalPage, onPageNumberClick, onNextClick, onPreviousClick
  } = props;
  const location = _.get(props, 'location');

  const _generatePageNumbers = () => {
    const pageNumbers = [];

    if (totalPage >= 10) {
      let next = activePage + 2;
      const isNearLast = next > totalPage;
      next = isNearLast ? totalPage : next;

      for (let idx = isNearLast ? activePage - 2 : activePage - 1; idx < next; idx += 1) {
        pageNumbers.push(
          <li key={idx} className={activePage === idx + 1 ? 'active page-item' : 'page-item'}>
            <a
              href={`${_.get(location, 'pathname')}#pagination`}
              className="page-link"
              onClick={() => onPageNumberClick(idx + 1)}
            >
              {idx + 1}
            </a>
          </li>
        );
      }

      if (next < totalPage) {
        pageNumbers.push(
          <>
            <li className="disabled page-item">
              <a href={`${_.get(location, 'pathname')}#pagination`} className="page-link">
                ...
              </a>
            </li>
            <li className={activePage === totalPage ? 'active page-item' : 'page-item'}>
              <a
                href={`${_.get(location, 'pathname')}#pagination`}
                className="page-link"
                onClick={() => onPageNumberClick(totalPage)}
              >
                {totalPage}
              </a>
            </li>
          </>
        );
      }
    } else { // if totalpage < 10
      for (let idx = 0; idx < totalPage; idx += 1) {
        pageNumbers.push(
          <li key={idx} className={activePage === idx + 1 ? 'active page-item' : 'page-item'}>
            <a
              href={`${_.get(location, 'pathname')}#pagination`}
              className="page-link"
              onClick={() => onPageNumberClick(idx + 1)}
            >
              {idx + 1}
            </a>
          </li>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <nav aria-label="Page navigation example" style={{ display: totalPage === 0 ? 'none' : '' }} {...props}>
      <ul className="pagination justify-content-center">
        {
          <li className={activePage - 5 < 1 ? 'disabled page-item' : 'page-item'} style={activePage === 1 ? { display: 'none' } : { display: 'block' }}>
            <a href={`${_.get(location, 'pathname')}#pagination`} className="page-link" onClick={() => onPageNumberClick(activePage - 5)}>
              &laquo;
            </a>
          </li>
        }
        {
          <li className={activePage === 1 ? 'disabled page-item' : 'page-item'} style={activePage === 1 ? { display: 'none' } : { display: 'block' }}>
            <a href={`${_.get(location, 'pathname')}#pagination`} className="page-link" onClick={onPreviousClick} tabIndex="-1">Prev</a>
          </li>
        }
        {_generatePageNumbers()}
        {
          <li className={activePage === totalPage ? 'disabled page-item' : 'page-item'} style={activePage === totalPage ? { display: 'none' } : { display: 'block' }}>
            <a href={`${_.get(location, 'pathname')}#pagination`} className="page-link" onClick={onNextClick}>Next</a>
          </li>
        }
        {
          <li className={activePage + 5 > totalPage ? 'disabled page-item' : 'page-item'} style={activePage === totalPage ? { display: 'none' } : { display: 'block' }}>
            <a href={`${_.get(location, 'pathname')}#pagination`} className="page-link" onClick={() => onPageNumberClick(activePage + 5)}>
              &raquo;
            </a>
          </li>
        }
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number,
  onPageNumberClick: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired
};

Pagination.defaultProps = {
  totalPage: 1
};

export default Pagination;
