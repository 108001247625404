/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';

import './styles.scss';

import ApiAuth from '../../services/api/auth';
import ApiMisc from '../../services/api/misc';

import {
  validateEmail,
  validatePhoneNumber,
  validateStringLen,
} from '../../utils';

class MentorRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      jobTitle: '',
      address: '',
      shortDescription: '',
      phoneNumber: '',
      email: '',
      code: '',
      password: '',
      confirmPass: '',
      instagram: '',
      linkedin: '',
      portofolio: '',
      gender: '',
      schoolName: '',
      lastEducation: '',
      provinceId: 0,
      cityId: null,
      provinces: [],

      isEmailValidated: null,
      isPassValidated: null,
      isFullNameValidated: null,
      isJobValidated: null,
      isDescValidated: null,
      isPhoneValidated: null,
      isAddressValidated: null,
      isConfirmPassValidated: null,
      isCityValidated: null,
      isProvinceValidated: null,
      isGenderValidated: null,
      isLastEduValidated: null,
      isInstitutionValidated: null,

      isWaitingEmailConfirm: false,
      loginResendCode: false,

      alertShow: false,
      alertMessage: '',
      alertType: 'error',
    };
  }

  componentDidMount() {
    const qp = new URLSearchParams(_.get(this.props, 'location.search'));
    const code = qp.get('code');
    const email = qp.get('email');

    this.setState({
      code,
      email
    });

    this._getProvinces();
  }

  _getProvinces = () => {
    ApiMisc.getProvinces()
      .then((res) => {
        const provinces = _.get(res, 'data.data');
        this.setState({
          provinces
        });
      });
  }

  _getCitiesById = (id) => {
    ApiMisc.getCitiesByProvinceId(id)
      .then((res) => {
        const cities = _.get(res, 'data.data');
        this.setState({
          cities,
        });
      });
  }

  _handleRegister = () => {
    const {
      fullName,
      jobTitle,
      address,
      shortDescription: description,
      phoneNumber,
      email,
      code,
      password,
      instagram,
      linkedin,
      portofolio,
      schoolName,
      gender,
      lastEducation,
      cityId
    } = this.state;

    ApiAuth.mentorRegister({
      email,
      code,
      name: fullName,
      password,
      phoneNumber,
      address,
      instagram,
      linkedin,
      portofolio,
      description,
      schoolName,
      gender,
      lastEducation,
      cityId,
      title: jobTitle
    })
      .then((res) => {
        const { data } = res;
        const { message } = data;

        const msg = message.map((_msg) => `${_msg.message}`).join('');

        this.setState({
          alertMessage: `${msg}`,
          alertType: 'success',
          alertShow: true,

          fullName: '',
          emailConfirm: _.get(this, 'state.email'),
          password: '',
          confirmPass: '',
          phoneNumber: '',
          address: '',
          jobTitle: '',
          shortDescription: '',
          instagram: '',
          linkedin: '',
          portofolio: '',
          gender: '',
          schoolName: '',
          lastEducation: '',

          isEmailValidated: null,
          isPassValidated: null,
          isFullNameValidated: null,
          isJobValidated: null,
          isDescValidated: null,
          isPhoneValidated: null,
          isAddressValidated: null,
          isCityValidated: null,
          isProvinceValidated: null,
          isGenderValidated: null,
          isLastEduValidated: null,
          isConfirmPassValidated: null,
          isInstitutionValidated: null,

          provinceId: 0,

          isWaitingEmailConfirm: true,
        });
      })
      .catch((e) => {
        const { response } = e;
        const { message } = response.data;

        const msg = message.map((_msg) => `<li>${_msg.message}</li>`).join('');

        this.setState({
          alertShow: true,
          alertType: 'error',
          alertMessage: `Register failed, because of: <br/>${msg}`,
          confirmPass: '',
          isConfirmPassValidated: false,
        });
      });
  };

  _handleResendVerifyEmail = () => {
    const email = _.get(this, 'state.emailConfirm');
    ApiAuth.resendCode(email)
      .then(() => {
        const succMsg = `We've resent you confirmation link to your email:
        <b>${this.state.emailConfirm}</b>.
        <br/>Please check again your email in primary inbox or spam folder`;

        this.setState({
          alertMessage: `${succMsg}`,
          alertType: 'success',
          alertShow: true,
          loginResendCode: false,
        });
      })
      .catch((e) => {
        const { response } = e;
        const { message } = response.data;

        const msg = message.map((_msg) => `<li>${_msg.message}</li>`).join('');
        this.setState({
          alertMessage: msg,
          alertType: 'error',
          alertShow: true,
        });
      });
  };

  _handleInputTextChanged = (e, field) => {
    const { value } = e.target;

    if (_.isEqual(field, 'email') || _.isEqual(field, 'forgotEmail')) {
      const validate = validateEmail(value);
      const prop = field === 'email' ? 'isEmailValidated' : 'isForgotEmailValidated';
      this.setState({
        [prop]: validate,
      });
    } else if (_.isEqual(field, 'password')) {
      const validate = validateStringLen(value, 8);
      const validate2 = _.isEqual(_.get(this, 'state.confirmPass'), value);

      this.setState({
        isPassValidated: validate,
        isConfirmPassValidated: validate2,
      });
    } else if (_.isEqual(field, 'fullName')) {
      const validate = validateStringLen(value, 3);
      this.setState({
        isFullNameValidated: validate,
      });
    } else if (_.isEqual(field, 'confirmPass')) {
      const { password } = this.state;
      const validate = _.isEqual(password, value);

      this.setState({
        isConfirmPassValidated: validate,
      });
    } else if (_.isEqual(field, 'address')) {
      const validate = !_.isEmpty(value);

      this.setState({
        isAddressValidated: validate
      });
    } else if (_.isEqual(field, 'phoneNumber')) {
      const validate = validatePhoneNumber(value);
      this.setState({
        isPhoneValidated: validate
      });
    } else if (_.isEqual(field, 'jobTitle')) {
      const validate = !_.isEmpty(value);
      this.setState({
        isJobValidated: validate
      });
    } else if (_.isEqual(field, 'shortDescription')) {
      const validate = !_.isEmpty(value);
      this.setState({
        isDescValidated: validate
      });
    } else if (_.isEqual(field, 'gender')) {
      const validate = !_.isEmpty(value);

      this.setState({
        isGenderValidated: validate
      });
    } else if (_.isEqual(field, 'schoolName')) {
      const validate = !_.isEmpty(value);

      this.setState({
        isInstitutionValidated: validate
      });
    } else if (_.isEqual(field, 'lastEducation')) {
      const validate = !_.isEmpty(value);
      this.setState({
        isLastEduValidated: validate
      });
    }

    this.setState({
      [field]: value,
    });
  };

  render() {
    return (
      <div className="container-login">
        {this.state.alertShow && (
          <div
            className={`alert ${
              _.isEqual(this.state.alertType, 'error')
                ? 'alert-danger'
                : 'alert-success'
            } alert-dismissible fade show`}
            role="alert"
            style={{
              position: 'fixed',
              top: '10%',
              zIndex: '10'
            }}
          >
            <p>
              {parse(this.state.alertMessage)}
              {_.isEqual(this.state.loginResendCode, true) && (
                <a href="#" onClick={this._handleResendVerifyEmail}>
                  resend verify email
                </a>
              )}
            </p>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => this.setState({ alertMessage: '', alertShow: false })}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        {/* Sign Up Only */}
        <div className="cont s-signup">
          <div className="sub-cont">
            {/* Image */}
            <div className="img">
              <div className="img-text m-in">
                <h2>New here?</h2>
                <p>Sign up and discover great amount of new opportunities!</p>
              </div>
            </div>
            {!this.state.isWaitingEmailConfirm && (
              <div className="form sign-up">
                {/* Header */}
                <div className="text-center">
                  <h1 className="text-center">Create an Account</h1>
                </div>
                <form className="container-form-signup mt-5">
                  <div className="row">
                    {/* Full Name */}
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="inputFullNameSignUp">Full Name</label>
                        <input
                          type="fullName"
                          // eslint-disable-next-line no-nested-ternary
                          className={`form-control ${this.state.isFullNameValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isFullNameValidated)
                              ? ''
                              : 'is-invalid'
                          }`}
                          id="inputFullNameSignUp"
                          placeholder="Enter your name"
                          value={this.state.fullName}
                          onChange={(e) => this._handleInputTextChanged(e, 'fullName')}
                        />
                        {!this.state.isFullNameValidated && (
                          <div className="invalid-feedback">
                            Please input fullname field
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Title */}
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="inputTitleSignUp">Job Title</label>
                        <input
                          type="email"
                          // eslint-disable-next-line no-nested-ternary
                          className={`form-control ${this.state.isJobValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isJobValidated)
                              ? ''
                              : 'is-invalid'
                          }`}
                          id="inputEmailSignUp"
                          aria-describedby="emailHelp"
                          placeholder="Enter job title"
                          value={this.state.jobTitle}
                          onChange={(e) => this._handleInputTextChanged(e, 'jobTitle')}
                        />
                        {!this.state.isEmailValidated && (
                          <div className="invalid-feedback">
                            Please input title field
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Desc */}
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="inputDesc">
                          Short Description
                        </label>
                        <input
                          type="email"
                          // eslint-disable-next-line no-nested-ternary
                          className={`form-control ${this.state.isDescValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isDescValidated)
                              ? ''
                              : 'is-invalid'
                          }`}
                          id="inputDesc"
                          aria-describedby="emailHelp"
                          placeholder="Enter short description"
                          value={this.state.shortDescription}
                          onChange={(e) => this._handleInputTextChanged(e, 'shortDescription')}
                        />
                        {!this.state.isDescValidated && (
                          <div className="invalid-feedback">
                            Please input quotes field
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Gender */}
                  <div className="row">
                    <div className="col-md">
                      <div className="form-group">
                        <label htmlFor="inputGenderSignUp">Gender</label>
                        <select
                          // eslint-disable-next-line no-nested-ternary
                          className={`form-control ${this.state.isGenderValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isGenderValidated)
                              ? ''
                              : 'is-invalid'
                          }`}
                          id="inputGenderSignUp"
                          value={this.state.gender}
                          onChange={(e) => this._handleInputTextChanged(e, 'gender')}
                        >
                          <option disabled value="">Choose...</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                        {
                          !this.state.isGenderValidated && (
                            <div className="invalid-feedback">
                              Please choose your gender
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  {/* Last edu */}
                  <div className="row">
                    <div className="col-md">
                      <div className="form-group">
                        <label htmlFor="inputLocationSignUp">Last Education</label>
                        <select
                          // eslint-disable-next-line no-nested-ternary
                          className={`form-control ${this.state.isLastEduValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isLastEduValidated)
                              ? ''
                              : 'is-invalid'
                          }`}
                          id="inlineFormCustomSelect"
                          value={this.state.lastEducation}
                          onChange={(e) => this._handleInputTextChanged(e, 'lastEducation')}
                        >
                          <option disabled value="">Choose...</option>
                          <option value="SD">SD/Sederajat</option>
                          <option value="SMP">SMP/Sederajat</option>
                          <option value="SMA">SMA/SMK/MA/Sederajat</option>
                          <option value="D3">D3 - Diploma</option>
                          <option value="S1">S1 - Sarjana</option>
                          <option value="S2">S2 - Magister</option>
                          <option value="S3">S3 - Doctor</option>
                        </select>
                        {
                          !this.state.isLastEduValidated && (
                            <div className="invalid-feedback">
                              Please choose your last education
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  {/* last edu specific */}
                  <div className="row">
                    <div className="col-md">
                      {/* last edu specific */}
                      <div className="form-group">
                        <label htmlFor="inputInstitutionSignUp">Last Education Institution</label>
                        <input
                          type="text"
                          // eslint-disable-next-line no-nested-ternary
                          className={`form-control ${this.state.isInstitutionValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isInstitutionValidated)
                              ? ''
                              : 'is-invalid'
                          }`}
                          id="inputInstitutionValidated"
                          placeholder="Enter you last education institution"
                          value={this.state.schoolName}
                          onChange={(e) => this._handleInputTextChanged(e, 'schoolName')}
                        />
                        {
                          !this.state.isInstitutionValidated && (
                            <div className="invalid-feedback">
                              Please enter this field
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  {/* Select Province */}
                  <div className="form-group row">
                    <div className="col-12">
                      <label htmlFor="dropdownMenuProvinces">Select Province</label>
                      <select
                        // eslint-disable-next-line no-nested-ternary
                        className={`form-control ${this.state.isProvinceValidated
                          ? 'is-valid'
                          : _.isNull(this.state.isProvinceValidated)
                            ? ''
                            : 'is-invalid'
                        }`}
                        value={this.state.provinceId}
                        onChange={(e) => {
                          const { value } = e.target;
                          const provinceId = value;
                          this.setState({
                            isProvinceValidated: true,
                            isCityValidated: false,
                            cityId: 0,
                            provinceId
                          });
                          this._getCitiesById(provinceId);
                        }}
                        aria-labelledby="dropdownMenuProvinces"
                      >
                        <option value={0} disabled>Choose province</option>
                        {_.map(this.state.provinces, (province) => (
                          <option value={_.get(province, 'province_id')}>{province.name}</option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        Please select province
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <label htmlFor="dropdownMenuCities">Select City</label>
                      <select
                        // eslint-disable-next-line no-nested-ternary
                        className={`form-control ${this.state.isCityValidated
                          ? 'is-valid'
                          : _.isNull(this.state.isCityValidated)
                            ? ''
                            : 'is-invalid'
                        }`}
                        value={this.state.cityId}
                        aria-labelledby="dropdownMenuCities"
                        onChange={(e) => {
                          const { value } = e.target;
                          const cityId = value;
                          this.setState({
                            isCityValidated: true,
                            cityId
                          });
                        }}
                      >
                        <option value={0} disabled>Please select province first</option>
                        {_.map(this.state.cities, (city) => (
                          <option value={_.get(city, 'id')}>{city.name}</option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        Please select city
                      </div>
                    </div>
                  </div>
                  {/* Phone Number */}
                  <div className="row">
                    <div className="col-12 form-group">
                      <label htmlFor="inputPhoneNumberSignUp">Phone Number</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">+62</div>
                        </div>
                        <input
                          type="tel"
                          // eslint-disable-next-line no-nested-ternary
                          className={`form-control ${this.state.isPhoneValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isPhoneValidated)
                              ? ''
                              : 'is-invalid'
                          }`}
                          id="inputPhoneNumberSignUp"
                          placeholder="Enter phone number"
                          value={this.state.phoneNumber}
                          onChange={(e) => this._handleInputTextChanged(e, 'phoneNumber')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 form-group">
                      <label htmlFor="inputPasswordSignUp">Address</label>
                      <textarea
                        // eslint-disable-next-line no-nested-ternary
                        className={`form-control ${this.state.isAddressValidated
                          ? 'is-valid'
                          : _.isNull(this.state.isAddressValidated)
                            ? ''
                            : 'is-invalid'
                        }`}
                        id="inputAddress"
                        placeholder="Enter your address"
                        value={this.state.address}
                        rows="5"
                        required
                        onChange={(e) => this._handleInputTextChanged(e, 'address')}
                      />
                      {
                        !this.state.isAddressValidated && (
                          <div className="invalid-feedback">
                            Please enter your address
                          </div>
                        )
                      }
                    </div>
                  </div>
                  {/* Link */}
                  <div className="row">
                    <div className="col-12 form-group">
                      <label htmlFor="mentor_link">
                        <b>Portofolio</b>
                        <p className="text-grey-kewirus d-inline ml-2">
                          (Optional)
                        </p>
                      </label>
                      {/* Instagram */}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <span>
                              <i
                                className="fa fa-instagram"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="social_media_ig"
                          placeholder="E.g. https://instagram.com/kewirus"
                          value={this.state.instagram}
                          onChange={(e) => this._handleInputTextChanged(e, 'instagram')}
                        />
                      </div>
                      {/* Linkedin */}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <span>
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="social_media_linked_in"
                          placeholder="E.g. https://linkedin.com/kewirus"
                          value={this.state.linkedin}
                          onChange={(e) => this._handleInputTextChanged(e, 'linkedin')}
                        />
                      </div>
                      {/* Portofolio */}
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <span>
                              <i className="fa fa-link" aria-hidden="true" />
                            </span>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="social_media_linked_in"
                          placeholder="E.g. https://kewirus.com"
                          value={this.state.portofolio}
                          onChange={(e) => this._handleInputTextChanged(e, 'portofolio')}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Password */}
                  <div className="form-group">
                    <label htmlFor="inputPasswordSignUp">Password</label>
                    <input
                      type="password"
                      // eslint-disable-next-line no-nested-ternary
                      className={`form-control ${this.state.isPassValidated
                        ? 'is-valid'
                        : _.isNull(this.state.isPassValidated)
                          ? ''
                          : 'is-invalid'
                      }`}
                      id="inputPasswordSignUp"
                      placeholder="Enter your password"
                      value={this.state.password}
                      onChange={(e) => this._handleInputTextChanged(e, 'password')}
                    />
                    {!this.state.isPassValidated && (
                      <div className="invalid-feedback">
                        Please enter password with minimal 8 characters
                      </div>
                    )}
                  </div>
                  {/* Confirm Password */}
                  <div className="form-group">
                    <label htmlFor="inputConfirmPasswordSignUp">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      // eslint-disable-next-line no-nested-ternary
                      className={`form-control ${this.state.isConfirmPassValidated
                        ? 'is-valid'
                        : _.isNull(this.state.isConfirmPassValidated)
                          ? ''
                          : 'is-invalid'
                      }`}
                      id="inputConfirmPasswordSignUp"
                      placeholder="Re-enter your password"
                      value={this.state.confirmPass}
                      onChange={(e) => this._handleInputTextChanged(e, 'confirmPass')}
                    />
                  </div>
                  {/* Sign Up Button */}
                  {
                    this.state.isConfirmPassValidated
                    && this.state.isAddressValidated
                    && this.state.isDescValidated
                    && this.state.isPhoneValidated
                    && this.state.isFullNameValidated
                    && this.state.isJobValidated
                    && this.state.isCityValidated
                    && this.state.isGenderValidated
                    && this.state.isInstitutionValidated
                    && this.state.isLastEduValidated
                      ? (
                        <button
                          type="button"
                          className="submit mt-5"
                          onClick={this._handleRegister}
                        >
                          Register Now
                        </button>
                      ) : (
                        <button type="button" className="submit mt-5" disabled>
                          Register Now
                        </button>
                      )
                  }
                </form>
              </div>
            )}
            {this.state.isWaitingEmailConfirm && (
              <div className="form sign-up">
                <h1 className="mb-5 text-center">Register succeed!</h1>
                <p>
                  You can now sign in with your new account. Go to
                  {' '}
                  <a href="/login" onClick={this._handleResendVerifyEmail}>
                    login page
                  </a>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MentorRegister);
