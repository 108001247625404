import _ from 'lodash';
import Http from '../http';

const endpoint = 'api/user';

export default {
  getProjects(query = '') {
    return new Promise((resolve, reject) => {
      Http.get(
        `${endpoint}/projects/me?query=${query}`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getProjectMentors(slug = undefined) {
    return new Promise((resolve, reject) => {
      Http.get(
        slug !== undefined
          ? `${endpoint}/projects/me/mentors?slug=${slug}`
          : `${endpoint}/projects/me/mentors`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getInstitutionList() {
    return new Promise((resolve, reject) => {
      Http.get('api/admin/institution').then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getProjectBySlug(slug) {
    return new Promise((resolve, reject) => {
      Http.get(
        `${endpoint}/project/${slug}`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  updateProject({
    name, description, slug, thumbnail, institutionCode
  }) {
    const form = new FormData();
    form.append('name', name);
    form.append('description', description);
    if (thumbnail) {
      form.append('thumbnail', thumbnail);
    }
    if (!_.isEmpty(institutionCode)) {
      form.append('institutionCode', institutionCode);
    }

    return new Promise((resolve, reject) => {
      Http.put(
        `${endpoint}/project/${slug}`,
        form,
        null,
        null,
        {
          'Content-Type': 'multipart/form-data'
        }
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getSharedProjects(query = '') {
    return new Promise((resolve, reject) => {
      Http.get(
        `${endpoint}/projects/shared?query=${query}`
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getPublishedProjects(page = 1, query = '') {
    return new Promise((resolve, reject) => {
      Http.get(
        `api/public/projects/published?page=${page}&query=${query}`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getMyPublishedProjects(page = 1, query = '') {
    return new Promise((resolve, reject) => {
      Http.get(
        `api/user/projects/me/published?page=${page}&query=${query}`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getPublishedProject(slug) {
    return new Promise((resolve, reject) => {
      Http.get(`api/public/project/${slug}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  changePublishProjectStatusToDraft(slug) {
    return new Promise((resolve, reject) => {
      Http.patch(`api/user/projects/me/published/${slug}/change-status`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  addProject({
    name, projectTypeSlug, description, thumbnail, institutionCode
  }) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('projectTypeSlug', projectTypeSlug);
    formData.append('description', description);
    if (!_.isEmpty(institutionCode)) {
      formData.append('institutionCode', institutionCode);
    }
    if (thumbnail instanceof Blob) {
      formData.append('thumbnail', thumbnail);
    }

    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}/project`,
        formData,
        null,
        null,
        {
          'Content-Type': 'multipart/form-data'
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  publishProject(_project) {
    const project = _.omit(_project, 'thumbnail');
    return new Promise((resolve, reject) => {
      Http.post(`${endpoint}/project/publish`, project)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getProjectTypes() {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/project-types`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getProjectStep(slug) {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/project-steps/${slug}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getProjectSection(stepSlug) {
    return new Promise((resolve, reject) => {
      Http.get(`${endpoint}/project-step/sections/${stepSlug}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  answerSection({ answer, stepSlug }) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}/project-step/section/answer`,
        {
          projectStepSlug: stepSlug,
          answers: [answer]
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  inviteMember({
    email, role, access, slug
  }) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}/project/member/invite`,
        {
          email,
          role,
          access,
          projectSlug: slug
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  confirmInvitation(code) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}/project/member/invitation/confirm`,
        {
          code
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  mentorConfirmInvitation(code) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}/project/mentor/invitation/confirm`,
        {
          code,
          action: 'confirmed'
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getProjectMembers(slug) {
    return new Promise((resolve, reject) => {
      Http.get(
        `${endpoint}/project/members/${slug}`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  checkAllowToCreate() {
    return new Promise((resolve, reject) => {
      Http.get(
        `${endpoint}/is-create-project/allow/check`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  submitProjectStep(projectSlug, stepSlug, files) {
    const projectStepSlug = stepSlug.split('_')[1];
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}/submission`,
        {
          projectSlug,
          projectStepSlug,
          files
        }
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  deleteSubmission(id, slug) {
    return new Promise((resolve, reject) => {
      Http.delete(
        `${endpoint}/submission/${id}?slug=${slug}`
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  deleteProjectMember(slug, email) {
    return new Promise((resolve, reject) => {
      Http.delete(
        `${endpoint}/project/members/${slug}?email=${email}`
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  deleteProjectBySlug(slug) {
    return new Promise((resolve, reject) => {
      Http.delete(
        `${endpoint}/project/${slug}`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  submitDiscussion(action = 'comment', data) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}/project-steps/discussion?action=${action}`,
        {
          ...data
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  inviteMeetMentor(data) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}/project/mentor/invite`,
        data
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  }
};
