import React from 'react';
import PropTypes from 'prop-types';
import ProfileImage from '../../assets/user-no-image.png';

import './styles.scss';

const ProjectTeam = (props) => (
  <div className="row mx-0 py-2 container-projectteam">
    {/* Team Image */}
    <div className="col-auto pr-0">
      <img
        onError={(e) => { e.target.src = ProfileImage; }}
        src={props.avatar || ProfileImage}
        className="avatar-project-team rounded-circle"
        alt="Team"
        style={{
          objectFit: 'cover'
        }}
      />
    </div>
    {/* Team Identity */}
    <div className="col align-self-center">
      <b>{props.name}</b>
      <p className="text-grey-kewirus">{props.role}</p>
    </div>
    <div
      className="row mx-auto"
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
      }}
    >
      <div className="col pl-3 py-2">
        <button
          type="button"
          className="action-button-delete btn"
          onClick={props.onItemClick}
          style={{
            display:
              (props.deleteAccess
              && props.role !== 'owner'
              && props.access !== 'admin') || (
                props.isSuper && props.role !== 'owner'
              ) ? '' : 'none'
          }}
        >
          <span className="text-white">
            <i className="fa fa-trash" aria-hidden="true" />
          </span>
        </button>
      </div>
    </div>
  </div>
);

ProjectTeam.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  access: PropTypes.string.isRequired,
  deleteAccess: PropTypes.bool.isRequired,
  isSuper: PropTypes.bool.isRequired,
  avatar: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  email: PropTypes.string.isRequired
};

export default ProjectTeam;
