export default [
  {
    id: 1,
    slug: 'photography',
    title: 'Photograph',
    total: 36,
    published: false,
    created_at: '2020-10-29',
    created_by: 'Team Leader',
    thumbnail: 'https://www.androidcentral.com/sites/androidcentral.com/files/styles/large/public/field/image/2020/08/sale_31350_article_image-3n9f.jpg',
  },
  {
    id: 2,
    slug: 'architecture',
    title: 'Architecture',
    total: 36,
    published: true,
    created_at: '2020-10-29',
    created_by: 'Team Leader',
    thumbnail: 'https://www.agpworkshops.com/wp-content/uploads/2020/07/CSEB-Design-online-course.jpg',
  },
  {
    id: 3,
    slug: 'culinary',
    title: 'Culinary',
    total: 36,
    published: false,
    created_at: '2020-10-29',
    created_by: 'Team Leader',
    thumbnail: 'https://www.agpworkshops.com/wp-content/uploads/2020/07/CSEB-Design-online-course.jpg',
  },
  {
    id: 4,
    slug: 'culinary',
    title: 'Culinary',
    total: 36,
    published: false,
    created_at: '2020-10-29',
    created_by: 'Team Leader',
    thumbnail: 'https://dummyimage.com/600x400/000/fff',
  },
  {
    id: 5,
    slug: 'culinary',
    title: 'Culinary',
    total: 36,
    published: false,
    created_at: '2020-10-29',
    created_by: 'Team Leader',
    thumbnail: 'https://dummyimage.com/600x400/000/fff',
  },
  {
    id: 6,
    slug: 'culinary',
    title: 'Culinary',
    total: 36,
    published: false,
    created_at: '2020-10-29',
    created_by: 'Team Leader',
    thumbnail: 'https://dummyimage.com/600x400/000/fff',
  },
  {
    id: 7,
    slug: 'culinary',
    title: 'Culinary',
    total: 36,
    published: false,
    created_at: '2020-10-29',
    created_by: 'Team Leader',
    thumbnail: 'https://dummyimage.com/600x400/000/fff',
  },
  {
    id: 8,
    slug: 'culinary',
    title: 'Culinary',
    total: 36,
    published: false,
    created_at: '2020-10-29',
    created_by: 'Team Leader',
    thumbnail: 'https://dummyimage.com/600x400/000/fff',
  },
  {
    id: 9,
    slug: 'culinary',
    title: 'Culinary',
    total: 36,
    published: false,
    created_at: '2020-10-29',
    created_by: 'Team Leader',
    thumbnail: 'https://dummyimage.com/600x400/000/fff',
  },
  {
    id: 10,
    slug: 'culinary',
    title: 'Culinary',
    total: 36,
    published: false,
    created_at: '2020-10-29',
    created_by: 'Team Leader',
    thumbnail: 'https://dummyimage.com/600x400/000/fff',
  },
];
