import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const PageTitle = ({ title, subtitle, ...props }) => (
  <div className="container-pagetitle" {...props}>
    <h1>{ title }</h1>
    <p className="text-grey-kewirus">{ subtitle }</p>
  </div>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

PageTitle.defaultProps = {
  subtitle: ''
};

export default PageTitle;
