/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React from 'react';
import _ from 'lodash';
import parse from 'html-react-parser';
import moment from 'moment';

import ApiEvent from '../../services/api/event';
import ApiTrx from '../../services/api/transaction';

import { Modal, Shimmer } from '../../components';
import { dateTimeTmplt, formatCurrency } from '../../utils';
import './styles.scss';

import ExampleThumbnail from '../../assets/no-image.png';
import AppLogo from '../../assets/app-logo.png';

class EventDetail extends React.Component {
  constructor(props) {
    super(props);
    const user = localStorage.getItem('kewirus_u');
    this.state = {
      user: JSON.parse(user),
      data: {},
      slug: '',
      isLoading: true,
      isCreatorEvent: false,
      attend: false,

      // modal
      showModal: false,
      isLoadingCheckout: false,

      alertType: '',
      alertShow: false,
      alertMessage: '',
    };
  }

  componentDidMount() {
    const slug = _.get(this.props, 'match.params.slug');
    const isCreatorEvent = _.get(this.props, 'location.state.isCreatorEvent');
    let attend = _.get(this.props, 'location.state.attend');
    const qp = new URLSearchParams(_.get(this.props, 'location.search'));
    const isRedirect = qp.get('redirect') === 'true';
    const fromRedirect = qp.get('from');

    if (isRedirect && _.isEqual(fromRedirect, 'email')) attend = true;

    this.getEventDetail(slug);
    this.setState({
      attend,
      isCreatorEvent
    });
  }

  getEventDetail = (slug) => {
    ApiEvent.getEventDetail(slug)
      .then((res) => {
        const data = _.get(res, 'data.data');
        this.setState({
          data,
          slug,
          isLoading: false,
        });
      });
  }

  _handleEditEvent = () => {
    const slug = _.get(this.state, 'slug');
    // eslint-disable-next-line react/prop-types
    this.props.history.push(`/event/edit/${slug}`);
  };

  _handleCheckout = (e) => {
    e.stopPropagation();
    const { data } = this.state;
    this.setState({ isLoadingCheckout: true });

    // - hit transaction event
    ApiTrx.buyEvent(data.slug)
      .then((res) => {
        const token = _.get(res, 'data.data');
        if (token === null) {
          this.setState({ showModal: false, attend: true });
          this.setState({
            alertShow: true,
            alertType: 'success',
            alertMessage: 'Event attended'
          });
          return;
        }
        const component = this;
        // - show midtrans popup client
        window.snap.pay(token, {
          onSuccess: (result) => {
            const { slug } = component.state;
            component.setState({
              alertShow: true,
              alertType: 'success',
              alertMessage: _.get(result, 'status_message')
            });

            // - set state
            component.setState({
              alertShow: true,
              alertType: 'success',
              alertMessage: _.get(result, 'status_message'),
              showModal: false,
              attend: true,
            });
            component.getEventDetail(slug);
          },
          onError(result) {
            component.setState({
              alertType: 'error',
              alertMessage: _.get(result, 'status_message'),
              alertShow: true,
              showModal: false
            });
          },
          onClose() {
            const msg = '<p class="text-center">Checkout failed!</p>';
            component.setState({
              alertType: 'error',
              alertMessage: msg,
              alertShow: true,
              showModal: false
            });
          }
        });
      });
  }

  _renderContent = () => (
    <div>
      <h2>About this event</h2>
      <p>
        {this.state.data.description}
      </p>
    </div>
  );

  _renderCheckoutModal = () => (
    <Modal
      show={this.state.showModal}
      onClose={() => this.setState({ showModal: false })}
    >
      <img
        onError={(e) => { e.target.src = ExampleThumbnail; }}
        src={_.get(this.state, 'data.thumbnail') || ExampleThumbnail}
        className="thumbnail-event"
        alt="event-thumbnail"
      />
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-12">
            <h1>{_.get(this.state, 'data.name')}</h1>
          </div>
        </div>
        <div className="row mt-3">
          <label className="col-12">Price</label>
          <div className="col-12">
            <h1>
              {formatCurrency(this.state.data.price - this.state.data.discountPrice)}
            </h1>
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="mt-5 py-3 btn btn-kewirus col-12"
        style={{
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px'
        }}
        disabled={this.state.isLoadingCheckout}
        onClick={this._handleCheckout}
      >
        {
          this.state.isLoadingCheckout
            ? 'Checkout..'
            : 'Checkout'
        }
      </button>
    </Modal>
  )

  render() {
    return (
      <div className="container-event-detail col px-5 py-5">
        {
          this.state.alertShow && (
            <div
              className={`alert ${_.isEqual(this.state.alertType, 'error') ? 'alert-danger' : 'alert-success'} alert-dismissible fade show`}
              role="alert"
              style={{
                position: 'fixed',
                top: '10%',
                zIndex: '10'
              }}
            >
              <p>
                {parse(this.state.alertMessage)}
              </p>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ alertMessage: '', alertShow: false })}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )
        }
        {/* Search Bar and Logo */}
        { this._renderCheckoutModal() }
        <div className="row">
          {/* Logo */}
          <a href="/" className="kewirus-desktop-only col-auto mb-5 ml-auto">
            <img src={AppLogo} className="app-logo" alt="Cabin Crew Class Logo" />
          </a>
        </div>
        {/* Title */}
        <div className="row">
          {/* Event Thumbnail */}
          <div className="col-md-8 pr-4">
            {_.isEqual(this.state.isLoading, true) ? (
              <Shimmer style={{ height: '50vh' }} />
            ) : (
              <img
                onError={(e) => { e.target.src = ExampleThumbnail; }}
                className="thumbnail-event"
                src={this.state.data.thumbnail || ExampleThumbnail}
                alt="thumbnail event"
              />
            )}
          </div>
          {/* Event Information */}
          <div className="col-md-4 container-event-information mt-5 mt-md-0">
            {/* Event Title */}
            <div className="row mx-0">
              <div className="col-10 pl-0">
                <h1>{this.state.data.name}</h1>
              </div>
              <div className="col-2">
                {
                  (this.state.user.kewirusType === 'admin') && (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <span
                      style={{
                        fontSize: 20,
                        cursor: 'pointer'
                      }}
                      className="text-black"
                      onClick={this._handleEditEvent}
                    >
                      <i className="fa fa-pencil" aria-hidden="true" />
                    </span>
                  )
                }
              </div>
            </div>
            {/* Event Category */}
            <div className="row mx-0">
              <h2>{this.state.data.category}</h2>
            </div>
            {/* Event Author */}
            <div className="row mx-0">
              <p className="text-grey-kewirus mb-0">
                By
                {` ${this.state.data.creator}`}
              </p>
            </div>
            {/* Event Date */}
            <div className="row mx-0">
              <p>
                {moment(this.state.data.startedAt, dateTimeTmplt).format('ddd, DD MMMM YYYY')}
                <br />
                {`${moment(this.state.data.startedAt, dateTimeTmplt).format('hh:mmA')} - `}
                {`${moment(this.state.data.endAt, dateTimeTmplt).format('hh:mmA [GMT] Z')}`}
              </p>
            </div>
            {/* Event Location */}
            <div className="row mx-0">
              <b>Online Event</b>
            </div>
            {/* Event Price */}
            <div className="row mx-0 my-4">
              {
                this.state.data.discountPrice <= 0
                  ? (
                    <h1 className="text-blue-kewirus">
                      {formatCurrency(this.state.data.price)}
                    </h1>
                  )
                  : (
                    <>
                      <h2 className="text-grey-kewirus" style={{ width: '100%' }}>
                        <s>{formatCurrency(this.state.data.price)}</s>
                      </h2>
                      <h1 className="text-blue-kewirus">
                        {formatCurrency(this.state.data.price - this.state.data.discountPrice)}
                      </h1>
                    </>
                  )
              }
            </div>
            { _.get(this.state, 'data.remainingQuota') <= 0 && (
              <div className="row mx-0 mt-5">
                <p>Already full</p>
              </div>
            ) }
            {/* Button Register */}
            <div className="row mx-0 mt-5">
              <button
                onClick={() => this.setState({ showModal: true })}
                type="button"
                className="btn btn-kewirus event-register-button"
                disabled={this.state.isCreatorEvent || this.state.attend}
                style={{ display: _.get(this.state, 'data.remainingQuota') <= 0 && !this.state.attend ? 'none' : '' }}
              >
                {this.state.isCreatorEvent
                  ? 'You own this event'
                  : this.state.attend
                    ? 'Already Registered'
                    : 'Register'}
              </button>
            </div>
          </div>
        </div>
        {/* Event Content */}
        <div className="row mt-5">
          <div className="col-md-8">
            {this._renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

export default EventDetail;
