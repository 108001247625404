/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import _ from 'lodash';
import parse from 'html-react-parser';

import { validateStringLen } from '../../utils';
import { PageTitle } from '../../components';

import AppLogo from '../../assets/app-logo.png';
import UploadImg from '../../assets/upload-image.png';

import 'react-datepicker/dist/react-datepicker.css';

// apis
import ApiProject from '../../services/api/project';

class CreateEditClass extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      project: {},
      projectTypes: [],
      slug: '',
      imageBase64: null,

      alertShow: false,
      alertMessage: '',
      alertType: 'error',

      isProjectNameValidated: null,
      isProjectDescValidated: null,
      isProjectTypeValidated: null,
    };
  }

  async componentDidMount() {
    try {
      const [types, res] = await Promise.all([
        ApiProject.getProjectTypes(),
        ApiProject.checkAllowToCreate(),
      ]);

      if (_.isEqual(res.status, 200)) {
        this.setState({
          projectTypes: _.get(types, 'data.data'),
        });
      } else throw Error();
    } catch (e) {
      // send response back to /class page
      const { response } = e;
      const { message } = response.data;
      const alertMessage = message.map((msg) => `${msg.message}`).join('');

      // eslint-disable-next-line react/prop-types
      this.props.history.replace('/class', {
        alertShow: true,
        alertType: 'error',
        alertMessage,
      });
    }
  }

  _handleSaveProject = () => {
    const { project } = this.state;

    ApiProject.addProject(project)
      .then((res) => {
        const data = _.get(res, 'data');
        const { message } = data;
        const msg = message.map((_msg) => `<p>${_msg.message}</p>`).join('');

        // eslint-disable-next-line react/prop-types
        this.props.history.replace('/class', {
          alertShow: true,
          alertType: 'success',
          alertMessage: msg,
        });
      })
      .catch((e) => {
        const { response } = e;
        const { message } = response.data;
        const msg = message.map((_msg) => `<li>${_msg.message}</li>`).join('');

        this.setState({
          alertShow: true,
          alertMessage: `Failed to create project. because of: </br>${msg}`,
          alertType: 'error',
        });
      });
  };

  _handleChooseImage = () => {
    this.uploader.click();
  };

  _onChangeFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // get image file
    const file = _.get(e, 'target.files[0]');
    if (_.isNil(file)) return;

    // read file blob and convert to base64
    const reader = new FileReader();
    reader.onload = (_e) => {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        imageBase64: _.get(_e, 'target.result'),
      });
    };
    reader.readAsDataURL(file);

    this.setState({
      project: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.project,
        thumbnail: file,
      },
    });
  };

  _handleInputTextChanged = (e, type) => {
    const projectObj = 'project.';
    const isProjectObj = type.includes(projectObj);
    const value = _.get(e, 'target.value');
    const project = _.get(this, 'state.project');

    if (isProjectObj) {
      const prop = type.substring(projectObj.length, type.length);
      let validate;

      switch (prop) {
      case 'description':
        validate = validateStringLen(value, 1);

        this.setState({
          isProjectDescValidated: validate,
        });
        break;
      case 'projectTypeSlug':
        validate = validateStringLen(value, 1);

        this.setState({
          isProjectTypeValidated: validate,
        });
        break;
      case 'name':
        // - validate string len
        validate = validateStringLen(value, 1);

        this.setState({
          isProjectNameValidated: validate,
        });
        break;
      default:
        break;
      }

      // - add to object prop
      project[prop] = value;
      this.setState({
        project,
      });
    }
  };

  /**
   * Render overview page
   */
  _renderOverview = () => {
    const imgSrc = _.isNull(this.state.imageBase64)
      ? this.state.project.thumbnail || UploadImg
      : this.state.imageBase64;

    return (
      <div className="container-project-create row">
        <div className="row col-12 pr-0">
          {/* Name */}
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="project_name">
                <b>Class Name</b>
              </label>
              <input
                type="text"
                className={`form-control ${
                  this.state.isProjectNameValidated
                    ? 'is-valid'
                    : _.isNull(this.state.isProjectNameValidated)
                      ? ''
                      : 'is-invalid'
                }`}
                id="project_name"
                placeholder="Enter project name"
                value={this.state.project.name}
                onChange={(e) => this._handleInputTextChanged(e, 'project.name')}
                required
              />
            </div>
          </div>
          {/* Class Type */}
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="project_type">
                <b>Class Type</b>
              </label>
              <select
                className={`form-control ${
                  this.state.isProjectTypeValidated
                    ? 'is-valid'
                    : _.isNull(this.state.isProjectTypeValidated)
                      ? ''
                      : 'is-invalid'
                }`}
                id="project_type"
                defaultValue=""
                value={this.state.project.projectTypeSlug}
                onChange={(e) => this._handleInputTextChanged(e, 'project.projectTypeSlug')}
              >
                <option disabled value="">
                  Choose...
                </option>
                {this._renderProjectTypes()}
              </select>
            </div>
          </div>
        </div>
        {/* <div className="row col-12 mt-3 pr-0">
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="project_type">
                <b>Enter institution code (optional)</b>
              </label>
              <input
                type="text"
                className="form-control"
                id="institution_code"
                placeholder="Enter institution code"
                value={this.state.project.institutionCode}
                onChange={(e) => this._handleInputTextChanged(e, 'project.institutionCode')}
                required
              />
            </div>
          </div>
        </div> */}
        <div className="row col-12 mt-3 pr-0">
          {/* Thumbnail */}
          <div className="col-md-6">
            <label htmlFor="project_thumbnail">
              <b>Class Thumbnail</b>
            </label>
            <img
              onClick={this._handleChooseImage}
              src={imgSrc}
              style={{
                objectFit: 'cover',
              }}
              className="mx-auto thumbnail-project-create"
              alt="Thumbnail project"
            />
            <input
              type="file"
              accept="image/*"
              id="file"
              ref={(ref) => {
                this.uploader = ref;
              }}
              style={{ display: 'none' }}
              onChange={this._onChangeFile}
            />
          </div>
          {/* Description */}
          <div className="col-md-6">
            <label htmlFor="project_desc">
              <b>Class Description</b>
            </label>
            <textarea
              className={`form-control ${
                this.state.isProjectDescValidated
                  ? 'is-valid'
                  : _.isNull(this.state.isProjectDescValidated)
                    ? ''
                    : 'is-invalid'
              }`}
              rows="13"
              id="project_desc"
              placeholder="Enter your class description"
              value={this.state.project.description}
              onChange={(e) => this._handleInputTextChanged(e, 'project.description')}
              required
            />
          </div>
        </div>
      </div>
    );
  };

  _renderProjectTypes = () => _.map(this.state.projectTypes, (type) => (
    <option key={type.slug} value={_.get(type, 'slug')}>
      {_.get(type, 'name')}
    </option>
  ));

  render() {
    return (
      <div className="container-project-create col container-page-kewirus">
        {this.state.alertShow && (
          <div
            className={`alert ${
              _.isEqual(this.state.alertType, 'error')
                ? 'alert-danger'
                : 'alert-success'
            } alert-dismissible fade show`}
            role="alert"
            style={{
              position: 'fixed',
              top: '10%',
              zIndex: '10',
            }}
          >
            <p>{parse(this.state.alertMessage)}</p>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => this.setState({ alertMessage: '', alertShow: false })}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}

        {/* Search Bar and Logo */}
        <div className="row">
          {/* Logo */}
          <a href="/" className="kewirus-desktop-only col-auto mb-5 ml-auto">
            <img
              src={AppLogo}
              className="app-logo"
              alt="Cabin Crew Class Logo"
            />
          </a>
        </div>

        {/* Title */}
        <div className="row mx-0 pt-5">
          <PageTitle
            title={`${
              _.isEmpty(this.state.slug) ? 'Create New' : 'Edit'
            } Class`}
            subtitle=""
          />
        </div>
        <div className="py-5 px-5 mt-3 mb-3 project-card">
          {/* Overview page */}
          {this._renderOverview()}

          {/* Save Button */}
          <div className="row pt-5">
            <div className="col text-right mr-3">
              <button
                type="button"
                className="btn btn-kewirus"
                onClick={this._handleSaveProject}
                disabled={
                  !(
                    _.isEqual(this.state.isProjectDescValidated, true)
                    && _.isEqual(this.state.isProjectNameValidated, true)
                    && _.isEqual(this.state.isProjectTypeValidated, true)
                  )
                }
              >
                Save Project
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateEditClass;
