import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import './styles.scss';

const ProjectStep = (props) => {
  const [active, setActive] = useState(0);
  const { sections, onTabClick } = props;
  const sectionViews = [];
  const contentViews = [];

  // - render sections & content
  sections.forEach((section, idx) => {
    const { title, items, renderItems } = section;
    // - add section view
    sectionViews.push(
      // eslint-disable-next-line react/no-array-index-key
      <div className="nav-item" key={`step-section-${idx}`}>
        <a
          onClick={(e) => {
            setActive(idx);
            onTabClick(e, idx);
          }}
          className={`nav-link-projectstep ${active === idx ? 'active' : ''}`}
          id={`pills-${title}-tab`}
          data-toggle="pill"
          href={`pills-${title}`}
          role="tab"
          aria-controls={`pills-${title}`}
          aria-selected="true"
        >
          {/* Step ID */}
          { idx > 0 ? (
            <b>{ `Step ${idx}` }</b>
          ) : null}
          {/* Step Title */}
          <p className="mt-1">{ title }</p>
          {/* Step Progress */}
          {
            !_.isNil(items[0].progress) && (
              <div className="step-percentage">
                {Number(_.get(items, '[0].progress', 0)).toFixed(2).replace(/[.,]00$/, '')}
                %
              </div>
            )
          }
        </a>
      </div>
    );

    // - add content view
    const content = renderItems(items, idx);
    contentViews.push(
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={`step-content-${idx}`}
        className={`tab-pane fade ${active === idx ? 'show active' : ''}`}
        id={`pills-${title}`}
        role="tabpanel"
        aria-labelledby={`pills-${title}-tab`}
      >
        { content }
      </div>
    );
  });

  return (
    <div className="container-projectstep">
      {/* Section Headers */}
      <div className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        { sectionViews }
      </div>
      {/* Content */}
      <div className="tab-content" id="pills-tabContent">
        { contentViews }
      </div>
    </div>
  );
};

ProjectStep.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.any).isRequired,
      renderItems: PropTypes.func.isRequired
    })
  ).isRequired,
  onTabClick: PropTypes.func.isRequired
};

export default ProjectStep;
