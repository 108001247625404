import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const SearchBar = ({ buttonText, onSearch, ...props }) => (
  <div className="row mx-0 container-searchbar" {...props}>

    <div className="col">
      <div className="input-group mb-2">
        <div className="input-group-prepend">
          <div className="input-group-text">
            <span className="text-grey-kewirus">
              <i className="fa fa-search" aria-hidden="true" />
            </span>
          </div>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Search . . ."
          onChange={onSearch}
        />
      </div>
    </div>

    <div className="col-auto container-button-searchbar">
      <button type="button" className="btn btn-rounded-kewirus px-4">{ buttonText }</button>
    </div>
  </div>
);

SearchBar.propTypes = {
  onSearch: PropTypes.func,
  buttonText: PropTypes.string
};

SearchBar.defaultProps = {
  buttonText: 'Search',
  onSearch: () => {}
};

export default SearchBar;
