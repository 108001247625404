/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import parse from 'html-react-parser';
import _ from 'lodash';
import { validateStringLen } from '../../utils';

import ApiAuth from '../../services/api/auth';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPass: false,
      alertShow: false,
      alertMsg: '',
      alertType: '',
      password: '',
      code: '',
      confirmPass: '',

      isPassValidated: null,
      isConfirmPassValidated: null,
    };
  }

  componentDidMount() {
    const qp = new URLSearchParams(_.get(this.props, 'location.search'));
    const code = qp.get('code');
    this.setState({ code });
  }

  _handleInputTextChanged = (e, field) => {
    const { value } = e.target;

    if (_.isEqual(field, 'password')) {
      const validate = validateStringLen(value, 8);
      const validate2 = _.isEqual(_.get(this, 'state.confirmPass'), value);

      this.setState({
        isPassValidated: validate,
        isConfirmPassValidated: validate2,
      });
    } else if (_.isEqual(field, 'confirmPass')) {
      const { password } = this.state;
      const validate = _.isEqual(password, value);

      this.setState({
        isConfirmPassValidated: validate,
      });
    }

    this.setState({
      [field]: value,
    });
  };

  _handleResetPass = () => {
    const { password, code } = this.state;

    ApiAuth.resetPass(code, password)
      .then(() => {
        this.setState({
          alertMsg: 'Your password has been successfully changed.',
          alertType: 'success',
          alertShow: true,
        });
        setTimeout(() => {
          // eslint-disable-next-line react/prop-types
          this.props.history.push('/login');
        }, 2000);
      })
      .catch((e) => {
        let alertMsg = _.get(e, 'response.data.message');
        alertMsg = alertMsg.map((msg) => `<li>${msg.message}</li>`);
        alertMsg = alertMsg.join('');

        this.setState({
          alertMsg: `Failed change to new password ${`${alertMsg}`}`,
          alertShow: true,
          alertType: 'error'
        });
      });
  }

  render() {
    return (
      <div className="container-login">
        {
          this.state.alertShow && (
            <div
              className={`alert ${_.isEqual(this.state.alertType, 'error') ? 'alert-danger' : 'alert-success'} alert-dismissible fade show`}
              role="alert"
              style={{
                position: 'fixed',
                top: '10%',
                zIndex: '10'
              }}
            >
              <p>{parse(this.state.alertMsg)}</p>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ alertMsg: '', alertShow: false })}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )
        }
        {/* Reset Password */}
        <div className="cont s-signup">
          <div className="sub-cont" style={{ paddingLeft: '0px' }}>
            <div className="form sign-up" style={{ width: '100%' }}>
              {/* Header */}
              <div className="text-center">
                <h1 className="text-center">Please Insert a New Password</h1>
              </div>
              <form className="mt-5 pt-5 mx-5">
                <div className="row">
                  {/* Password */}
                  <div className="form-group col-12">
                    <label htmlFor="inputPasswordSignUp">New Password</label>
                    <div className="input-group">
                      <input
                        type={`${this.state.showPass ? 'text' : 'password'}`}
                        // eslint-disable-next-line no-nested-ternary
                        className={`form-control ${this.state.isPassValidated
                          ? 'is-valid'
                          : _.isNull(this.state.isPassValidated)
                            ? ''
                            : 'is-invalid'
                        }`}
                        id="inputPasswordSignUp"
                        placeholder="Enter your password"
                        value={this.state.password}
                        onChange={(e) => this._handleInputTextChanged(e, 'password')}
                      />
                      <div
                        onClick={() => {
                          this.setState({
                            // eslint-disable-next-line react/no-unused-state
                            showPass: !_.get(this, 'state.showPass')
                          });
                        }}
                        className="input-group-append"
                        style={{ cursor: 'pointer' }}
                      >
                        <span className="input-group-text">
                          <i className={`fa ${!this.state.showPass ? 'fa-eye' : 'fa-eye-slash'}`} />
                        </span>
                      </div>
                      {!this.state.isPassValidated && (
                        <div className="invalid-feedback">
                          Please enter password with minimal 8 characters
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Confirm Password */}
                  <div className="form-group col-12">
                    <label htmlFor="inputConfirmPasswordSignUp">
                      Confirm Password
                    </label>
                    <div className="input-group">
                      <input
                        type={`${this.state.showPass ? 'text' : 'password'}`}
                        // eslint-disable-next-line no-nested-ternary
                        className={`form-control ${this.state.isConfirmPassValidated
                          ? 'is-valid'
                          : _.isNull(this.state.isConfirmPassValidated)
                            ? ''
                            : 'is-invalid'
                        }`}
                        id="inputConfirmPasswordSignUp"
                        placeholder="Re-enter your password"
                        value={this.state.confirmPass}
                        onChange={(e) => this._handleInputTextChanged(e, 'confirmPass')}
                      />
                      <div
                        onClick={() => {
                          this.setState({
                            // eslint-disable-next-line react/no-unused-state
                            showPass: !_.get(this, 'state.showPass')
                          });
                        }}
                        className="input-group-append"
                        style={{ cursor: 'pointer' }}
                      >
                        <span className="input-group-text">
                          <i className={`fa ${!this.state.showPass ? 'fa-eye' : 'fa-eye-slash'}`} />
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* Submit button */}
                  <button
                    className="submit mt-5 w-100 mx-2"
                    type="button"
                    disabled={!(this.state.isConfirmPassValidated && this.state.isPassValidated)}
                    onClick={this._handleResetPass}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
