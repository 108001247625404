import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const NavTabs = (props) => {
  const [active, setActive] = useState(0);
  const { sections, onTabClick } = props;
  const sectionViews = [];
  const contentViews = [];

  // - render sections & content
  sections.forEach((section, idx) => {
    const { title, items, renderItems } = section;
    // - add section view
    sectionViews.push(
      <li className="nav-item" key={`navtab-${title}`}>
        <a
          onClick={(e) => {
            setActive(idx);
            onTabClick(e, idx);
          }}
          className={`nav-link ${active === idx ? 'active' : ''}`}
          id={`pills-${title}-tab`}
          data-toggle="pill"
          href={`pills-${title}`}
          role="tab"
          aria-controls={`pills-${title}`}
          aria-selected="true"
        >
          { title }
        </a>
      </li>
    );

    // - add content view
    const content = renderItems(items);
    contentViews.push(
      <div
        key={`navtab-content-${title}`}
        className={`tab-pane fade ${active === idx ? 'show active' : ''}`}
        id={`pills-${title}`}
        role="tabpanel"
        aria-labelledby={`pills-${title}-tab`}
      >
        { content }
      </div>
    );
  });

  return (
    <div className="container-navtabs">
      {/* Section Headers */}
      <ul className="nav nav-pills" id="pills-tab" role="tablist">
        { sectionViews }
      </ul>
      {/* Content */}
      <div className="tab-content" id="pills-tabContent">
        { contentViews }
      </div>
    </div>
  );
};

NavTabs.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.any).isRequired,
      renderItems: PropTypes.func.isRequired
    })
  ).isRequired,
  onTabClick: PropTypes.func.isRequired
};

export default NavTabs;
