/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CheckMarkSvg from '../../assets/checkmark.svg';
import './styles.scss';

const CollapsedStepper = (props) => (
  <div className="accordion collapsed-stepper mb-2" id="collapsed-stepper">
    <div className="card">
      <div className="card-header" id="headingOne" data-toggle="collapse" data-target={`#collapse${props.index}`}>
        <h2 className="row mb-0">
          {/* Number */}
          <div className="col-1 align-self-center">
            {props.index}
          </div>
          {/* Title Step */}
          <div className="col-9 pl-0 align-self-center">
            <button type="button" className="btn">
              {props.name}
            </button>
          </div>
          {/* Icon arrow down */}
          <div className="col-1 align-self-center pr-0">
            <span className="float-right"><i className="fa fa-chevron-down" /></span>
          </div>
        </h2>
      </div>
      <div id={`collapse${props.index}`} className="collapse" aria-labelledby="headingOne" data-parent="#collapsed-stepper">
        <div className="card-body">
          <ul className="list-group">
            {
              props.lessons.map((lesson, idx) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  key={lesson.code}
                  className="list-group-item row"
                  style={{
                    backgroundColor:
                      _.isEqual(props.selected.stepID, props.stepID)
                      && _.isEqual(props.selected.lessonID, idx)
                        ? '#E9E9E9' : ''
                  }}
                  onClick={() => props.onStepperClick(props.stepID, idx, lesson.code, lesson.type)}
                >
                  {/* Title Next Step */}
                  <div className="col-9">
                    {lesson.name}
                  </div>
                  {/* Type */}
                  <div className="col-auto ml-auto">
                    <div strokeWidth="0.5" aria-hidden="true" className="checkmark">
                      <img src={CheckMarkSvg} height="16px" width="16px" alt="check" />
                    </div>
                  </div>
                </li>
              ))
            }
            {/* <li className="list-group-item">Morbi leo risus</li> */}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

CollapsedStepper.propTypes = {
  name: PropTypes.string.isRequired,
  lessons: PropTypes.array.isRequired,
  stepID: PropTypes.number.isRequired,
  selected: PropTypes.shape({
    stepID: PropTypes.number.isRequired,
    lessonID: PropTypes.number.isRequired
  }).isRequired,
  index: PropTypes.number.isRequired,

  onStepperClick: PropTypes.func.isRequired
};

export default CollapsedStepper;
