/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';

import './styles.scss';

import ApiAuth from '../../services/api/auth';
import ApiMisc from '../../services/api/misc';

import FacebookIcon from '../../assets/facebook.png';
import InstagramIcon from '../../assets/instagram.png';
import Logo from '../../assets/logo.png';
import { validateEmail, validatePhoneNumber, validateStringLen } from '../../utils';

import {
  Modal
} from '../../components';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isRegister: null,

      fullName: '',
      email: '',
      forgotEmail: '',
      emailConfirm: '',
      password: '',
      confirmPass: '',
      lastEducation: '',
      phoneNumber: '',
      address: '',
      gender: '',
      schoolName: '',
      provinceId: 0,
      cityId: 0,

      showPass: false,
      showConfirmPass: false,

      isEmailValidated: null,
      isForgotEmailValidated: null,
      isPassValidated: null,
      isFullNameValidated: null,
      isLastEduValidated: null,
      isPhoneValidated: null,
      isProvinceValidated: null,
      isCityValidated: null,
      isGenderValidated: null,
      isInstitutionValidated: null,
      isConfirmPassValidated: null,
      isLoading: false,
      isAddressValidated: null,

      isWaitingEmailConfirm: false,
      loginResendCode: false,

      alertShow: false,
      alertMessage: '',
      alertType: 'error',

      provinces: [],
      cities: []
    };
  }

  componentDidMount() {
    this._getProvinces();
  }

  _getProvinces = () => {
    ApiMisc.getProvinces()
      .then((res) => {
        const provinces = _.get(res, 'data.data');
        this.setState({
          provinces
        });
      });
  }

  _getCitiesById = (id) => {
    ApiMisc.getCitiesByProvinceId(id)
      .then((res) => {
        const cities = _.get(res, 'data.data');
        this.setState({
          cities,
        });
      });
  }

  _handleClickSignIn = () => {
    this.setState({ isRegister: false });
  }

  _handleClickSignUp = () => {
    this.setState({ isRegister: true });
  }

  _handleLogin = () => {
    const { email, password } = this.state;
    this.setState({
      isLoading: true,
    });

    ApiAuth
      .login(email, password)
      .then(async () => {
        this.setState({
          isLoading: false
        });

        const { data } = await ApiAuth.getUser();
        const usr = data.data;
        const isMentor = usr.kewirusType === 'mentor';
        const isInstitution = usr.kewirusType === 'institution';

        if ((_.get(usr, 'emailVerified') === true && !isMentor && !isInstitution)
          || isMentor || isInstitution) {
          // eslint-disable-next-line react/prop-types
          this.props.history.push('/class');
        } else {
          await localStorage.removeItem('kewirus_t');
          this.setState({
            alertMessage: 'Email is not verified, please verify first by click ',
            alertShow: true,
            alertType: 'error',
            emailConfirm: _.get(this, 'state.email'),
            loginResendCode: true,
          });
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false,

          alertShow: true,
          alertType: 'error',
          loginResendCode: false,
          alertMessage: 'Incorrect email or password'
        });
      });
  }

  _handleRegister = () => {
    const {
      fullName, email, password, lastEducation, phoneNumber, address,
      gender, schoolName, cityId
    } = this.state;

    ApiAuth.register({
      email,
      name: fullName,
      password,
      phoneNumber,
      lastEducation,
      address,
      gender,
      schoolName,
      cityId
    })
      .then((res) => {
        const { data } = res;
        const { message } = data;

        const msg = message.map((_msg) => `${_msg.message}`).join('');
        const succMsg = 'Please verify your email address, we have sent an email with a confirmation link';

        this.setState({
          alertMessage: `${msg}. ${succMsg}`,
          alertType: 'success',
          alertShow: true,

          fullName: '',
          email: '',
          emailConfirm: _.get(this, 'state.email'),
          password: '',
          confirmPass: '',
          lastEducation: '',
          phoneNumber: '',
          address: '',

          isFullNameValidated: null,
          isLastEduValidated: null,
          isEmailValidated: null,
          isPhoneValidated: null,
          isConfirmPassValidated: null,
          isPassValidated: null,
          isAddressValidated: null,

          isWaitingEmailConfirm: true,

        });
      })
      .catch((e) => {
        const { response } = e;
        const { message } = response.data;

        const msg = message.map((_msg) => `<li>${_msg.message}</li>`).join('');

        this.setState({
          alertShow: true,
          alertType: 'error',
          alertMessage: `Register failed, because of: <br/>${msg}`,
          confirmPass: '',
          isConfirmPassValidated: false,
        });
      });
  }

  _handleResendVerifyEmail = () => {
    const email = _.get(this, 'state.emailConfirm');
    ApiAuth.resendCode(email)
      .then(() => {
        const succMsg = `We've resent you confirmation link to your email:
        <b>${this.state.emailConfirm}</b>.
        <br/>Please check again your email in primary inbox or spam folder`;

        this.setState({
          alertMessage: `${succMsg}`,
          alertType: 'success',
          alertShow: true,
          loginResendCode: false,
        });
      })
      .catch((e) => {
        const { response } = e;
        const { message } = response.data;

        const msg = message.map((_msg) => `<li>${_msg.message}</li>`).join('');
        this.setState({
          alertMessage: msg,
          alertType: 'error',
          alertShow: true
        });
      });
  }

  _handleInputTextChanged = (e, field) => {
    const { value } = e.target;

    if (_.isEqual(field, 'email') || _.isEqual(field, 'forgotEmail')) {
      const validate = validateEmail(value);
      const prop = field === 'email' ? 'isEmailValidated' : 'isForgotEmailValidated';
      this.setState({
        [prop]: validate
      });
    } else if (_.isEqual(field, 'password')) {
      const validate = validateStringLen(value, 6);
      const validate2 = _.isEqual(_.get(this, 'state.confirmPass'), value);

      this.setState({
        isPassValidated: validate,
        isConfirmPassValidated: validate2
      });
    } else if (_.isEqual(field, 'fullName')) {
      const validate = validateStringLen(value, 3);
      this.setState({
        isFullNameValidated: validate
      });
    } else if (_.isEqual(field, 'lastEducation')) {
      const validate = !_.isEmpty(value);
      this.setState({
        isLastEduValidated: validate
      });
    } else if (_.isEqual(field, 'phoneNumber')) {
      const validate = validatePhoneNumber(value);
      this.setState({
        isPhoneValidated: validate
      });
    } else if (_.isEqual(field, 'confirmPass')) {
      const { password } = this.state;
      const validate = _.isEqual(password, value);

      this.setState({
        isConfirmPassValidated: validate
      });
    } else if (_.isEqual(field, 'address')) {
      const validate = !_.isEmpty(value);

      this.setState({
        isAddressValidated: validate
      });
    } else if (_.isEqual(field, 'gender')) {
      const validate = !_.isEmpty(value);

      this.setState({
        isGenderValidated: validate
      });
    } else if (_.isEqual(field, 'schoolName')) {
      const validate = !_.isEmpty(value);

      this.setState({
        isInstitutionValidated: validate
      });
    }

    this.setState({
      [field]: value
    });
  }

  _handleKeyUp = ({ keyCode }) => {
    if (_.isEqual(keyCode, 13)) this._handleLogin();
  }

  _handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  }

  _handleForgotPass = () => {
    // - hit reset pass
    const { forgotEmail: email } = this.state;
    ApiAuth.forgotPass(email)
      .then((res) => {
        const { data } = res;
        const message = _.get(data, 'message');
        const alertMessage = message.map((msg) => `${msg.message}`).join('');

        this._handleCloseModal();
        this.setState({
          alertType: 'success',
          alertShow: true,
          alertMessage
        });
      })
      .catch((e) => {
        let alertMessage = _.get(e, 'response.data.message');
        alertMessage = alertMessage.map((msg) => `<li>${msg.message}</li>`);
        alertMessage = alertMessage.join('');

        this.setState({
          alertMessage: `Failed request to change password because ${alertMessage}`,
          alertShow: true,
          alertType: 'error'
        });
      });
  }

  _renderForgotPasswordModal = () => (
    <Modal show={this.state.showModal}>
      <div className="container mentor-modal mt-5 mb-4 mx-3">
        <div className="row">
          <h1 className="col mb-4">Please let us know your email</h1>
        </div>
        <div className="row mx-0">
          <input
            type="text"
            onChange={(e) => this._handleInputTextChanged(e, 'forgotEmail')}
            // eslint-disable-next-line no-nested-ternary
            className={`form-control ${this.state.isForgotEmailValidated
              ? 'is-valid'
              : _.isNull(this.state.isForgotEmailValidated)
                ? ''
                : 'is-invalid'
            }`}
            id="user_email"
            placeholder="Input your email"
            value={this.state.forgotEmail}
            required
          />
          {/* Alert */}
          {
            !this.state.isForgotEmailValidated && (
              <div className="invalid-feedback">
                Please input correct email address
              </div>
            )
          }
        </div>
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={this._handleCloseModal}
              className="btn m-up mt-4 col-12 border-blue-kewirus"
            >
              <b className="text-blue-kewirus">Cancel</b>
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              disabled={!this.state.isForgotEmailValidated}
              onClick={this._handleForgotPass}
              className="mt-4 btn submit col-12"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )

  render() {
    return (
      <div className="container-login">
        {/* Forgot pass modal */}
        { this._renderForgotPasswordModal() }
        {
          this.state.alertShow && (
            <div
              className={`alert ${_.isEqual(this.state.alertType, 'error') ? 'alert-danger' : 'alert-success'} alert-dismissible fade show`}
              role="alert"
              style={{
                position: 'fixed',
                top: '10%',
                zIndex: '10'
              }}
            >
              <p>
                {parse(this.state.alertMessage)}
                { _.isEqual(this.state.loginResendCode, true) && (
                  <a href="#" onClick={this._handleResendVerifyEmail}>resend verify email</a>
                ) }
              </p>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ alertMessage: '', alertShow: false })}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )
        }
        <div className={
          `${this.state.isRegister ? 'cont s-signup' : 'cont'}`
        }
        >
          {/* SIGN IN */}
          <div className="form sign-in">
            {/* Header */}
            <div className="text-center">
              <img src={Logo} className="rounded-circle" alt="app" />
              <h1 className="text-center mt-5">Sign In</h1>
            </div>
            <form className="container-form-login mt-3 p-0">
              {/* Field Email */}
              <div className="form-group col-12">
                <label htmlFor="inputEmailSignIn">Email address</label>
                <input
                  type="email"
                  // eslint-disable-next-line no-nested-ternary
                  className={`form-control ${this.state.isEmailValidated
                    ? 'is-valid'
                    : _.isNull(this.state.isEmailValidated)
                      ? ''
                      : 'is-invalid'
                  }`}
                  id="inputEmailSignIn"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onKeyUp={this._handleKeyUp}
                  onChange={(e) => this._handleInputTextChanged(e, 'email')}
                  required
                />
                {
                  !this.state.isEmailValidated && !_.isNull(this.state.isEmailValidated) && (
                    <div className="invalid-feedback">
                      Please enter correct email format
                    </div>
                  )
                }
              </div>
              {/* Field Password */}
              <div className="form-group col-12">
                <label htmlFor="inputPasswordSignIn">Password</label>
                <div className="input-group">
                  <input
                    type={`${this.state.showPass ? 'text' : 'password'}`}
                    className="form-control"
                    id="inputPasswordSignIn"
                    placeholder="Enter password"
                    value={this.state.password}
                    onKeyUp={this._handleKeyUp}
                    onChange={(e) => this._handleInputTextChanged(e, 'password')}
                  />
                  <div
                    onClick={() => {
                      this.setState({
                        // eslint-disable-next-line react/no-unused-state
                        showPass: !_.get(this, 'state.showPass')
                      });
                    }}
                    className="input-group-append"
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="input-group-text">
                      <i className={`fa ${!this.state.showPass ? 'fa-eye' : 'fa-eye-slash'}`} />
                    </span>
                  </div>
                </div>
              </div>
              {/* Sign in Button */}
              <button
                className="submit mt-5"
                type="button"
                disabled={(!(this.state.isEmailValidated && this.state.isPassValidated))
                || this.state.isLoading}
                onClick={this._handleLogin}
              >
                {this.state.isLoading ? 'Signing In...' : 'Sign In'}
              </button>
            </form>

            <div className="row mx-auto">
              <button
                type="button"
                className="forgot-pass mx-auto"
                onClick={() => this.setState({ showModal: true })}
              >
                Forgot Password?
              </button>
            </div>

            {/* Social Media Link */}
            <div className="row mx-0">
              <div className="social-media mx-auto">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/cabincrewclass/">
                      <img src={FacebookIcon} alt="facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/cabincrewclass/">
                      <img src={InstagramIcon} alt="instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row mx-0 kewirus-mobile-only">
              <button
                type="button"
                onClick={this._handleClickSignUp}
              >
                <span className="text-dark">
                  Dont have any account?
                  {' '}
                  <span className="text-blue-kewirus">Sign Up</span>
                </span>
              </button>
            </div>
          </div>

          <div className="sub-cont">
            {/* Image */}
            <div className="img">
              <div className="img-text m-up">
                <h2>New here?</h2>
                <p>Sign up and discover great amount of new opportunities!</p>
              </div>
              <div className="img-text m-in">
                <h2>One of us?</h2>
                <p>If you already has an account, just sign in. We have missed you!</p>
              </div>
              <div className="img-btn">
                <button
                  type="button"
                  onClick={this._handleClickSignUp}
                >
                  <span className="m-up">Sign Up</span>
                </button>
                <button
                  type="button"
                  onClick={this._handleClickSignIn}
                >
                  <span className="m-in">Sign In</span>
                </button>
              </div>
            </div>
            {/* SIGN UP */}
            {
              !this.state.isWaitingEmailConfirm && (
                <div className="form sign-up">
                  {/* Header */}
                  <div className="text-center">
                    <h1 className="text-center">Create an Account</h1>
                  </div>
                  <form className="container-form-signup mt-5" style={{ padding: '8px' }}>
                    <div className="row">
                      {/* Full Name */}
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="inputFullNameSignUp">Full Name</label>
                          <input
                            type="fullName"
                            // eslint-disable-next-line no-nested-ternary
                            className={`form-control ${this.state.isFullNameValidated
                              ? 'is-valid'
                              : _.isNull(this.state.isFullNameValidated)
                                ? ''
                                : 'is-invalid'
                            }`}
                            id="inputFullNameSignUp"
                            placeholder="Enter your name"
                            value={this.state.fullName}
                            onChange={(e) => this._handleInputTextChanged(e, 'fullName')}
                          />
                          {
                            !this.state.isFullNameValidated && (
                              <div className="invalid-feedback">
                                Please input fullname field
                              </div>
                            )
                          }
                        </div>
                      </div>
                      {/* Email Address */}
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="inputEmailSignUp">Email address</label>
                          <input
                            type="email"
                            // eslint-disable-next-line no-nested-ternary
                            className={`form-control ${this.state.isEmailValidated
                              ? 'is-valid'
                              : _.isNull(this.state.isEmailValidated)
                                ? ''
                                : 'is-invalid'
                            }`}
                            id="inputEmailSignUp"
                            aria-describedby="emailHelp"
                            placeholder="Enter email"
                            value={this.state.email}
                            onChange={(e) => this._handleInputTextChanged(e, 'email')}
                          />
                          {
                            !this.state.isEmailValidated && (
                              <div className="invalid-feedback">
                                Please enter correct email format
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* Phone Number */}
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="inputPhoneNumberSignUp">Phone Number</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text">+62</div>
                            </div>
                            <input
                              type="tel"
                              // eslint-disable-next-line no-nested-ternary
                              className={`form-control ${this.state.isPhoneValidated
                                ? 'is-valid'
                                : _.isNull(this.state.isPhoneValidated)
                                  ? ''
                                  : 'is-invalid'
                              }`}
                              id="inputPhoneNumberSignUp"
                              placeholder="Enter phone number"
                              value={this.state.phoneNumber}
                              onChange={(e) => this._handleInputTextChanged(e, 'phoneNumber')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="inputGenderSignUp">Gender</label>
                          <select
                            // eslint-disable-next-line no-nested-ternary
                            className={`form-control ${this.state.isGenderValidated
                              ? 'is-valid'
                              : _.isNull(this.state.isGenderValidated)
                                ? ''
                                : 'is-invalid'
                            }`}
                            id="inputGenderSignUp"
                            value={this.state.gender}
                            onChange={(e) => this._handleInputTextChanged(e, 'gender')}
                          >
                            <option disabled value="">Choose...</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                          {
                            !this.state.isGenderValidated && (
                              <div className="invalid-feedback">
                                Please choose your gender
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    {/* Last edu */}
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="inputLocationSignUp">Last Education</label>
                          <select
                            // eslint-disable-next-line no-nested-ternary
                            className={`form-control ${this.state.isLastEduValidated
                              ? 'is-valid'
                              : _.isNull(this.state.isLastEduValidated)
                                ? ''
                                : 'is-invalid'
                            }`}
                            id="inlineFormCustomSelect"
                            value={this.state.lastEducation}
                            onChange={(e) => this._handleInputTextChanged(e, 'lastEducation')}
                          >
                            <option disabled value="">Choose...</option>
                            <option value="SD">SD/Sederajat</option>
                            <option value="SMP">SMP/Sederajat</option>
                            <option value="SMA">SMA/SMK/MA/Sederajat</option>
                            <option value="D3">D3 - Diploma</option>
                            <option value="S1">S1 - Sarjana</option>
                            <option value="S2">S2 - Magister</option>
                            <option value="S3">S3 - Doctor</option>
                          </select>
                          {
                            !this.state.isLastEduValidated && (
                              <div className="invalid-feedback">
                                Please choose your last education
                              </div>
                            )
                          }
                        </div>
                      </div>
                      {/* last edu specific */}
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="inputInstitutionSignUp">Last Education Institution</label>
                          <input
                            type="text"
                            // eslint-disable-next-line no-nested-ternary
                            className={`form-control ${this.state.isInstitutionValidated
                              ? 'is-valid'
                              : _.isNull(this.state.isInstitutionValidated)
                                ? ''
                                : 'is-invalid'
                            }`}
                            id="inputInstitutionValidated"
                            placeholder="Enter you last education institution"
                            value={this.state.schoolName}
                            onChange={(e) => this._handleInputTextChanged(e, 'schoolName')}
                          />
                          {
                            !this.state.isInstitutionValidated && (
                              <div className="invalid-feedback">
                                Please enter this field
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    {/* Address */}
                    <div className="form-group">
                      <label htmlFor="inputPasswordSignUp">Address</label>
                      <textarea
                        // eslint-disable-next-line no-nested-ternary
                        className={`form-control ${this.state.isAddressValidated
                          ? 'is-valid'
                          : _.isNull(this.state.isAddressValidated)
                            ? ''
                            : 'is-invalid'
                        }`}
                        id="inputAddress"
                        placeholder="Enter your address"
                        value={this.state.address}
                        rows="5"
                        required
                        onChange={(e) => this._handleInputTextChanged(e, 'address')}
                      />
                      {
                        !this.state.isAddressValidated && (
                          <div className="invalid-feedback">
                            Please enter your address
                          </div>
                        )
                      }
                    </div>
                    {/* Select Province */}
                    <div className="form-group row">
                      <div className="col-12">
                        <label htmlFor="dropdownMenuProvinces">Select Province</label>
                        <select
                          // eslint-disable-next-line no-nested-ternary
                          className={`form-control ${this.state.isProvinceValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isProvinceValidated)
                              ? ''
                              : 'is-invalid'
                          }`}
                          value={this.state.provinceId}
                          onChange={(e) => {
                            const { value } = e.target;
                            const provinceId = value;
                            this.setState({
                              isProvinceValidated: true,
                              isCityValidated: false,
                              cityId: 0,
                              provinceId
                            });
                            this._getCitiesById(provinceId);
                          }}
                          aria-labelledby="dropdownMenuProvinces"
                        >
                          <option value={0} disabled>Choose province</option>
                          {_.map(this.state.provinces, (province) => (
                            <option value={_.get(province, 'province_id')}>{province.name}</option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          Please select province
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <label htmlFor="dropdownMenuCities">Select City</label>
                        <select
                          // eslint-disable-next-line no-nested-ternary
                          className={`form-control ${this.state.isCityValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isCityValidated)
                              ? ''
                              : 'is-invalid'
                          }`}
                          value={this.state.cityId}
                          aria-labelledby="dropdownMenuCities"
                          onChange={(e) => {
                            const { value } = e.target;
                            const cityId = value;
                            this.setState({
                              isCityValidated: true,
                              cityId
                            });
                          }}
                        >
                          <option value={0} disabled>Please select province first</option>
                          {_.map(this.state.cities, (city) => (
                            <option value={_.get(city, 'id')}>{city.name}</option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          Please select city
                        </div>
                      </div>
                    </div>
                    {/* Password */}
                    <div className="form-group">
                      <label htmlFor="inputPasswordSignUp">Password</label>
                      <div className="input-group">
                        <input
                          type={`${this.state.showPass ? 'text' : 'password'}`}
                          // eslint-disable-next-line no-nested-ternary
                          className={`form-control ${this.state.isPassValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isPassValidated)
                              ? ''
                              : 'is-invalid'
                          }`}
                          id="inputPasswordSignUp"
                          placeholder="Enter your password"
                          value={this.state.password}
                          onChange={(e) => this._handleInputTextChanged(e, 'password')}
                        />
                        <div
                          onClick={() => {
                            this.setState({
                            // eslint-disable-next-line react/no-unused-state
                              showPass: !_.get(this, 'state.showPass')
                            });
                          }}
                          className="input-group-append"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="input-group-text">
                            <i className={`fa ${!this.state.showPass ? 'fa-eye' : 'fa-eye-slash'}`} />
                          </span>
                        </div>
                        {
                          !this.state.isPassValidated && (
                            <div className="invalid-feedback">
                              Please enter password with minimal 8 characters
                            </div>
                          )
                        }
                      </div>
                    </div>
                    {/* Confirm Password */}
                    <div className="form-group">
                      <label htmlFor="inputConfirmPasswordSignUp">Confirm Password</label>
                      <div className="input-group">
                        <input
                          type={`${this.state.showConfirmPass ? 'text' : 'password'}`}
                          // eslint-disable-next-line no-nested-ternary
                          className={`form-control ${this.state.isConfirmPassValidated
                            ? 'is-valid'
                            : _.isNull(this.state.isConfirmPassValidated)
                              ? ''
                              : 'is-invalid'
                          }`}
                          id="inputConfirmPasswordSignUp"
                          placeholder="Re-enter your password"
                          value={this.state.confirmPass}
                          onChange={(e) => this._handleInputTextChanged(e, 'confirmPass')}
                        />
                        <div
                          onClick={() => {
                            this.setState({
                            // eslint-disable-next-line react/no-unused-state
                              showConfirmPass: !_.get(this, 'state.showConfirmPass')
                            });
                          }}
                          className="input-group-append"
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="input-group-text">
                            <i className={`fa ${!this.state.showConfirmPass ? 'fa-eye' : 'fa-eye-slash'}`} />
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Sign Up Button */}
                    {
                      this.state.isConfirmPassValidated
                  && this.state.isEmailValidated
                  && this.state.isPassValidated
                  && this.state.isPhoneValidated
                  && this.state.isFullNameValidated
                  && this.state.isLastEduValidated
                  && this.state.isProvinceValidated
                  && this.state.isCityValidated
                  && this.state.isInstitutionValidated
                  && this.state.isGenderValidated
                        ? (
                          <button type="button" className="submit mt-5" onClick={this._handleRegister}>Register Now</button>
                        ) : <button type="button" className="submit mt-5" disabled>Register Now</button>
                    }
                  </form>
                  <div className="row mx-0 mt-3 kewirus-mobile-only">
                    <button
                      className="col-12"
                      type="button"
                      onClick={this._handleClickSignIn}
                    >
                      <span className="text-dark">
                        Already have an account?
                        {' '}
                        <span className="text-blue-kewirus">Sign In</span>
                      </span>
                    </button>
                  </div>
                </div>
              )
            }
            {
              this.state.isWaitingEmailConfirm && (
                <div className="form sign-up">
                  <h1 className="mb-3 text-center">You are almost there!</h1>
                  <p>
                    Please verify your email, we have sent you an email to
                    {' '}
                    <b>{this.state.emailConfirm}</b>
                    <br />
                    <br />
                    Just click the
                    {' '}
                    <b>Activate</b>
                    {' '}
                    button in that email to complete your signup process.
                    If you don&quot;t see any email, you may need to check to your spam folder
                    or click
                    {' '}
                    <a href="#" onClick={this._handleResendVerifyEmail}>resend verify email</a>
                  </p>
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
