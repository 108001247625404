/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import './styles.scss';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import ApiAuth from '../../services/api/auth';
import ApiMeeting from '../../services/api/meeting';
import ApiEvent from '../../services/api/event';

import Logo from '../../assets/app-logo.png';
import UserLogo from '../../assets/user-no-image.png';

const us = JSON.parse(localStorage.getItem('kewirus_u'));
const photoUrl = _.get(us, 'photoUrl');

const NavBar = (props) => {
  const [active, setActive] = useState(0);
  const [user, setUser] = useState({});
  const [, setNotifBadge] = useState(0);

  // - check current url path
  // eslint-disable-next-line react/prop-types
  const { menus, location } = props;
  // eslint-disable-next-line react/prop-types
  const { pathname } = location;
  let rootPath = String(pathname).split('/')[1];
  rootPath = rootPath.substring(0, rootPath.length - 1);

  useEffect(() => {
    async function fetchUserInfo() {
      const { data } = await ApiAuth.getUser();
      const usr = data.data;

      await localStorage.setItem('kewirus_u', JSON.stringify(usr));
      setUser(usr);
    }

    async function fetchTotalSchedule() {
      const res = await ApiMeeting.getInvitations();
      const res2 = await ApiEvent.getMyEvents();
      let eventItems = _.get(res2, 'data.data.data');
      eventItems = _.chain(eventItems)
        .filter((it) => it.isCreatorEvent)
        .reduce((sum, curr) => sum + _.toLength(curr.eventAttendances), 0)
        .value();
      const items = _.get(res, 'data.data');

      const total = _.toLength(items) + eventItems;
      setNotifBadge(total);
    }

    if (props.title !== 'Published Projects') {
      const initialActive = _.head(
        menus
          .map((menu, idx) => (menu.url.includes(rootPath) ? idx : -1))
          .filter((val) => val > -1)
      );
      setActive(initialActive || 0);

      // - fetch user
      fetchUserInfo();
      fetchTotalSchedule();
    }
  }, [rootPath, menus, props.title]);
  const _handleLogout = async () => {
    await localStorage.removeItem('kewirus_t');
    await localStorage.removeItem('kewirus_u');

    window.location.reload();
  };

  // render badge
  // const renderBadge = (needRender) => {
  //   if (needRender) {
  //     return (
  //       <span className="badge badge-pill badge-primary pt-2 ml-2">{badge}</span>
  //     );
  //   }
  //   return null;
  // };

  return (
    <>
      { props.title === 'Published Projects'
      // Published Projects Navbar
        ? (
          <div className="container-navbar-kewirus align-self-center">
            <nav className="row mx-0 navbar navbar-expand-lg navbar-light">
              <a href="https://www.kewirus.com" className="align-self-center">
                <img
                  src={Logo}
                  alt="kewirus"
                />
              </a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    <a className="nav-link" href="https://www.kewirus.com">
                      <b className="mr-4">HOME</b>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#projects">
                      <b className="mr-4 active" style={{ color: '#21A7D0' }}>PROJECTS</b>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/courses">
                      <b className="mr-4">COURSES</b>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#blog">
                      <b className="mr-4">BLOG</b>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/login">
                      <b className="mr-4">REGISTER</b>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/login">
                      <b className="mr-3">LOGIN</b>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        ) : (
          <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm row px-4">
            <div className="col-auto">
              <img
                onError={(e) => { e.target.src = UserLogo; }}
                src={photoUrl || UserLogo}
                height="42px"
                width="42px"
                className="rounded-circle"
                alt="Cabin Crew Class"
                onClick={() => {
                // eslint-disable-next-line react/prop-types
                  props.history.push('/profile');
                }}
                style={{
                  objectFit: 'cover'
                }}
              />
            </div>
            <div className="col mx-auto">
              <div className="row d-flex align-items-center justify-content-center mx-auto">
                <div className="col-auto px-2">
                  <img
                    src={Logo}
                    height="20"
                    alt="Cabin Crew Class"
                    className="rounded-circle"
                    onClick={() => {
                    // eslint-disable-next-line react/prop-types
                      props.history.push('/login');
                    }}
                  />
                </div>
                <div className="col-auto px-0">
                  <b style={{ fontSize: '10px' }}>Cabin Crew Class</b>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>
            </div>
            <div className="collapse navbar-collapse px-4 pt-3" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                {
                  menus.map((menu, idx) => (
                    _.includes(menu.viewAccess, user.kewirusType) && (
                      <Link
                        key={menu.url}
                        to={`/${menu.url !== 'logout' ? menu.url : 'projects'}`}
                        onClick={() => {
                          if (menu.url === 'logout') _handleLogout();
                          else setActive(idx);
                        }}
                      >
                        <li className={`nav-item ${active === idx ? 'active' : ''}`}>
                          <span className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">
                            { menu.name }
                            {/* {renderBadge(menu.badge)} */}
                          </span>
                        </li>
                      </Link>
                    )
                  ))
                }
              </ul>
            </div>
          </nav>
        )}
    </>
  );
};

NavBar.propTypes = {
  title: PropTypes.string.isRequired,
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      viewAccess: PropTypes.array.isRequired
    }).isRequired
  ),
};

NavBar.defaultProps = {
  menus: []
};

export default withRouter(NavBar);
