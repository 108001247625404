import React from 'react';
import _ from 'lodash';

import ApiAuth from '../../services/api/auth';
import { Loading } from '../../components';

class RegisterVerificationCallback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isError: false
    };
  }

  componentDidMount() {
    const code = _.get(this.props, 'match.params.code');

    ApiAuth.verify(code)
      .then(() => {
        this.setState({
          loading: false,
          isError: false
        });

        setTimeout(() => {
          // eslint-disable-next-line react/prop-types
          this.props.history.replace('/login');
        }, 1000);
      })
      .catch(() => {
        this.setState({
          loading: false,
          isError: true
        });

        setTimeout(() => {
          // eslint-disable-next-line react/prop-types
          this.props.history.replace('/login');
        }, 3000);
      });
  }

  render() {
    const { loading, isError } = this.state;
    if (loading) {
      return (
        <div className="container d-flex align-items-center h-100vh">
          <Loading
            loading={loading}
          />
        </div>
      );
    }

    if (isError) {
      return (
        <div className="container d-flex h-100vh align-items-center">
          <div className="text-center mx-auto">
            <Loading loading />
            <h1 className="mx-auto mt-5">
              Verification code is not valid. We will redirect you to login page in 3 seconds
            </h1>
          </div>
        </div>
      );
    }

    return (
      <div className="container d-flex h-100vh align-items-center">
        <div className="text-center mx-auto">
          <Loading loading />
          <h1 className="mx-auto mt-5">
            Verification Success! Please sign in using your verified email
          </h1>
        </div>
      </div>
    );

  }
}

export default RegisterVerificationCallback;
