import axios from 'axios';
import Http from '../http';

const endpoint = 'http://proxy-server.herokuapp.com/https://maps.googleapis.com/maps/api/place/autocomplete/json';

export default {
  getCities(input) {
    return new Promise((resolve, reject) => {
      const instance = axios.create();

      instance.get(
        `${endpoint}?input=${input}&types=(cities)&components=country:id&key=AIzaSyABhgeXd-nO4_hixnhMlTQNA2TY19lOccE`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
  getProvinces() {
    return new Promise((resolve, reject) => {
      Http.get(
        'api/provinces'
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },
  getCitiesByProvinceId(id) {
    return new Promise((resolve, reject) => {
      Http.get(
        `api/cities/${id}`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  }
};
