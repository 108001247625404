import Http from '../http';

const endpointTrx = 'api/user/transaction';
const endpointSub = 'api/user/subscription';

export default {
  getSubcriptionPackages() {
    return new Promise((resolve, reject) => {
      Http.get(`${endpointSub}/packages`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getTransactionHistories(page = 1) {
    return new Promise((resolve, reject) => {
      Http.get(`${endpointTrx}/histories?page=${page}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  buyMaterial(slugs) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpointTrx}/material`,
        {
          materialSlugs: slugs
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  buyEvent(eventSlug) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpointTrx}/event`,
        {
          eventSlug
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  payMeetingInvitation(code) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpointTrx}/expert`,
        {
          code
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  subscribeMaterial(packageCode) {
    return new Promise((resolve, reject) => {
      Http.post(
        `${endpointTrx}/subscription`,
        {
          userPackageCode: packageCode
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  }
};
