import React from 'react';
import _ from 'lodash';
import parse from 'html-react-parser';

import './styles.scss';

import ApiProject from '../../services/api/project';

import {
  SearchBar,
  NavTabs,
  PageTitle,
  CardList,
  Modal,
} from '../../components';

import AppLogo from '../../assets/app-logo.png';

class Class extends React.Component {
  constructor(props) {
    super(props);
    const user = localStorage.getItem('kewirus_u');
    this.state = {
      user: JSON.parse(user),
      myProject: {
        page: 1,
        totalPage: 0,
        total: 0,
        isLoading: true,
      },
      sharedProject: {
        page: 1,
        totalPage: 0,
        total: 0,
        isLoading: true,
      },
      publishedProject: {
        page: 1,
        totalPage: 0,
        total: 0,
        isLoading: true,
      },
      sections: [
        {
          title: 'Class',
          items: _.range(0, 3),
          renderItems: (it) => this._renderMyProjects(it),
        },
        {
          title: 'Shared Classes',
          items: _.range(0, 3),
          renderItems: (it) => this._renderSharedProjects(it),
        }
      ],
      title: 'My Classes',
      selectedTab: 'myProject',

      modalDeleteProject: false,
      modalTitle: '',
      modalDeleteAction: '',
      selectedProjectToDelete: '',

      alertType: '',
      alertShow: false,
      alertMessage: '',

      query: '',
    };

    const role = _.get(this.state.user, 'kewirusType');
    if (role !== 'mentor') {
      this.state.sections.shift();
      this.state.selectedTab = 'sharedProject';
    }
  }

  componentDidMount() {
    const location = _.get(this.props, 'location');
    const history = _.get(this.props, 'history');
    if (!_.isNil(location.state)) {
      this.setState({
        alertMessage: location.state.alertMessage,
        alertType: location.state.alertType,
        alertShow: location.state.alertShow,
      });

      const idx = location.state.tabIndex;
      if (!_.isNil(idx)) {
        // click tab published
        if (idx === 2) {
          const elmt = document.getElementById('pills-Published-tab');
          elmt.click();
        }
      }

      history.replace(location.pathname, null);
    }

    const role = _.get(this.state.user, 'kewirusType');
    if (role === 'mentor') {
      this._getMyProjects();
    } else {
      this._getSharedProjects();
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.alertShow === true) {
      // eslint-disable-next-line react/no-did-update-set-state
      setTimeout(() => {
        this.setState({
          alertShow: false,
        });
      }, 3000);
    }
  }

  _getMyProjects = (query = '') => {
    // const page = _.get(this.state, 'myProject.page');
    this.setState((prevState) => ({
      myProject: {
        ...prevState.myProject,
        isLoading: true,
      },
    }));

    ApiProject.getProjects(query).then((res) => {
      const data = _.get(res, 'data.data');
      const { sections: oldSections } = this.state;
      const firstSection = _.head(oldSections);
      const sections = _.drop(oldSections, 1);

      firstSection.items = data;
      sections.unshift(firstSection);

      this.setState((prevState) => ({
        myProject: {
          ...prevState.myProject,
          total: data.length,
          isLoading: false,
        },
        sections,
      }));
    }).catch(() => {
      this._getMyProjects();
    });
  };

  _getSharedProjects = (query = '') => {
    this.setState((prevState) => ({
      myProject: {
        ...prevState.myProject,
        isLoading: true,
      },
    }));

    ApiProject.getSharedProjects(query).then((res) => {
      const data = _.get(res, 'data.data');
      let { sections: oldSections } = this.state;
      const role = _.get(this.state.user, 'kewirusType');
      const tempArray = [];

      // drop My Classes
      if (role === 'mentor') {
        const firstSection = _.head(oldSections);
        oldSections = _.drop(oldSections, 1);
        tempArray.push(firstSection);
      }

      // Shared classes
      const secondSection = _.head(oldSections);
      secondSection.items = data.map((_data) => {
        _.set(_data, 'projectType', 'shared');
        return _data;
      });
      oldSections = _.drop(oldSections, 1);
      tempArray.push(secondSection);

      // new sections
      const sections = tempArray.concat(oldSections);
      this.setState((prevState) => ({
        sharedProject: {
          ...prevState.sharedProject,
          total: data.length,
          isLoading: false,
        },
        sections,
      }));
    });
  };

  _getPublishedProjects = (query = '') => {
    const { publishedProject } = this.state;
    this.setState({
      publishedProject: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.publishedProject,
        isLoading: true,
      },
    });

    ApiProject.getMyPublishedProjects(publishedProject.page, query).then(
      (res) => {
        const { data } = res;
        const items = _.get(data, 'data.data');
        const meta = _.get(data, 'data.meta');
        const { last_page: totalPage, total } = meta;

        // - set items
        const { sections } = this.state;
        const lastIdx = sections.length - 1;
        sections[lastIdx].items = items;

        this.setState({
          publishedProject: {
            // eslint-disable-next-line react/no-access-state-in-setstate
            ...this.state.publishedProject,
            totalPage,
            total,
            isLoading: false,
          },
          sections,
        });
      }
    );
  };

  _handleAddProject = () => {
    const route = '/class/new';
    // eslint-disable-next-line react/prop-types
    this.props.history.push(route);
  };

  _handleSearch = ({ target }) => {
    const { value: query } = target;
    const { selectedTab } = this.state;
    this.setState({ query });

    // hit list api
    switch (selectedTab) {
    case 'myProject':
      this._getMyProjects(query);
      break;
    case 'sharedProject':
      this._getSharedProjects(query);
      break;
    default:
      this._getPublishedProjects(query);
      break;
    }
  };

  _handleDeleteProject = () => {
    // delete project
    const { selectedProjectToDelete: slug, modalDeleteAction } = this.state;

    if (modalDeleteAction === 'myproject') {
      ApiProject.deleteProjectBySlug(slug)
        .then((res) => {
          const { data } = res;
          const { message } = data;
          const msg = message.map((_msg) => `${_msg.message}`).join('');

          // eslint-disable-next-line react/prop-types
          this.setState({
            alertShow: true,
            alertMessage: `${msg}`,
            alertType: 'success',
            modalDeleteProject: false,
          });

          this._getMyProjects();
          this._getSharedProjects();
        })
        .catch((e) => {
          const { response } = e;
          const { message } = response.data;
          const msg = message
            .map((_msg) => `<li>${_msg.message} for field ${_msg.field}</li>`)
            .join('');

          this.setState({
            alertShow: true,
            alertMessage: `Failed to delete project. because of: </br>${msg}`,
            alertType: 'error',
            modalDeleteProject: false,
          });
        });
    } else {
      ApiProject.changePublishProjectStatusToDraft(slug)
        .then((res) => {
          const { data } = res;
          const { message } = data;
          const msg = message.map((_msg) => `${_msg.message}`).join('');

          // eslint-disable-next-line react/prop-types
          this.setState({
            alertShow: true,
            alertMessage: `${msg}`,
            alertType: 'success',
            modalDeleteProject: false,
          });

          this._getMyProjects();
          this._getSharedProjects();
        })
        .catch((e) => {
          const { response } = e;
          const { message } = response.data;
          const msg = message
            .map((_msg) => `<li>${_msg.message} for field ${_msg.field}</li>`)
            .join('');

          this.setState({
            alertShow: true,
            alertMessage: `Failed to make project as a draft. because of: </br>${msg}`,
            alertType: 'error',
            modalDeleteProject: false,
          });
        });
    }
  };

  _renderMyProjects = (it) => (
    <CardList
      {...this.props}
      items={it}
      type="project"
      isLoading={this.state.myProject.isLoading}
      page={this.state.myProject.page}
      totalPage={this.state.myProject.totalPage}
      onItemClick={(_e, item, actionType) => {
        _e.stopPropagation();
        if (actionType === 'publishAction') {
          // eslint-disable-next-line react/prop-types
          this.props.history.push({
            pathname: '/class/publish/new',
            state: {
              slug: _.get(item, 'slug'),
            },
          });
        } else if (actionType === 'viewAction') {
          // eslint-disable-next-line react/prop-types
          this.props.history.push({
            pathname: `/class/detail/${item.slug}`,
          });
        } else {
          this.setState({
            modalDeleteProject: true,
            modalTitle: 'Are you sure want to delete this class?',
            modalDeleteAction: 'myproject',
            selectedProjectToDelete: item.slug,
          });
        }
      }}
      onNextPage={() => {
        this.setState((prevState) => ({
          myProject: {
            ...prevState.myProject,
            page: prevState.myProject.page + 1,
          },
        }));
      }}
      onPageNumberChanged={(_page) => {
        this.setState((prevState) => ({
          myProject: {
            ...prevState.myProject,
            page: _page,
          },
        }));
      }}
      onPreviousPage={() => {
        this.setState((prevState) => ({
          myProject: {
            ...prevState.myProject,
            page: prevState.myProject.page - 1,
          },
        }));
      }}
    />
  );

  _renderDeleteProjectModal = () => (
    <Modal show={this.state.modalDeleteProject}>
      <div className="container mentor-modal mt-5 mb-4 mx-3">
        <div className="row">
          <h1 className="col mb-3">{this.state.modalTitle}</h1>
        </div>
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={() => {
                this.setState({ modalDeleteProject: false });
              }}
              className="btn m-up mt-4 col-12 border-blue-kewirus"
            >
              <b className="text-blue-kewirus">Cancel</b>
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              onClick={this._handleDeleteProject}
              className="mt-4 btn submit col-12 btn-kewirus"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );

  _renderSharedProjects = (it) => (
    <CardList
      {...this.props}
      items={it}
      type="project"
      isLoading={this.state.sharedProject.isLoading}
      page={this.state.sharedProject.page}
      totalPage={this.state.sharedProject.totalPage}
      onItemClick={(e, item, action) => {
        e.stopPropagation();

        if (_.isEqual(action, 'viewAction')) {
          // eslint-disable-next-line react/prop-types
          this.props.history.push({
            pathname: `/class/detail/${item.slug}`,
            state: {
              name: item.name,
            },
          });
        } else {
          this.setState({
            modalDeleteProject: true,
            modalTitle: 'Are you sure want to make project as a draft project?',
            modalDeleteAction: 'myproject',
            selectedProjectToDelete: item.slug,
          });
        }
      }}
      onNextPage={() => {
        this.setState((prevState) => ({
          sharedProject: {
            ...prevState.sharedProject,
            page: prevState.sharedProject.page + 1,
          },
        }));
      }}
      onPageNumberChanged={(_page) => {
        this.setState((prevState) => ({
          sharedProject: {
            ...prevState.sharedProject,
            page: _page,
          },
        }));
      }}
      onPreviousPage={() => {
        this.setState((prevState) => ({
          sharedProject: {
            ...prevState.sharedProject,
            page: prevState.sharedProject.page - 1,
          },
        }));
      }}
    />
  );

  _renderPublishedProjects = (it) => (
    <CardList
      {...this.props}
      items={it}
      type="published-project"
      isLoading={this.state.publishedProject.isLoading}
      page={this.state.publishedProject.page}
      onItemClick={(_e, { slug }, action) => {
        const isDelete = _.isEqual(action, 'delete');
        const isEdit = _.isEqual(action, 'edit');

        _e.stopPropagation();
        if (isDelete) {
          this.setState({
            modalDeleteProject: true,
            modalDeleteAction: 'publishedproject',
            selectedProjectToDelete: slug,
            modalTitle: 'Are you sure want to make project as a draft project?',
          });

          this._getPublishedProjects();
        } else if (isEdit) {
          // eslint-disable-next-line react/prop-types
          this.props.history.push({
            pathname: '/class/publish/edit',
            state: {
              slug,
            },
          });
        } else {
          // eslint-disable-next-line react/prop-types
          this.props.history.push(`/published-projects/detail/${slug}`);
        }
      }}
      totalPage={this.state.publishedProject.totalPage}
      onNextPage={() => {
        this.setState((prevState) => ({
          publishedProject: {
            ...prevState.publishedProject,
            page: prevState.publishedProject.page + 1,
          },
        }));
      }}
      onPageNumberChanged={(_page) => {
        this.setState((prevState) => ({
          publishedProject: {
            ...prevState.publishedProject,
            page: _page,
          },
        }));
      }}
      onPreviousPage={() => {
        this.setState((prevState) => ({
          publishedProject: {
            ...prevState.publishedProject,
            page: prevState.publishedProject.page - 1,
          },
        }));
      }}
    />
  );

  render() {
    const { sections, selectedTab } = this.state;

    return (
      <div className="col container-page-kewirus">
        {this.state.alertShow && (
          <div
            className={`alert ${
              _.isEqual(this.state.alertType, 'error')
                ? 'alert-danger'
                : 'alert-success'
            } alert-dismissible fade show`}
            role="alert"
            style={{
              position: 'fixed',
              top: '10%',
              zIndex: '10',
            }}
          >
            <p>{parse(this.state.alertMessage)}</p>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => this.setState({ alertMessage: '', alertShow: false })}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        {/* modal */}
        {this._renderDeleteProjectModal()}
        {/* Search Bar and Logo */}
        <div className="row">
          {/* Search Bar */}
          <div className="kewirus-desktop-only col-6 mb-5">
            <SearchBar onSearch={this._handleSearch} />
          </div>
          {/* Logo */}
          <a href="/" className="kewirus-desktop-only col-auto mb-5 ml-auto">
            <img
              src={AppLogo}
              className="app-logo"
              alt="Cabin Crew Class Logo"
            />
          </a>
        </div>

        {/* Title */}
        <div className="row mx-0">
          <PageTitle
            title={this.state.title}
            subtitle={`${this.state[selectedTab].total} classes`}
          />
        </div>

        {/* Search Bar Mobile Only */}
        <div className="row kewirus-mobile-only">
          {/* Search Bar */}
          <div className="col-12 mt-3 mb-4">
            <SearchBar onSearch={this._handleSearch} />
          </div>
        </div>

        {/* Nav Tabs and Button Add Classes */}
        <div className="row">
          {/* Nav Tabs */}
          <div className="col-12">
            <NavTabs
              sections={(() => {
                const kewirusType = _.get(this, 'state.user.kewirusType');
                if (kewirusType !== 'institution') {
                  return sections;
                }

                return sections.filter(
                  (section) => section.title !== 'Shared Classes'
                );
              })()}
              onTabClick={(_e, idx) => {
                const { query } = this.state;
                let tab = '';
                let title;
                switch (idx) {
                case 0:
                  tab = 'myProject';
                  title = 'My Classes';
                  this._getMyProjects(query);
                  break;
                case 1:
                  // eslint-disable-next-line no-case-declarations
                  const kewirusType = _.get(this, 'state.user.kewirusType');
                  if (kewirusType !== 'institution') {
                    title = 'Shared Classes';
                    tab = 'sharedProject';
                    this._getSharedProjects(query);
                  } else {
                    tab = 'publishedProject';
                    title = 'Published Projects';
                    this._getPublishedProjects(query);
                  }
                  break;
                case 2:
                  tab = 'publishedProject';
                  title = 'Published Projects';
                  this._getPublishedProjects(query);
                  break;
                default:
                  break;
                }

                this.setState({
                  selectedTab: tab,
                  title,
                });
              }}
            />
          </div>
          {/* Button */}
          {_.get(this, 'state.user.kewirusType') === 'mentor' && (
            <button
              type="button"
              className="btn btn-kewirus btn-add-new"
              onClick={this._handleAddProject}
            >
              + Add Class
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default Class;
