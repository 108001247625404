/* eslint-disable camelcase */
import Http from '../http';

const endpoint = 'api/user/event';

export default {
  getEvents(page = 1, query = '', limit = 3, orderBy = 'startedAt', descending = false) {
    return new Promise((resolve, reject) => {
      Http.get(
        `${endpoint}s?page=${page}&limit=${limit}&orderBy=${orderBy}&descending=${descending ? 1 : 0}&query=${query}`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getMyEvents(page = 1, query = '', limit = 3, orderBy = 'startedAt', descending = false) {
    return new Promise((resolve, reject) => {
      Http.get(
        `${endpoint}s/me?page=${page}&limit=${limit}&orderBy=${orderBy}&descending=${descending ? 1 : 0}&query=${query}`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  getEventDetail(slug) {
    return new Promise((resolve, reject) => {
      Http.get(
        `${endpoint}/${slug}`
      ).then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  createEvent({
    name,
    thumbnail,
    creatorEmail,
    description,
    price,
    quota,
    start_date,
    start_time,
    start_offset,
    end_date,
    end_time,
    end_offset
  }) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('creator_email', creatorEmail);
    formData.append('thumbnail', thumbnail);
    formData.append('price', price);
    formData.append('quota', quota);
    formData.append('start_date', start_date);
    formData.append('start_time', start_time);
    formData.append('start_offset', start_offset);
    formData.append('end_date', end_date);
    formData.append('end_time', end_time);
    formData.append('end_offset', end_offset);

    return new Promise((resolve, reject) => {
      Http.post(
        `${endpoint}`,
        formData,
        null,
        null,
        {
          'Content-Type': 'multipart/form-data'
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  editEvent({
    name,
    thumbnail,
    description,
    price,
    quota,
    start_date,
    start_time,
    start_offset,
    end_date,
    end_time,
    end_offset
  }, slug) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('creator_email', 'johndoe@example.com');
    formData.append('description', description);
    formData.append('price', price);
    formData.append('quota', quota);
    formData.append('start_date', start_date);
    formData.append('start_time', start_time);
    formData.append('start_offset', start_offset);
    formData.append('end_date', end_date);
    formData.append('end_time', end_time);
    formData.append('end_offset', end_offset);
    if (thumbnail instanceof Blob) {
      formData.append('thumbnail', thumbnail);
    }

    return new Promise((resolve, reject) => {
      Http.patch(
        `${endpoint}/${slug}`,
        formData,
        null,
        null,
        {
          'Content-Type': 'multipart/form-data'
        }
      )
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  attendEvent(slug) {
    return new Promise((resolve, reject) => {
      Http.patch(`${endpoint}/attend/${slug}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  },

  deleteEvent(slug) {
    return new Promise((resolve, reject) => {
      Http.delete(`${endpoint}/${slug}`)
        .then((res) => resolve(res))
        .catch((e) => reject(e));
    });
  }
};
