/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import './App.scss';
import {
  Switch, BrowserRouter, Route, Redirect
} from 'react-router-dom';

import {
  ClassDetail,
  Profile,
  Class,
  CoursesCategory,
  Courses,
  CourseDetail,
  Events,
  EventDetail,
  Experts,
  Schedules,
  Login,
  MentorRegister,
  GuestRegister,
  ResetPassword,
  CreateEditEvent,
  CreateEditClass,
  InviteMemberCallback,
  InviteMentorCallback,
  RegisterVerificationCallback,
  NotFound,
  Dashboard,
} from './pages';

import {
  PrivateRoute, PublicRoute,
} from './components';
import { isLogin } from './utils';

const App = () => (
  <div className="App">
    <div className="row mx-0">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={() => <Redirect to="/login" />} />

          {/* Callbacks */}
          <Route exact path="/user/project-member/invitation/confirm/:code" component={InviteMemberCallback} />
          <Route exact path="/user/project-mentor/invitation/confirm/:code" component={InviteMentorCallback} />
          <Route // callback when guest user is going to register on our page
            exact
            path="/user/project-member/invitation/register/:code"
            component={(props) => {
              const params = _.get(props, 'match.params');
              const { code } = params;
              const qp = new URLSearchParams(_.get(props, 'location.search'));
              const email = qp.get('email');

              if (_.isNil(code) || _.isNil(email)) {
                return <Redirect to="" />;
              }

              return <Redirect to={`/guest/register?code=${code}&email=${email}`} />;
            }}
          />
          <Route exact path="/auth/email-verification/:code" component={RegisterVerificationCallback} />
          <Route exact path="/auth/register" component={() => <Redirect to="/login" />} />
          <Route exact path="/auth/login" component={() => <Redirect to="/login" />} />
          <Route
            exact
            path="/auth/reset-password/:code"
            component={(props) => {
              if (isLogin()) {
                return <Redirect to={`/profile?resetpass=true&code=${props.match.params.code}`} />;
              }
              return <Redirect to={`/reset-password?code=${props.match.params.code}`} />;
            }}
          />
          <Route
            exact
            path="/auth/register-mentor"
            component={(props) => {
              const qp = new URLSearchParams(_.get(props, 'location.search'));
              const code = qp.get('code');
              const email = qp.get('email');

              if (_.isNil(code) || _.isNil(email)) {
                return <Redirect to="" />;
              }

              return <Redirect to={`/mentor/register?code=${code}&email=${email}`} />;
            }}
          />

          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute exact path="/mentor/register" component={MentorRegister} />
          <PublicRoute exact path="/guest/register" component={GuestRegister} />
          <PublicRoute exact path="/reset-password" component={ResetPassword} />

          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/class" component={Class} />
          <PrivateRoute exact path="/class/detail/:slug" component={ClassDetail} />
          <PrivateRoute exact path="/class/new" component={CreateEditClass} />
          <PrivateRoute exact path="/class/edit/:slug" component={CreateEditClass} />
          <PrivateRoute exact path="/courses" component={CoursesCategory} />
          <PrivateRoute exact path="/courses/browse/:categorySlug" component={Courses} />
          <PrivateRoute exact path="/course/detail/:slug" component={CourseDetail} />
          <PrivateRoute exact path="/events" component={Events} />
          <PrivateRoute exact path="/event/detail/:slug" component={EventDetail} />
          <PrivateRoute exact path="/event/new" component={CreateEditEvent} />
          <PrivateRoute exact path="/event/edit/:slug" component={CreateEditEvent} />
          <PrivateRoute exact path="/experts" component={Experts} />
          <PrivateRoute exact path="/schedule" component={Schedules} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />

          <Route exact path="/404-not-found" component={NotFound} />
          <Redirect from="*" to="/404-not-found" />
        </Switch>
      </BrowserRouter>
    </div>
  </div>
);

export default App;
