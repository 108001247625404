/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React from 'react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import mime from 'mime-types';
import moment from 'moment';
import parse from 'html-react-parser';

import { Link } from 'react-router-dom';
import ApiProject from '../../services/api/project';
import ApiExpert from '../../services/api/expert';
import ApiFile from '../../services/api/file';

import './styles.scss';
import AppLogo from '../../assets/app-logo.png';
import WebAppLogo from '../../assets/logo.png';
import InstagramIcon from '../../assets/instagram.png';
import LinkedinIcon from '../../assets/linkedin.png';
import UserImage from '../../assets/user-no-image.png';
// import UserThumbnail from '../../assets/user-profile-2.png';
import NoImageThumbnail from '../../assets/project-no-image.png';

import roles from './role.json';

import {
  PageTitle,
  ProjectStep,
  ProjectTeam,
  NavTabs,
  Modal,
} from '../../components';
import { formatCurrency, validateEmail, validateStringLen } from '../../utils';

class ClassDetail extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('kewirus_u'));

    this.state = {
      user,
      name: '', // project name
      slug: '',
      description: '',
      imageBase64: null,
      institution: null,
      thumbnail: '',
      thumbnailEdit: null,
      attachment: {}, // for save temporary section items file
      submissions: {}, // for save submission files
      members: 0,
      sections: _.map([], (item) => ({
        title: item.name,
        items: [item],
        renderItems: (it) => this._renderContent(it),
      })),
      stepSectionSelectedTab: 0,
      stepSections: [
        {
          title: 'Instructions',
          items: [_.range(0, 0)],
          renderItems: (it) => this._renderInstructions(it[0]),
        },
        {
          title: 'Discussion',
          items: ['Discussion'],
          renderItems: () => this._renderDiscussion(),
        },
        // {
        //   title: 'Submission',
        //   items: ['Submission'],
        //   renderItems: () => this._renderSubmission(),
        // },
      ],

      // modal state
      modalAddMember: false,
      modalDeleteSubmission: false,
      modalDeleteMember: false,
      selectedMemberToDelete: '',
      selectedSubmission: {},
      member: {
        role: '',
        email: '',
        access: 'read',
      },
      answer: {}, // answer of section item
      comment: {},
      isMemberEmailValidated: null,
      isMemberRoleValidated: null,
      isMemberAccessValidated: true,
      isCommentDiscussionValidated: false,

      // meet modal state
      mentors: [],
      showMeetModal: false,
      isMeetingStartDateValidated: null,
      isMeetingEndDateValidated: null,
      isNotesValidated: null,
      isMentorSelected: null,
      requestMeet: {},
      minStartDate: moment().toDate(),

      // alert state
      alertShow: false,
      alertMessage: '',
      alertType: 'error',

      // flag to indicate editing project
      isEditingProject: false,
      hasEditAccess: false,
      edit: {
        title: '',
        description: '',
      },

      currentPath: '',
    };
  }

  componentDidMount() {
    const slug = _.get(this.props, 'match.params.slug');

    this._getProjectBySlug(slug);
    // - get steps by project slug
    this._getProjectSteps(slug);
    this._getProjectMentors(slug);
    this._getExpert();

    const location = _.get(this.props, 'location');
    const history = _.get(this.props, 'history');
    const pathname = _.get(location, 'pathname');
    if (!_.isNil(location.state)) {
      this.setState({
        alertMessage: location.state.alertMessage,
        alertType: location.state.alertType,
        alertShow: location.state.alertShow,
      });

      history.replace(location.pathname, null);
    }

    this.setState({ currentPath: pathname });
  }

  componentDidUpdate(_prevProps, prevState) {
    if (
      prevState.stepSectionSelectedTab !== this.state.stepSectionSelectedTab
      && this.state.stepSectionSelectedTab > 0
    ) {
      const stepSlug = _.get(
        this,
        `state.sections[${this.state.stepSectionSelectedTab}].items[0].slug`
      );
      const {
        submissions, submissionDeadline, submissionStatus, discussions
      } = _.get(
        this,
        `state.sections[${this.state.stepSectionSelectedTab}].items[0]`
      );
      const submission = {};
      Object.assign(submission, {
        submissions,
        submissionDeadline,
        submissionStatus,
      });

      // - hit api section step
      this._getProjectSectionsFromStep(stepSlug, submission, discussions);
    }
  }

  _getProjectBySlug = (slug) => {
    ApiProject.getProjectBySlug(slug).then((res) => {
      const data = _.get(res, 'data.data');
      const {
        description, name, thumbnail, institution
      } = data;
      this.setState({
        description,
        name,
        thumbnail,
        institution,
      });
    });
  };

  _getExpert = () => {
    // - hit api experts
    ApiExpert.getExperts().then((res) => {
      const data = _.get(res, 'data.data');
      let { mentors } = this.state;
      mentors = mentors.concat(data);

      this.setState({
        mentors,
      });
    });
  };

  _getProjectMentors = (slug) => {
    ApiProject.getProjectMentors(slug).then((res) => {
      const data = _.get(res, 'data.data');
      let { mentors } = this.state;
      mentors = mentors.concat(data);
      this.setState({
        mentors,
      });
    });
  };

  _getProjectSteps = (slug) => {
    ApiProject.getProjectStep(slug).then(async (res) => {
      const data = _.get(res, 'data.data');
      const {
        submissions, submissionDeadline, submissionStatus, discussions
      } = _.get(data, `[${this.state.stepSectionSelectedTab}]`);
      const submission = {};
      Object.assign(submission, {
        submissions,
        submissionDeadline,
        submissionStatus,
      });

      const stepSlug = _.get(
        data,
        `[${this.state.stepSectionSelectedTab}].slug`
      );
      // get sections in first step
      await this._getProjectSectionsFromStep(stepSlug, submission, discussions);

      this.setState(
        (prevState) => ({
          ...prevState,
          slug,
          sections: _.map(data, (item) => ({
            title: item.name,
            items: [item],
            renderItems: (it) => this._renderContent(it),
          })),
        }),
        () => this._getProjectMembers(slug)
      );
    });
  };

  _addMember = () => {
    const { member, slug } = this.state;
    ApiProject.inviteMember({
      ...member,
      slug,
    })
      .then(() => {
        this.setState({
          alertShow: true,
          alertType: 'success',
          alertMessage: `Success invite new member with email: ${member.email}`,
          modalAddMember: false,
          member: {
            role: '',
            email: '',
            access: 'read',
          },
          isMemberEmailValidated: null,
          isMemberRoleValidated: null,
          isMemberAccessValidated: true,
        });
      })
      .catch((e) => {
        const { response } = e;
        const { message, status } = response.data;
        const msg = message.map((_msg) => `<li>${_msg.message}</li>`).join('');

        if (status === 200) {
          this.setState({
            alertShow: true,
            alertType: 'success',
            alertMessage: `Success invite new member with email: ${member.email}`,
            modalAddMember: false,
            member: {
              role: '',
              email: '',
              access: 'read',
            },
            isMemberEmailValidated: null,
            isMemberRoleValidated: null,
            isMemberAccessValidated: true,
          });
        } else {
          this.setState({
            alertShow: true,
            alertType: 'error',
            alertMessage: `Failed to invite new member. because of: <br />${msg}`,
          });
        }
      });
  };

  _getProjectMembers = (slug) => {
    ApiProject.getProjectMembers(slug).then((res) => {
      const data = _.get(res, 'data.data');
      const hasEditAccess = !_.isEmpty(
        _.chain(data)
          .filter(
            (member) => member.role === 'owner' || member.access === 'admin'
          )
          .value()
      );
      const userAccess = _.chain(data)
        .filter((member) => member.email === this.state.user.email)
        .get('[0].access')
        .value();
      const hasEditAnswerAccess = _.includes(
        ['read-write', 'admin'],
        userAccess
      );

      this.setState((prevState) => ({
        ...prevState,
        hasEditAccess,
        hasEditAnswerAccess,
        members: data.length,
        sections: [
          {
            title: 'Overview',
            items: [
              {
                name: 'Overview',
                members: data,
              },
            ],
            renderItems: (it) => this._renderContent(it),
          },
          ...prevState.sections,
        ],
      }));
    });
  };

  _getProjectSectionsFromStep = async (stepSlug, submission, discussions) => {
    const res = await ApiProject.getProjectSection(stepSlug);
    const data = _.get(res, 'data.data');

    const changeStepSectionState = (prevState) => ({
      ...prevState,
      stepSections: [
        {
          title: 'Instructions',
          items: [data],
          renderItems: (it) => this._renderInstructions(it[0]),
        },
        {
          title: 'Discussion',
          items: [discussions],
          renderItems: (it) => this._renderDiscussion(it[0]),
        },
        // {
        //   title: 'Submission',
        //   items: [submission],
        //   renderItems: (it) => this._renderSubmission(it[0]),
        // },
      ],
    });
    this.setState(changeStepSectionState);
  };

  _handleCommentOrLike = (_e, discussionId, actionType = 'comment') => {
    const stepSlug = _.get(
      this,
      `state.sections[${this.state.stepSectionSelectedTab}].items[0].slug`
    );
    const projectSlug = _.get(this, 'state.slug');
    const user = _.get(this, 'state.user');
    const prop = discussionId === 0 ? 'root' : discussionId;
    const comment = _.get(this, `state.comment[${prop}]`);

    const data = {
      projectSlug,
      discussionId,
      comment: comment || ' ',
      projectStepSlug: stepSlug.split('_')[1],
    };

    ApiProject.submitDiscussion(actionType, data)
      .then(() => {
        this.setState({
          alertMessage: `${
            actionType[0].toUpperCase() + actionType.slice(1)
          } added`,
          alertType: 'success',
          alertShow: true,
          comment: {
            root: '',
          },
        });

        const stepSections = _.get(this, 'state.stepSections');
        const discussionIdx = 1;
        const discussionTab = stepSections[discussionIdx];
        const discussions = _.get(discussionTab, 'items[0]');
        if (actionType === 'comment') {
          const lastId = _.get(_.last(discussions), 'id', 0) || 0;
          const discussion = {
            comment,
            like: 0,
            projectSlug,
            createdAt: moment().toISOString(),
            user: {
              name: user.displayName,
              image: user.photoUrl,
              email: user.email,
              photoUrl: user.photoUrl,
            },
            id: lastId + 1,
          };
          discussions.push(discussion);
        } else {
          const selectedDiscussion = _.head(
            _.filter(discussions, (d) => d.id === discussionId)
          );
          selectedDiscussion.like += 1;
        }

        // replace old
        stepSections[discussionIdx] = discussionTab;

        this.setState({
          stepSections,
        });
      })
      .catch((e) => {
        const { response } = e;
        const { message } = response.data;
        const msg = message.map((_msg) => `<li>${_msg.message}</li>`).join('');

        this.setState({
          alertShow: true,
          alertType: 'error',
          alertMessage: `Failed to comment or like. because of: <br />${msg}`,
        });
      });
  };

  _handleCloseModal = () => {
    this.setState({
      modalAddMember: false,
      showMeetModal: false,
      member: {
        role: '',
        email: '',
        access: 'read',
      },
      isMemberEmailValidated: null,
      isMemberRoleValidated: null,
      isMemberAccessValidated: null,
    });
  };

  _handleInviteMentor = () => {
    const { requestMeet, slug } = this.state;
    const startDate = _.get(requestMeet, 'start_date');
    const endDate = _.get(requestMeet, 'end_date');
    const { email } = _.get(requestMeet, 'mentor');
    const inv = _.omit(requestMeet, ['mentor']);
    _.set(inv, 'email', email);
    _.set(inv, 'projectSlug', slug);

    const filteredInv = {
      ...inv,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      startTime: moment(startDate).format('HH:mm'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
      endTime: moment(endDate).format('HH:mm'),
    };

    ApiProject.inviteMeetMentor(filteredInv)
      .then((res) => {
        const { data } = res;
        const { message } = data;
        const msg = message.map((_msg) => `${_msg.message}`).join('');

        this.setState({
          alertShow: true,
          alertMessage: `${msg}`,
          alertType: 'success',
          showMeetModal: false,
        });

        setTimeout(() => {
          // eslint-disable-next-line react/prop-types
          this.props.history.replace('/schedule');
        }, 2000);
      })
      .catch((e) => {
        const { response } = e;
        const { message } = response.data;
        const msg = message
          .map((_msg) => `<li>${_msg.message} for field ${_msg.field}</li>`)
          .join('');

        this.setState({
          alertShow: true,
          alertMessage: `Failed to invite mentor. because of: </br>${msg}`,
          alertType: 'error',
        });
      });
  };

  _handleChooseFile = (code) => {
    this[`uploader${code}`].click();
  };

  _onChangeFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // get file
    const code = _.get(e, 'target.id');
    const file = _.get(e, 'target.files[0]');
    if (_.isNil(file)) return;

    // - set state
    this.setState({
      attachment: {
        [code]: file,
      },
    });
  };

  _onChangeFileProjectDetail = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // get image file
    const file = _.get(e, 'target.files[0]');
    if (_.isNil(file)) return;

    // read file blob and convert to base64
    const reader = new FileReader();
    reader.onload = (_e) => {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        imageBase64: _.get(_e, 'target.result'),
      });
    };
    reader.readAsDataURL(file);

    this.setState({
      thumbnailEdit: file,
    });
  };

  _onChangeSubmissionFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // get files
    const code = _.get(
      this,
      `state.sections[${this.state.stepSectionSelectedTab}].items[0].slug`
    );
    const files = _.get(e, 'target.files');
    if (_.isEmpty(files)) return;

    // - set attachments
    this.setState({
      submissions: {
        [code]: files,
      },
    });
  };

  _handleSubmitFile = async () => {
    const { slug, submissions } = this.state;
    const stepSlug = _.get(
      this,
      `state.sections[${this.state.stepSectionSelectedTab}].items[0].slug`
    );
    const documentsTypes = ['pdf', 'pptx', 'docx', 'ppt', 'doc'];
    const imageTypes = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'svg', 'SVG'];

    try {
      const files = await Promise.all(
        _.map(submissions[stepSlug], async (submission) => {
          const { type } = submission;
          const ext = mime.extension(type);
          const isImage = _.includes(imageTypes, ext);
          const isDoc = _.includes(documentsTypes, ext);
          let res;
          // - upload document / image
          if (isDoc) res = await ApiFile.uploadFile(submission, 'document');
          else if (isImage) res = await ApiFile.uploadFile(submission, 'image');

          return {
            filename: _.get(res, 'data.data'),
            type: isDoc ? 'document' : 'image',
          };
        })
      );

      // - submit document
      ApiProject.submitProjectStep(slug, stepSlug, files)
        .then((res) => {
          const alertMessage = _.get(res, 'data.message[0].message');
          this.setState({
            alertMessage,
            alertType: 'success',
            alertShow: true,
          });

          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((e) => {
          const message = _.get(e, 'response.data.message');
          const status = _.get(e, 'response.data.status');
          let alertMsg = message.map((msg) => `<li>${msg.message}</li>`);
          alertMsg = alertMsg.join('');

          if (status !== 200) {
            this.setState({
              alertMessage: `Failed to submit file: ${`${alertMsg}`}`,
              alertShow: true,
              alertType: 'error',
            });
          } else {
            this.setState({
              alertMessage: _.get(message, '[0].message'),
              alertType: 'success',
              alertShow: true,
            });
            const stepSections = _.get(this, 'state.stepSections');
            const lastIdx = stepSections.length - 1;
            const submissionTab = _.last(stepSections);
            const _submissions = _.get(submissionTab, 'items[0].submissions');
            const lastSubmission = _.last(_submissions);

            _.map(files, (file) => {
              _submissions.push({
                id: (_.get(lastSubmission, 'id', 0) || 0) + 1,
                filename: `${file.filename}`,
              });
            });

            submissionTab.items = [
              {
                ...submissionTab.items[0],
                submissions: _submissions,
              },
            ];
            // insert new submissions tab
            stepSections[lastIdx] = submissionTab;

            this.setState({
              submissions: _submissions,
            });
          }
        });
    } catch (e) {
      let alertMsg = _.get(e, 'response.data.message', 'response.message');
      if (_.isArray(alertMsg)) {
        alertMsg = alertMsg.map((msg) => `<li>${msg.message}</li>`);
        alertMsg = alertMsg.join('');
      }

      this.setState({
        alertMessage: `Failed to submit file: ${`${alertMsg}`}`,
        alertShow: true,
        alertType: 'error',
      });
    }
  };

  _handleDeleteSubmission = () => {
    const { selectedSubmission } = this.state;
    const { id, project_slug: slug } = selectedSubmission;
    ApiProject.deleteSubmission(id, slug)
      .then(() => {})
      .catch((e) => {
        const status = _.get(e, 'response.data.status');
        let alertMsg = _.get(e, 'response.data.message');
        alertMsg = alertMsg.map((msg) => `<li>${msg.message}</li>`);
        alertMsg = alertMsg.join('');
        const stepSections = _.get(this, 'state.stepSections');
        const lastIdx = stepSections.length - 1;
        const submissionTab = _.last(stepSections);
        const submissions = _.get(submissionTab, 'items[0].submissions');
        submissionTab.items = [
          {
            ...submissionTab.items[0],
            submissions: _.filter(submissions, (item) => item.id !== id),
          },
        ];

        // insert new submissions tab
        stepSections[lastIdx] = submissionTab;

        this.setState({
          alertMessage:
            status !== 200
              ? `Failed to delete submission: ${`${alertMsg}`}`
              : alertMsg,
          alertShow: true,
          alertType: status === 200 ? 'success' : 'error',
          modalDeleteSubmission: false,
          stepSections,
        });
      });
  };

  _handleUpdateProject = () => {
    const { edit, slug, thumbnailEdit: thumbnail } = this.state;

    ApiProject.updateProject({
      name: edit.title,
      description: edit.description,
      slug,
      thumbnail,
      institutionCode: _.get(edit, 'institution.code'),
    })
      .then((res) => {
        const alertMessage = _.get(res, 'data.message[0].message');
        this.setState({
          alertMessage,
          alertType: 'success',
          alertShow: true,
          isEditingProject: false,
          thumbnailEdit: null,
          imageBase64: null,
        });
        // - reload data
        this._getProjectBySlug(slug);
      })
      .catch((e) => {
        let alertMsg = _.get(e, 'response.data.message');
        alertMsg = alertMsg.map((msg) => `<li>${msg.message}</li>`);
        alertMsg = alertMsg.join('');

        this.setState({
          alertMessage: `Failed to edit project: ${`${alertMsg}`}`,
          alertShow: true,
          alertType: 'error',
        });
      });
  };

  _handleDeleteProjectMember = () => {
    const { selectedMemberToDelete: email, slug } = this.state;
    ApiProject.deleteProjectMember(slug, email)
      .then((res) => {
        const alertMessage = _.get(res, 'data.message[0].message');
        this.setState({
          alertMessage,
          alertType: 'success',
          alertShow: true,
          modalDeleteMember: false,
        });

        this._getProjectMembers(slug);
      })
      .catch((e) => {
        let alertMsg = _.get(e, 'response.data.message');
        alertMsg = alertMsg.map((msg) => `<li>${msg.message}</li>`);
        alertMsg = alertMsg.join('');

        this.setState({
          alertMessage: `Failed to remove project member: ${`${alertMsg}`}`,
          alertShow: true,
          alertType: 'error',
        });
      });
  };

  _handleSaveAnswer = async (code, type) => {
    const projectStepSlug = _.get(
      this,
      `state.sections[${this.state.stepSectionSelectedTab}].items[0].slug`
    );
    let data = {};

    if (_.isEqual(type, 'fileUpload')) {
      const attachment = _.get(this, `state.attachment.${code}`);
      try {
        // - upload file
        const isImage = attachment.type.indexOf('image') > -1;
        const res = await ApiFile.uploadFile(
          attachment,
          isImage ? 'image' : 'document'
        );
        // - answer section item
        const fileUrl = _.get(res, 'data.data');
        data = {
          answer: fileUrl,
          sectionItemCode: code,
        };
      } catch (e) {
        let alertMsg = _.get(e, 'response.data.message');
        alertMsg = alertMsg.map((msg) => `<li>${msg.message}</li>`);
        alertMsg = alertMsg.join('');

        this.setState({
          alertMessage: `Failed to answer: ${`${alertMsg}`}`,
          alertShow: true,
          alertType: 'error',
        });
      }
    } else {
      const answer = _.get(this, `state.answer.${code}`);
      // - construct data
      data = {
        answer,
        sectionItemCode: code,
      };
    }

    // - hit api
    ApiProject.answerSection({
      stepSlug: projectStepSlug,
      answer: data,
    })
      .then((resp) => {
        const alertMessage = _.get(resp, 'data.message[0].message');
        const slug = _.get(this, 'state.slug');
        this.setState({
          alertMessage,
          alertType: 'success',
          alertShow: true,
        });
        if (_.isEqual('fileUpload', type)) {
          this.setState(
            {
              attachment: {
                // eslint-disable-next-line react/no-access-state-in-setstate
                ...this.state.attachment,
                [code]: null,
              },
              // eslint-disable-next-line react/no-access-state-in-setstate
              stepSectionSelectedTab: this.state.stepSectionSelectedTab - 1,
            },
            () => {
              this._getProjectSteps(slug);
              setTimeout(() => {
                // eslint-disable-next-line react/no-access-state-in-setstate
                this.setState({
                  // eslint-disable-next-line react/no-access-state-in-setstate
                  stepSectionSelectedTab: this.state.stepSectionSelectedTab + 1,
                });
              }, 1000);
            }
          );
        } else {
          this.setState({
            answer: {
              // eslint-disable-next-line react/no-access-state-in-setstate
              ...this.state.answer,
              [code]: null,
            },
          });
        }
      })
      .catch((e) => {
        let alertMsg = _.get(e, 'response.data.message');
        alertMsg = alertMsg.map((msg) => `<li>${msg.message}</li>`);
        alertMsg = alertMsg.join('');

        this.setState({
          alertMessage: `Failed to answer: ${`${alertMsg}`}`,
          alertShow: true,
          alertType: 'error',
        });
      });
  };

  _handleInputTextChanged = (e, field, additionalVal = {}) => {
    let { value } = e.target;
    const { type } = e.target;
    const memberObj = 'member.';
    const answerObj = 'answer.';
    const editObj = 'edit.';
    const commentObj = 'comment.';
    const requestMeetObj = 'requestMeet.';
    const isRequestMeetObj = field.includes(requestMeetObj);
    const isMemberObj = field.includes(memberObj);
    const isAnswerObj = field.includes(answerObj);
    const isCommentObj = field.includes(commentObj);
    const isEditObj = field.includes(editObj);

    if (isMemberObj) {
      const member = _.get(this, 'state.member');
      const prop = field.substring(memberObj.length, field.length);
      let validate;

      if (_.isEqual(prop, 'email')) {
        validate = validateEmail(value);
        this.setState({
          isMemberEmailValidated: validate,
        });
      } else if (_.isEqual(prop, 'role')) {
        validate = validateStringLen(value, 1);
        this.setState({
          isMemberRoleValidated: validate,
        });
      } else if (_.isEqual(prop, 'access')) {
        validate = validateStringLen(value, 1);
        this.setState({
          isMemberAccessValidated: validate,
        });
      }

      member[prop] = value;
      this.setState({
        member,
      });
    } else if (isAnswerObj) {
      const code = field.substring(answerObj.length, field.length);
      const answer = _.get(this, 'state.answer');
      if (type === 'checkbox') {
        let answerSection = answer[code];
        if (!_.isNil(answerSection)) {
          const jsonObj = JSON.parse(answerSection);
          jsonObj.push(value);
          answer[code] = JSON.stringify(jsonObj);
        } else {
          answerSection = [];
          answerSection.push(value);
          answer[code] = JSON.stringify(answerSection);
        }
      } else {
        const radiobuttons = document.getElementsByClassName(`answer.${code}`);
        _.forEach(radiobuttons, (btn) => {
          btn.checked = undefined;
        });

        answer[code] = value;
      }

      this.setState({
        answer,
      });
    } else if (isCommentObj) {
      const code = field.substring(commentObj.length, field.length);
      const comment = _.get(this, 'state.comment');
      const validate = !_.isEmpty(value);
      comment[code] = value;

      this.setState({
        comment,
        isCommentDiscussionValidated: validate,
      });
    } else if (isRequestMeetObj) {
      const prop = field.substring(requestMeetObj.length, field.length);
      const requestMeet = _.get(this, 'state.requestMeet');
      let validate = false;
      if (prop === 'notes') {
        validate = !_.isEmpty(value);
        this.setState({ isNotesValidated: validate });
      } else if (prop === 'mentor') {
        value = {
          email: additionalVal.email,
          projectSlug: additionalVal.projectSlug,
          rate_price: additionalVal.rate_price,
        };
        this.setState({ isMentorSelected: true });
      }

      requestMeet[prop] = value;
      this.setState({ requestMeet });
    } else if (isEditObj) {
      const prop = field.substring(editObj.length, field.length);
      const edit = _.get(this, 'state.edit');
      edit[prop] = value;

      this.setState({ edit });
    }
  };

  _handleChooseImage = () => {
    this.uploader.click();
  };

  _handleDateChanged = (_e, date, type) => {
    const validation = {};
    if (_.isEqual(type, 'start_date')) {
      const endDate = _.get(this.state, 'requestMeet.end_date');
      if (!_.isUndefined(endDate)) {
        const endDateMoment = moment(endDate);
        if (moment(date).isSame(endDateMoment, 'day')) {
          _.set(validation, 'isMeetingStartDateValidated', true);
          _.set(validation, 'isMeetingEndDateValidated', true);
        } else {
          _.set(validation, 'isMeetingEndDateValidated', false);
          _.set(validation, 'isMeetingStartDateValidated', false);
        }
      } else {
        _.set(validation, 'isMeetingStartDateValidated', true);
      }
    } else {
      const startDate = _.get(this.state, 'requestMeet.start_date');
      if (!_.isNil(startDate)) {
        const d = moment(startDate).format('DD');
        let strEndDate = moment(date).format('DD/MM/YYYY HH:mm');
        strEndDate = strEndDate.substr(2, strEndDate.length - 1);
        strEndDate = `${d}${strEndDate}`;
        date = moment(strEndDate, 'DD/MM/YYYY HH:mm').toDate();
      }
      if (!_.isUndefined(startDate)) {
        const startDateMoment = moment(startDate);
        if (moment(date).isAfter(startDateMoment)) {
          _.set(validation, 'isMeetingStartDateValidated', true);
          _.set(validation, 'isMeetingEndDateValidated', true);
        } else {
          _.set(validation, 'isMeetingStartDateValidated', false);
          _.set(validation, 'isMeetingEndDateValidated', false);
        }
      } else {
        _.set(validation, 'isMeetingEndDateValidated', true);
      }
    }
    this.setState({
      requestMeet: {
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state.requestMeet,
        [type]: date,
      },
      ...validation,
    });
  };

  _renderMeetRequestModal = () => {
    const startDate = _.get(this.state, 'requestMeet.start_date');
    const endDate = _.get(this.state, 'requestMeet.end_date');
    let diff = Math.ceil(
      Math.abs(moment(startDate).diff(endDate, 'minutes')) / 60
    );
    if (_.isNil(startDate) || _.isNil(endDate)) diff = 0;
    const ratePrice = _.get(this.state, 'requestMeet.mentor.rate_price', 0);
    const total = diff * ratePrice;

    return (
      <Modal show={this.state.showMeetModal} className="modal-meeting-request">
        <div className="container mentor-modal mt-5 mb-4 mx-3">
          <div className="row">
            <h1 className="col mb-5">Mentoring</h1>
          </div>
          {/* Mentor Select */}
          <div className="row">
            <label htmlFor="schedule_mentor" className="col-12">
              Choose a Mentor
            </label>
            <div className="col-12">
              <div className="toggle">
                <div className="row">
                  {/* Mentor */}
                  {_.map(this.state.mentors, (mentor, key) => (
                    <div
                      className={`col-md-6 mt-3 mt-md-0 ${
                        key > 1 ? 'mt-md-3' : ''
                      }`}
                      key={key}
                    >
                      <div className="card">
                        <input
                          onChange={(e) => this._handleInputTextChanged(
                            e,
                            'requestMeet.mentor',
                            mentor
                          )}
                          type="radio"
                          name="listMentor"
                          id={`mentor-${key}`}
                        />
                        <label htmlFor={`mentor-${key}`}>
                          <div className="row mx-0">
                            {/* Mentor Image */}
                            <div className="col-3 px-0">
                              <img
                                onError={(e) => {
                                  e.target.src = UserImage;
                                }}
                                src={mentor.image || UserImage}
                                className="mentor-img rounded-circle"
                                alt="user"
                                style={{
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                            {/* Mentor Info */}
                            <div className="col-9 pl-4 pr-0">
                              <b>{_.get(mentor, 'name')}</b>
                              <p className="mb-2">
                                <p className="mb-0 text-blue-kewirus">
                                  {!_.isNil(mentor.projectName)
                                    ? 'Mentor In Project'
                                    : 'Cabin Crew Class Expert'}
                                </p>
                                {/* {' '}
                                <b>
                                  {_.get(mentor, 'projectName')}
                                </b> */}
                              </p>
                              <p>
                                <p className="mb-1">Active Schedule: </p>
                                <p
                                  className="text-dark"
                                  style={{ whiteSpace: 'pre-line' }}
                                >
                                  {_.get(
                                    mentor,
                                    'mentorInfo.available_schedule',
                                    '-'
                                  ) || '-'}
                                </p>
                              </p>
                              {/* Expert Portofolio */}
                              <div className="col-12 mx-0 px-0">
                                <div className="social-media mx-auto">
                                  <ul>
                                    {_.get(
                                      mentor,
                                      'mentorInfo.linkedin',
                                      null
                                    ) !== null
                                      && _.get(
                                        mentor,
                                        'mentorInfo.linkedin',
                                        null
                                      ) !== '' && (
                                      <li>
                                        <a
                                          href={_.get(
                                            mentor,
                                            'mentorInfo.linkedin',
                                            '#'
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img
                                            src={LinkedinIcon}
                                            alt="linkedin"
                                          />
                                        </a>
                                      </li>
                                    )}
                                    {_.get(
                                      mentor,
                                      'mentorInfo.instagram',
                                      null
                                    ) !== null
                                      && _.get(
                                        mentor,
                                        'mentorInfo.instagram',
                                        null
                                      ) !== '' && (
                                    // eslint-disable-next-line react/jsx-indent
                                      <li>
                                        <a
                                          href={_.get(
                                            mentor,
                                            'mentorInfo.instagram',
                                            '#'
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img src={InstagramIcon} alt="ig" />
                                        </a>
                                      </li>
                                    )}
                                    {_.get(
                                      mentor,
                                      'mentorInfo.portofolio',
                                      null
                                    ) !== null
                                      && _.get(
                                        mentor,
                                        'mentorInfo.portofolio',
                                        null
                                      ) !== '' && (
                                    // eslint-disable-next-line react/jsx-indent
                                      <li>
                                        <a
                                          href={_.get(
                                            mentor,
                                            'mentorInfo.portofolio',
                                            '#'
                                          )}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <img src={WebAppLogo} alt="ig" />
                                        </a>
                                      </li>
                                    )}
                                    {_.get(
                                      mentor,
                                      'mentorInfo.linkedin',
                                      null
                                    ) === null
                                      && _.get(
                                        mentor,
                                        'mentorInfo.instagram',
                                        null
                                      ) === null
                                      && _.get(
                                        mentor,
                                        'mentorInfo.portofolio',
                                        null
                                      ) === null && (
                                    // eslint-disable-next-line react/jsx-indent
                                      <p>No profile info</p>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  ))}
                  {this.state.mentors.length === 0 && (
                    <label className="text-center my-3 mx-auto">
                      No mentors
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mt-3">
              <label htmlFor="schedule_date_picker">Pick Start Date</label>
              <DatePicker
                id="request_meeting_start_date"
                className={`form-control w-100 ${
                  this.state.isMeetingStartDateValidated
                    ? 'is-valid'
                    : _.isNull(this.state.isMeetingStartDateValidated)
                      ? ''
                      : 'is-invalid'
                }`}
                autoComplete="off"
                minDate={this.state.minStartDate}
                showTimeSelect
                placeholderText="Click here!"
                dateFormat="dd/MM/yyyy HH:mm"
                selected={_.get(this.state, 'requestMeet.start_date')}
                onChange={(date, e) => this._handleDateChanged(e, date, 'start_date')}
              />
            </div>
            <div className="col-md-6 mt-3">
              <label htmlFor="schedule_date_picker">Pick End Date</label>
              <DatePicker
                id="request_meeting_end_date"
                className={`form-control w-100 ${
                  this.state.isMeetingEndDateValidated
                    ? 'is-valid'
                    : _.isNull(this.state.isMeetingEndDateValidated)
                      ? ''
                      : 'is-invalid'
                }`}
                autoComplete="off"
                minDate={
                  _.isNil(_.get(this.state, 'requestMeet.start_date'))
                    ? this.state.minStartDate
                    : moment(
                      _.get(this.state, 'requestMeet.start_date')
                    ).toDate()
                }
                maxDate={
                  _.isNil(_.get(this.state, 'requestMeet.start_date'))
                    ? null
                    : moment(
                      _.get(this.state, 'requestMeet.start_date')
                    ).toDate()
                }
                showTimeSelect
                placeholderText="Click here!"
                dateFormat="dd/MM/yyyy HH:mm"
                selected={_.get(this.state, 'requestMeet.end_date')}
                onChange={(date, e) => this._handleDateChanged(e, date, 'end_date')}
              />
            </div>
          </div>
          <div className="row mt-3">
            <label htmlFor="schedule_notes" className="col-12">
              Notes
            </label>
            <div className="col-12">
              <textarea
                className={`form-control ${
                  this.state.isNotesValidated
                    ? 'is-valid'
                    : _.isNull(this.state.isNotesValidated)
                      ? ''
                      : 'is-invalid'
                }`}
                rows="5"
                onChange={(e) => this._handleInputTextChanged(e, 'requestMeet.notes')}
              />
              {!this.state.isNotesValidated && (
                <div className="invalid-feedback">Please input notes field</div>
              )}
            </div>
          </div>
          <div
            className="row mt-3"
            style={{
              display:
                !_.isNil(_.get(this, 'state.requestMeet.mentor.projectSlug'))
                || _.isEmpty(this.state.requestMeet)
                  ? 'none'
                  : '',
            }}
          >
            <label htmlFor="schedule_price" className="col-12">
              Base Price (per hour)
            </label>
            <div className="col-12">
              <h1>{formatCurrency(ratePrice)}</h1>
            </div>
          </div>
          <div
            className="row mt-3"
            style={{
              display:
                !_.isNil(_.get(this, 'state.requestMeet.mentor.projectSlug'))
                || _.isEmpty(this.state.requestMeet)
                  ? 'none'
                  : '',
            }}
          >
            <label htmlFor="schedule_price" className="col-12">
              Total Price
            </label>
            <div className="col-12">
              <h1>{`${formatCurrency(total)} (${diff} hours)`}</h1>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-6">
              <button
                type="button"
                onClick={this._handleCloseModal}
                className="btn btn-secondary-kewirus col-12"
              >
                Close
              </button>
            </div>
            <div className="col-6 mb-4">
              <button
                type="submit"
                disabled={
                  !(
                    this.state.isMeetingEndDateValidated
                    && this.state.isMeetingStartDateValidated
                    && this.state.isNotesValidated
                    && this.state.isMentorSelected
                  )
                }
                onClick={this._handleInviteMentor}
                className="btn btn-kewirus col-12"
              >
                Invite
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  _renderContent = (it) => {
    const { name } = it[0];
    const { stepSections } = this.state;

    if (_.isEqual(name, 'Overview')) {
      // - first slide of project
      // - render overview section
      const { members } = it[0];
      const { description, thumbnail } = this.state;

      return (
        <div>
          {/* Project Thumbnail & Description */}
          <div className="row pt-2 pb-4">
            {/* Thumbnail */}
            <div className="col-md">
              <img
                onError={(e) => {
                  e.target.src = NoImageThumbnail;
                }}
                src={
                  _.isNil(this.state.imageBase64)
                    ? thumbnail || NoImageThumbnail
                    : this.state.imageBase64
                }
                alt="Thumbnail project"
                width="100%"
                style={{
                  maxHeight: '60vh',
                  borderRadius: '10px',
                  objectFit: 'cover',
                  cursor: this.state.isEditingProject ? 'pointer' : '',
                }}
                onClick={(e) => {
                  const { isEditingProject } = this.state;
                  if (isEditingProject) {
                    this._handleChooseImage(e);
                  }
                }}
              />
              <input
                type="file"
                accept="image/*"
                id="file"
                ref={(ref) => {
                  this.uploader = ref;
                }}
                style={{ display: 'none' }}
                onChange={this._onChangeFileProjectDetail}
              />
              {this.state.isEditingProject ? (
                <div
                  className="row mx-auto"
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '11px',
                  }}
                >
                  <div className="col pl-0 pr-1">
                    <button
                      type="button"
                      className="action-button-edit btn"
                      onClick={(e) => this._handleChooseImage(e)}
                      onError={(e) => {
                        e.target.src = NoImageThumbnail;
                      }}
                    >
                      <span className="text-white">
                        <i className="fa fa-pencil" aria-hidden="true" />
                      </span>
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
            {/* Description */}
            <div className="col-md">
              <h1 className="mb-3 mt-5 mt-md-0">Project Description</h1>
              {this.state.institution && !this.state.isEditingProject && (
                <div className="row mx-0 mb-4">
                  <div className="col-12 px-0">
                    <p className="text-grey-kewirus">Powered by</p>
                  </div>
                  <div className="col-auto px-0">
                    <img
                      className="mr-3 rounded-circle"
                      width="48px"
                      height="48px"
                      src={this.state.institution.logo || AppLogo}
                      onError={(e) => {
                        e.target.src = AppLogo;
                      }}
                      alt="project_institution_logo"
                      style={{
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <div className="col-auto d-flex align-items-center px-0">
                    <b className="">{_.get(this.state, 'institution.name')}</b>
                  </div>
                </div>
              )}
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Enter institution code here"
                onChange={(e) => this._handleInputTextChanged(e, 'edit.institution.code')}
                style={{ display: this.state.isEditingProject ? '' : 'none' }}
              />
              <p
                className="col-12 px-0"
                style={{ display: this.state.isEditingProject ? 'none' : '' }}
              >
                {description}
              </p>
              <textarea
                className="col-12  form-control"
                defaultValue={description}
                value={this.state.edit.description}
                onChange={(e) => this._handleInputTextChanged(e, 'edit.description')}
                style={{ display: this.state.isEditingProject ? '' : 'none' }}
                rows={10}
              />
            </div>
          </div>
          {/* Title Project Team */}
          <div className="row mx-0 mt-4">
            <PageTitle title="Project Team" subtitle="" />
          </div>
          {/* Project Team Card */}
          <div className="row">
            {members.map((member, idx) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`member-${idx}`}
                className="col-sm-12 col-md-auto py-2 pr-0 pl-3 plsm-2"
              >
                <ProjectTeam
                  role={member.role}
                  name={member.name}
                  access={member.access}
                  avatar={member.avatar}
                  deleteAccess={
                    !_.chain(members)
                      .filter(
                        (members,
                        (_member) => _member.access === 'admin'
                          && _member.email === this.state.user.email)
                      )
                      .isEmpty()
                      .value()
                  }
                  isSuper={
                    !_.chain(members)
                      .filter(
                        (members,
                        (_member) => _member.role === 'owner'
                          && _member.email === this.state.user.email)
                      )
                      .isEmpty()
                      .value()
                  }
                  onItemClick={() => {
                    this.setState({
                      modalDeleteMember: true,
                      selectedMemberToDelete: member.email,
                    });
                  }}
                />
              </div>
            ))}
            <div
              onClick={() => {
                this.setState({
                  modalAddMember: true,
                });
              }}
              style={{ cursor: 'pointer' }}
              className="col-sm-12 col-md-auto py-2 pr-0 pl-3 plsm-2"
            >
              <div className="row mx-0 py-2 container-projectteam h-100">
                {/* Team Image */}
                <div className="col-auto pr-0 d-flex align-items-center">
                  <i className="fa fa-plus" aria-hidden="true" />
                </div>
                {/* Team Identity */}
                <div className="col align-self-center">
                  <b>Add member</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // if want to render 'step' section
    return (
      <div>
        {/* NavTabs for Step Section */}
        <div className="mt-4 mx-0">
          <NavTabs sections={stepSections} onTabClick={() => {}} />
        </div>
      </div>
    );
  };

  _renderInstructions = (it) => {
    const sections = it;
    const renderSectionContent = (sectionItem) => {
      const { type } = sectionItem;
      let view;

      switch (type) {
      case 'image':
        view = (
          <div className="col-md-5 p-0 mr-md-3 col-xs-12 text-center">
            <img
              src={sectionItem.filePath || NoImageThumbnail}
              onError={(e) => {
                e.target.src = NoImageThumbnail;
              }}
              alt="thumbnail-step-section"
              width="100%"
              style={{
                borderRadius: '10px',
                objectFit: 'cover',
              }}
            />
          </div>
        );
        break;
      case 'youtube':
        view = (
          <iframe
            title="section-video"
            className="iframe-video col-xs-12 col-md-6 p-0 mr-md-3"
            src={_.replace(
              _.split(sectionItem.url, '&')[0],
              'watch?v=',
              'embed/'
            )}
            allowFullScreen
            frameBorder="0"
          />
        );
        break;
      default:
        break;
      }
      return view;
    };

    const renderMisc = (sectionItem) => {
      const { type, code } = sectionItem;
      let view;

      switch (type) {
      case 'radio':
      case 'checkbox':
        // eslint-disable-next-line no-case-declarations
        const checkAnswer = (opt) => {
          if (_.isEqual(type, 'checkbox')) {
            const answers = JSON.parse(sectionItem.answer);
            if (_.isNil(this.state.answer[code]) && !_.isEmpty(answers)) {
              this.setState({
                answer: {
                  [code]: JSON.stringify(answers),
                },
              });
            }
            return _.includes(answers, opt);
          }

          return _.isEqual(opt, sectionItem.answer);
        };

        view = _.map(sectionItem.options, (opt) => (
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <input
                  type={type}
                  className={type === 'radio' ? `answer.${code}` : ''}
                  onChange={(e) => this._handleInputTextChanged(e, `answer.${code}`)}
                  value={opt}
                  {...(!_.isNil(this.state.answer[code])
                      && type === 'radio' && {
                    checked: this.state.answer[code] === opt,
                  })}
                  {...(!_.isNil(sectionItem.answer) && {
                    defaultChecked: checkAnswer(opt),
                  })}
                  {...(type === 'radio'
                      && _.isNil(sectionItem.answer) && {
                    defaultChecked: this.state.answer[code] === opt,
                  } && { checked: this.state.answer[code] === opt })}
                />
              </div>
            </div>
            <p className="m-0 ml-3">{opt}</p>
          </div>
        ));

        // eslint-disable-next-line no-case-declarations
        view.push(
          <div className="col-12">
            <button
              type="button"
              className="btn btn-kewirus float-right"
              style={{
                display: this.state.hasEditAnswerAccess ? '' : 'none',
              }}
              onClick={() => this._handleSaveAnswer(code, type)}
            >
              Save answer
            </button>
          </div>
        );
        break;
      case 'textarea':
        // eslint-disable-next-line no-case-declarations
        const answer = _.get(sectionItem, 'answer');
        view = [
          <textarea
            className="form-control"
            placeholder="Enter your answer"
            rows="4"
            value={this.state.answer[code] || answer}
            required
            onChange={(e) => this._handleInputTextChanged(e, `answer.${code}`)}
          />,
        ];
        // eslint-disable-next-line no-case-declarations
        view.push(
          <div className="col-12 mt-4">
            <button
              type="button"
              className="btn btn-kewirus float-right"
              disabled={_.isNil(this.state.answer[code]) && _.isNil(answer)}
              style={{
                display: this.state.hasEditAnswerAccess ? '' : 'none',
              }}
              onClick={() => this._handleSaveAnswer(code, 'textarea')}
            >
              Save answer
            </button>
          </div>
        );
        break;
      case 'fileUpload':
        // eslint-disable-next-line no-case-declarations
        const acceptTypes = 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*';
        // eslint-disable-next-line no-case-declarations
        const attachment = _.get(this, `state.attachment.${code}`);
        // - set view
        view = [];
        view.push(
          <div className="col-12 mt-3">
            <div
              style={{
                display: this.state.hasEditAnswerAccess ? 'none' : '',
              }}
            >
              <p className="text-danger">
                Read access does not have permission to upload file
              </p>
            </div>
            <input
              type="file"
              accept={acceptTypes}
              id={code}
              ref={(ref) => {
                this[`uploader${code}`] = ref;
              }}
              onChange={this._onChangeFile}
              hidden
            />
            <label htmlFor={code} style={{ cursor: 'pointer' }}>
              <span className="btn-choose-file-kewirus mr-2">
                Choose File
              </span>
            </label>
            <span>
              {_.chain(sectionItem).get('answer').split('/').last()
                .value()
                  || 'No chosen File'}
            </span>
            {!_.isNil(attachment) && (
              <div className="mt-3">
                <p>File to upload:</p>
                <ul>
                  <li>
                    Name:
                    {` ${_.get(attachment, 'name')}`}
                  </li>
                  <li>
                    Size:
                    {` ${_.get(attachment, 'size') / 1000} KB (max: 5MB)`}
                  </li>
                  <li>
                    File type:
                    {` ${_.get(attachment, 'type')}`}
                  </li>
                </ul>
                <button
                  type="button"
                  className="btn btn-kewirus float-right"
                  disabled={_.isNil(attachment)}
                  style={{
                    display: this.state.hasEditAnswerAccess ? '' : 'none',
                  }}
                  onClick={() => this._handleSaveAnswer(code, 'fileUpload')}
                >
                  Upload
                </button>
              </div>
            )}
          </div>
        );

        if (!_.isEmpty(sectionItem.answer)) {
          view.push(
            <a
              href={sectionItem.answer}
              className="col-md-12 ml-3 p-0 project-submission-file mt-4"
            >
              Your previous answer
            </a>
          );
        }
        break;
      default:
        break;
      }

      return view;
    };

    const renderParagraphContent = (items) => _.map(items, (sectionItem, idx) => (
      <>
        <div className="row col-12 mx-auto">
          {idx % 2 === 0 && sectionItem.type === 'youtube' ? (
            <>
              {/* Render thumbnail or yt video */}
              {renderSectionContent(sectionItem)}
              {/* Description */}
              <div className="col-md px-0">
                <p className="px-1 mt-3 mt-md-0">{sectionItem.description}</p>
              </div>
            </>
          ) : (
            <>
              {/* Description */}
              <div className="col-md px-0">
                <p className="px-1 mt-3 mt-md-0">{sectionItem.description}</p>
              </div>
              {/* Render thumbnail or yt video */}
              {renderSectionContent(sectionItem)}
            </>
          )}
          {/* Checkbox, Radiobutton */}
          {renderMisc(sectionItem)}
          {/* Resource IF EXIST */}
          {_.isEqual(sectionItem.type, 'material') && (
            <div className="resource-step-section col-auto px-3 py-2 ml-md-4 mr-md-0 mx-auto">
              <b>
                Resource -
                {' '}
                {sectionItem.material.name}
              </b>
              <p>Go to this course! </p>
              <Link
                to={{
                  pathname: `/course/detail/${sectionItem.material.slug}`,
                  state: {
                    redirectPath: this.state.currentPath,
                  },
                }}
              >
                Click here to view
              </Link>
            </div>
          )}
        </div>
        {idx < _.get(items, 'length') - 1 && (
          <hr className="hr-text mb-4" data-content="" />
        )}
      </>
    ));
    const renderContent = (items) => _.map(items, (item, idx) => (
      <div className={`row mx-0 ${idx <= items.length - 1 ? 'mb-5' : ''}`}>
        {/* Title */}
        <h2 className="title-step-section p-3 mb-2 mb-md-5">{item.name}</h2>
        {/* Paragraph / Main content */}
        {renderParagraphContent(item.sectionItems)}
      </div>
    ));

    return (
      <div className="p-4 container-step-navtabs mr-4">
        {/* Header */}
        <div className="row mb-4 mx-0">
          <h2>Instruction</h2>
        </div>

        {renderContent(sections)}
      </div>
    );
  };

  _renderDiscussion = (discussions = []) => {
    const { user } = this.state;
    const { photoUrl } = user;
    const overviewSection = _.head(this.state.sections);
    const members = _.get(overviewSection, 'items[0].members');
    const userProfile = (img) => _.find(members, { avatar: img });
    const convertToProperDate = (date) => moment(date).format('DD MMMM YYYY HH:mm');

    return (
      <div className="p-4 container-step-navtabs mr-4">
        {/* Header */}
        <div className="row mb-3 mx-0">
          <h2>Discussion</h2>
        </div>
        {/* Content */}
        {/* Add Comment */}
        <div>
          {/* Identity */}
          <div className="row mx-0">
            {/* User Image */}
            <div className="col-auto pl-0">
              <img
                onError={(e) => {
                  e.target.src = UserImage;
                }}
                src={photoUrl || UserImage}
                width="50"
                height="50"
                alt="user"
                className="rounded-circle"
                style={{
                  objectFit: 'cover',
                }}
              />
            </div>
            {/* Name - Position */}
            <div className="col align-self-center pl-0">
              <b>{_.get(user, 'displayName')}</b>
              {' '}
              -
              {' '}
              {_.get(userProfile(photoUrl), 'role')}
            </div>
          </div>
          {/* Text Area */}
          <div className="row pt-2 mx-0">
            <form className="w-100">
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows="3"
                  value={this.state.comment.root}
                  onChange={(e) => this._handleInputTextChanged(e, 'comment.root')}
                  placeholder="Add a comment"
                />
              </div>
            </form>
          </div>
          {/* Submit Button */}
          <div className="row mx-0 d-flex justify-content-end">
            <button
              onClick={(e) => this._handleCommentOrLike(e, 0, 'comment')}
              type="button"
              className="btn btn-kewirus"
              disabled={!this.state.isCommentDiscussionValidated}
            >
              Comment
            </button>
          </div>
        </div>
        {/* Commented */}
        <div className="mt-5">
          {discussions.map((discussion, idx) => (
            <>
              {idx > 0 && <hr />}
              <div className={`row mx-0 ${idx >= 1 ? 'mt-5' : ''}`}>
                {/* User Image */}
                <div className="col-auto pl-0">
                  <img
                    onError={(e) => {
                      e.target.src = UserImage;
                    }}
                    src={_.get(discussion, 'user.photoUrl') || UserImage}
                    width="50"
                    height="50"
                    alt="user"
                    className="rounded-circle"
                    style={{
                      objectFit: 'cover',
                    }}
                  />
                </div>
                {/* Name - Position */}
                <div className="col align-self-center pl-0">
                  <div>
                    <b>{_.get(discussion, 'user.name')}</b>
                    {' '}
                    -
                    {' '}
                    {_.get(userProfile(discussion.user.photoUrl), 'role')}
                  </div>
                  <div className="text-grey-kewirus">
                    {convertToProperDate(_.get(discussion, 'createdAt'))}
                  </div>
                </div>
              </div>
              {/* User Comment */}
              <div className="row mx-0 mt-3 mx-2">
                <p>{_.get(discussion, 'comment')}</p>
              </div>
              {/* User Like */}
              {/* <div className="row mx-0 mt-2 text-blue-kewirus">
                <span className="mt-1">
                  { _.get(discussion, 'like') }
                  {' '}
                  likes
                </span>
                <span
                  className="text-black ml-2 thumb-up-icon"
                  onClick={(e) => this._handleCommentOrLike(e, _.get(discussion, 'id'), 'like')}
                >
                  <i className="fa fa-thumbs-up" aria-hidden="true" />
                </span>
              </div> */}
            </>
          ))}
        </div>
      </div>
    );
  };

  _renderSubmission = (it) => {
    const submissionStatus = _.get(it, 'submissionStatus');
    const submissionDeadline = _.get(it, 'submissionDeadline');
    const submissions = _.get(it, 'submissions');
    const lastSubmit = _.last(submissions);
    const tmplt = 'dddd, DD MMMM YYYY[,] hh:mm A';
    const lastSubmitModified = _.get(lastSubmit, 'created_at');
    const isSubmitted = _.isEqual(submissionStatus, 'SUBMITTED');
    const now = moment();
    const deadlineMoment = moment(submissionDeadline);
    // - checking deadline
    const diff = now.diff(deadlineMoment);
    const diffInHours = now.diff(deadlineMoment, 'hour');
    const diffInDays = now.diff(deadlineMoment, 'days');
    const isOverdue = diff > 0;

    return (
      <div className="p-4 container-step-navtabs mr-4">
        {/* Header */}
        <div className="row mb-3 mx-0">
          <h2>Submission</h2>
        </div>
        {/* Content */}
        {/* Submission Status */}
        <div className="row my-2">
          <div className="text-grey-kewirus col-md-4">Submission status</div>
          <div
            className={`col-md-8 ${
              isSubmitted && !_.isEmpty(it.submissions)
                ? 'text-success'
                : 'text-black'
            }`}
          >
            {isSubmitted && !_.isEmpty(it.submissions)
              ? 'Submitted'
              : 'Not Submitted'}
          </div>
        </div>
        {/* Grading Status */}
        {/* <div className="row my-2">
          <div className="text-grey-kewirus col-md-4">Grading status</div>
          <div className="col-md-8">Not graded</div>
        </div> */}
        {/* Deadline */}
        <div className="row my-2">
          <div className="text-grey-kewirus col-md-4">Due date</div>
          <div className="col-md-8">
            {moment(submissionDeadline).format(tmplt)}
          </div>
        </div>
        {/* Time Remaining */}
        <div className="row my-2">
          <div className="text-grey-kewirus col-md-4">Time remaining</div>
          {isOverdue && !isSubmitted ? (
            <div className="col-md-8 text-danger">
              Assignment is overdue by:
              {' '}
              {diffInDays}
              {' '}
              days
              {' '}
              {diffInHours}
              {' '}
              hours
            </div>
          ) : isSubmitted && !_.isEmpty(it.submissions) ? (
            <div className="col-md-8 text-success">
              Assignment is already submitted
            </div>
          ) : (
            <div className="col-md-8">-</div>
          )}
          {_.map(submissions, ({ filename, ...submission }, idx) => (
            <>
              <div className="col-md-4 text-grey-kewirus mt-3">
                {idx === 0 && 'Submission Files'}
              </div>
              <div className="col-md-7 col-10">
                <li
                  className={`list-group-item mb-2 rounded ${
                    idx === 0 ? 'mt-3' : ''
                  } ${idx === submissions.length - 1 ? 'mb-3' : ''}`}
                >
                  <a href={filename}>
                    <span className="text-blue-kewirus">
                      {filename.split('host/document-')[1]
                        || filename.split('host/image-')[1]}
                    </span>
                  </a>
                </li>
              </div>
              <div
                className={`${idx === 0 ? 'mt-3' : ''} ${
                  idx === submissions.length - 1 ? 'mb-3' : ''
                } col-1 px-1`}
              >
                <button
                  type="button"
                  className="action-button-delete btn"
                  style={{ display: isOverdue ? 'none' : '' }}
                  onClick={() => {
                    // open modal to delete submission
                    this.setState({
                      modalDeleteSubmission: true,
                      selectedSubmission: {
                        filename:
                          filename.split('host/document-')[1]
                          || filename.split('host/image-')[1],
                        ...submission,
                      },
                    });
                  }}
                >
                  <span
                    style={{
                      fontSize: '20px',
                      color: '#ff0000',
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true" />
                  </span>
                </button>
              </div>
            </>
          ))}
        </div>
        {/* Last Modified */}
        {!_.isUndefined(lastSubmitModified) && (
          <div className="row my-2">
            <div className="text-grey-kewirus col-md-4">Last modified</div>
            <div className="col-md-8">
              {moment(lastSubmitModified).format(tmplt)}
            </div>
          </div>
        )}

        {/* Submit */}
        {!isOverdue && (
          <>
            {/* File Submission */}
            <div className="row my-2">
              <div className="text-grey-kewirus col-md-4">File submission</div>
              <div className="col-md-8">
                <form>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control-file"
                      multiple
                      onChange={this._onChangeSubmissionFile}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-4" />
              <div className="col-md-8">
                <button
                  type="submit"
                  className="btn btn-kewirus"
                  disabled={_.isEmpty(this.state.submissions)}
                  onClick={this._handleSubmitFile}
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  _renderDeleteSubmissionModal = () => (
    <Modal show={this.state.modalDeleteSubmission}>
      <div className="container mentor-modal mt-5 mb-4 mx-3">
        <div className="row">
          <h1 className="col mb-3">
            Are you sure want to delete this submission?
          </h1>
        </div>
        <div className="row">
          <p className="col-12 mb-4">
            Filename
            {': '}
            {_.get(this, 'state.selectedSubmission.filename')}
          </p>
          <div className="col-6">
            <button
              type="button"
              onClick={() => {
                this.setState({ modalDeleteSubmission: false });
              }}
              className="btn m-up mt-4 col-12 border-blue-kewirus"
            >
              <b className="text-blue-kewirus">Cancel</b>
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              onClick={this._handleDeleteSubmission}
              className="mt-4 btn submit col-12 btn-kewirus"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );

  _renderDeleteMemberModal = () => (
    <Modal show={this.state.modalDeleteMember}>
      <div className="container mentor-modal mt-5 mb-4 mx-3">
        <div className="row">
          <h1 className="col mb-3" style={{ lineHeight: '1.5rem' }}>
            Are you sure want to remove this project member from your project?
          </h1>
        </div>
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={() => {
                this.setState({ modalDeleteMember: false });
              }}
              className="btn m-up mt-4 col-12 border-blue-kewirus"
            >
              <b className="text-blue-kewirus">Cancel</b>
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              onClick={this._handleDeleteProjectMember}
              className="mt-4 btn submit col-12 btn-kewirus"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );

  _renderModalAddMember = () => (
    <Modal show={this.state.modalAddMember}>
      <div className="container mt-5 mb-4 mx-3">
        <div className="row">
          <h1 className="col mb-5">Add new member</h1>
        </div>
        <div className="row">
          <label className="col-3 mb-0">Email</label>
          <div className="col-9">
            <input
              type="email"
              className={`form-control ${
                this.state.isMemberEmailValidated
                  ? 'is-valid'
                  : _.isNull(this.state.isMemberEmailValidated)
                    ? ''
                    : 'is-invalid'
              }`}
              required
              value={this.state.member.email}
              onChange={(e) => this._handleInputTextChanged(e, 'member.email')}
              placeholder="Enter email address"
            />
          </div>
        </div>
        <div className="row mt-3">
          <label className="col-3 mb-0">Role</label>
          <div className="col-9">
            <select
              className={`form-control ${
                this.state.isMemberRoleValidated
                  ? 'is-valid'
                  : _.isNull(this.state.isMemberRoleValidated)
                    ? ''
                    : 'is-invalid'
              }`}
              value={this.state.member.role}
              onChange={(e) => this._handleInputTextChanged(e, 'member.role')}
            >
              <option disabled value="">
                Choose...
              </option>
              {_.map(roles, (role) => (
                <option value={role.value}>{role.text}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-3">
          <label className="col-3 mb-0">Access</label>
          <div className="col-9">
            <select
              className={`form-control ${
                this.state.isMemberAccessValidated
                  ? 'is-valid'
                  : _.isNull(this.state.isMemberAccessValidated)
                    ? ''
                    : 'is-invalid'
              }`}
              value={this.state.member.access}
              onChange={(e) => this._handleInputTextChanged(e, 'member.access')}
            >
              <option disabled value="">
                Choose...
              </option>
              <option value="read">Read</option>
              <option value="read_write">Read & Write</option>
              <option value="admin">Admin</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={this._handleCloseModal}
              className="btn btn-secondary-kewirus mt-5 col-12"
            >
              Close
            </button>
          </div>
          <div className="col-6">
            <button
              type="submit"
              disabled={
                !(
                  this.state.isMemberEmailValidated
                  && this.state.isMemberRoleValidated
                  && this.state.isMemberAccessValidated
                )
              }
              onClick={this._addMember}
              className="mt-5 btn btn-kewirus col-12"
            >
              + Add Member
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );

  render() {
    const { sections } = this.state;

    return (
      <div className="container-project-detail col container-page-kewirus">
        {/* Alert */}
        {this.state.alertShow && (
          <div
            className={`alert ${
              _.isEqual(this.state.alertType, 'error')
                ? 'alert-danger'
                : 'alert-success'
            } alert-dismissible fade show`}
            role="alert"
            style={{
              position: 'fixed',
              top: '10%',
              zIndex: '10',
            }}
          >
            <p>{parse(this.state.alertMessage)}</p>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => this.setState({ alertMessage: '', alertShow: false })}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        {/* Modal */}
        {this._renderModalAddMember()}
        {this._renderDeleteSubmissionModal()}
        {this._renderDeleteMemberModal()}
        {/* Modal: meeting request modal */}
        {this._renderMeetRequestModal()}
        {/* Search Bar and Logo */}
        <div className="row">
          {/* Logo */}
          <a href="/" className="kewirus-desktop-only col-auto mb-5 ml-auto">
            <img src={AppLogo} className="app-logo" alt="Cabin Crew Class Logo" />
          </a>
        </div>
        {/* Title */}
        <div className="row">
          <div className="col-md-auto col-7">
            <PageTitle
              style={{ display: this.state.isEditingProject ? 'none' : '' }}
              title={`${this.state.name}`}
              subtitle={`${this.state.members} Members`}
            />
            <input
              type="text"
              className="form-control mb-5"
              defaultValue={this.state.name}
              value={this.state.edit.title}
              onChange={(e) => this._handleInputTextChanged(e, 'edit.title')}
              style={{ display: this.state.isEditingProject ? '' : 'none' }}
            />
          </div>
          <div className="col-md-auto col-5">
            <span
              style={{
                fontSize: 20,
                cursor: 'pointer',
                background: '#AD0000',
                borderRadius: '5px',
                paddingRight: '5px',
                paddingLeft: '8px',
                color: '#ffffff',
                display:
                  this.state.isEditingProject || !this.state.hasEditAccess
                    ? 'none'
                    : '',
              }}
              className="text-black float-md-left float-right"
              onClick={() => {
                this.setState({
                  isEditingProject: true,
                  edit: {
                    title: _.get(this, 'state.name'),
                    description: _.get(this, 'state.description'),
                  },
                });
              }}
            >
              <i className="fa fa-pencil-square-o" aria-hidden="true" />
            </span>
            <button
              type="button"
              className="btn btn-secondary-kewirus mr-md-3 mr-1 mb-3"
              onClick={() => {
                this.setState({
                  edit: {
                    // eslint-disable-next-line react/no-access-state-in-setstate
                    title: this.state.name,
                    // eslint-disable-next-line react/no-access-state-in-setstate
                    description: this.state.description,
                  },
                  isEditingProject: false,
                  imageBase64: null,
                  thumbnailEdit: null,
                });
              }}
              style={{ display: this.state.isEditingProject ? '' : 'none' }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-kewirus float-right"
              style={{ display: this.state.isEditingProject ? '' : 'none' }}
              onClick={this._handleUpdateProject}
            >
              Save
            </button>
          </div>
          <div className="col text-right my-md-0 mt-3 mb-4">
            <button
              type="button"
              className="btn btn-kewirus"
              onClick={() => this.setState({ showMeetModal: true })}
              style={{
                display: _.includes(
                  ['institution', 'mentor'],
                  this.state.user.kewirusType
                ) ? 'none' : ''
              }}
            >
              + Mentoring
            </button>
          </div>
        </div>
        <div className="row mx-0">
          <ProjectStep
            sections={sections}
            onTabClick={(_e, idx) => {
              this.setState({
                stepSectionSelectedTab: idx,
                alertShow: false,
              });
            }}
          />
        </div>
      </div>
    );
  }
}

export default ClassDetail;
