import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import parse from 'html-react-parser';
import { Redirect } from 'react-router';
import { Bar, Doughnut, HorizontalBar } from 'react-chartjs-2';

import AppLogo from '../../assets/app-logo.png';
import ApiProject from '../../services/api/project';
import { Card, PageTitle } from '../../components';

const stackedBarOpts = {
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    const jsonUsr = localStorage.getItem('kewirus_u') || '{}';
    const user = JSON.parse(jsonUsr);

    this.state = {
      user,
      totalProjectDataset: {},
      totalProjectInMonthsDataset: {},
      totalUnpublishAndPublishedProjectDataset: {},
      alertMsg: '',
      alertShow: false,
      alertType: '',
    };
  }

  componentDidMount() {
    ApiProject.getProjects()
      .then((res) => {
        const totalProjectZeroProgress2 = _.times(12, _.constant(0));
        const totalProjectInProgress2 = _.times(12, _.constant(0));
        const totalProjectInComplete2 = _.times(12, _.constant(0));

        const data = _.get(res, 'data.data');
        const totalProject = _.get(data, 'length');
        const totalUnpublishedProject = _.filter(data, (d) => !d.isPublish).length;
        const totalPublishedProject = _.filter(data, (d) => d.isPublish).length;
        const projectInProgress = _.filter(data, (d) => d.progress >= 0 && d.progress < 100);
        const projectCompleted = _.filter(data, (d) => d.progress === 100);

        // total projects
        const totalProjectCompleted = projectCompleted.length;
        const totalProjectInProgress = projectInProgress.length;
        const isBelow480 = window.innerWidth < 480;
        const totalProjects = {
          labels: [
            isBelow480 ? 'Total' : 'Total Project In Total',
            isBelow480 ? 'InProgress' : 'Total Project (In progress)',
            isBelow480 ? 'Completed' : 'Total Project (Completed)'],
          datasets: [
            {
              label: '#Projects',
              backgroundColor: 'rgba(255,99,132,0.2)',
              borderColor: 'rgba(255,99,132,1)',
              borderWidth: 1,
              hoverBackgroundColor: 'rgba(255,99,132,0.4)',
              hoverBorderColor: 'rgba(255,99,132,1)',
              data: [totalProject, totalProjectInProgress, totalProjectCompleted]
            }
          ]
        };

        // total project in months, stacked bar
        _.map(projectInProgress, (project) => {
          const createdAt = moment(_.get(project, 'createdAt'), 'DD MMMM YYYY');
          const monthIdx = createdAt.month();
          const year = createdAt.year();
          const yearNow = moment().year();
          if (year === yearNow) {
            if (project.progress === 0) totalProjectZeroProgress2[monthIdx] += 1;
            else totalProjectInProgress2[monthIdx] += 1;
          }
        });

        _.map(projectCompleted, (project) => {
          const createdAt = moment(_.get(project, 'createdAt'), 'DD MMMM YYYY');
          const monthIdx = createdAt.month();
          const year = createdAt.year();
          const yearNow = moment().year();

          if (year === yearNow) {
            totalProjectInComplete2[monthIdx] += 1;
          }
        });

        const totalProjectInMonthsDataset = {
          labels: moment.monthsShort(),
          datasets: [
            {
              label: '# of 0% Progress Project',
              data: totalProjectZeroProgress2,
              backgroundColor: 'rgb(255, 99, 132)',
            },
            {
              label: '# of In Progress Project',
              data: totalProjectInProgress2,
              backgroundColor: 'rgb(54, 162, 235)',
            },
            {
              label: '# of Completed Project',
              data: totalProjectInComplete2,
              backgroundColor: 'rgb(75, 192, 192)',
            },
          ],
        };

        // diagram doughnut chart
        const totalUnpublishAndPublishedProjectDataset = {
          labels: ['Draft Projects in total (unpublished)', 'Published Projects in total'],
          datasets: [
            {
              label: '# of Votes',
              data: [totalUnpublishedProject, totalPublishedProject],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
              ],
              borderWidth: 1,
            },
          ],
        };

        this.setState({
          totalProjectDataset: totalProjects,
          totalProjectInMonthsDataset,
          totalUnpublishAndPublishedProjectDataset
        });
      });
  }

  _renderDashboard = () => (
    <div className="col container-page-kewirus">
      {
        this.state.alertShow && (
          <div
            className={`alert ${_.isEqual(this.state.alertType, 'error') ? 'alert-danger' : 'alert-success'} alert-dismissible fade show`}
            role="alert"
            style={{
              position: 'fixed',
              top: '10%',
              zIndex: '10'
            }}
          >
            <p>{parse(this.state.alertMsg)}</p>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.setState({ alertMsg: '', alertShow: false })}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )
      }
      {/* Search Bar and Logo */}
      <div className="row">
        {/* Logo */}
        <a href="/" className="kewirus-desktop-only col-auto mb-5 ml-auto">
          <img src={AppLogo} className="app-logo" alt="Cabin Crew Class Logo" />
        </a>
      </div>
      {/* Title */}
      <div className="row mx-0 my-2">
        <PageTitle
          title="Dashboard"
        />
      </div>
      {/* Card */}
      <Card className="p-5 card-profile">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center mb-5">
              Published / Unpublished Projects in
              {` ${moment().year()}`}
            </h1>
          </div>
          <div className="col-md-6">
            <Doughnut
              height={window.innerWidth < 480 ? 500 : 175}
              data={this.state.totalUnpublishAndPublishedProjectDataset}
            />
          </div>
          <div className="col-md-6 mt-3 mt-md-5">
            {/* Bar Chart */}
            <HorizontalBar
              data={this.state.totalProjectDataset}
              width={100}
              height={window.innerWidth > 480 ? 200 : 150}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </div>
          <div className="col-12 mt-5" style={{ minHeight: '40vh' }}>
            <h1 className="text-center mb-5">
              Projects chart progress in
              {` ${moment().year()}`}
            </h1>
            <Bar
              // eslint-disable-next-line max-len
              height={window.innerWidth > 480 ? window.innerHeight * 0.2 : window.innerHeight * 0.5}
              data={this.state.totalProjectInMonthsDataset}
              options={stackedBarOpts}
            />
          </div>
        </div>
      </Card>
    </div>
  )

  render() {
    const { user } = this.state;
    const isInstitution = _.isEqual(user.kewirusType, 'institution');

    return isInstitution ? this._renderDashboard() : <Redirect to="/404-not-found" />;
  }
}

export default Dashboard;
