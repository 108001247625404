export default [
  {
    name: 'Dashboard',
    url: 'dashboard',
    icon: 'fa fa-bar-chart',
    viewAccess: ['institution'],
  },
  {
    name: 'Class',
    url: 'class',
    icon: 'fa fa-folder-open',
    viewAccess: ['user', 'mentor', 'institution', 'admin', 'member'],
  },
  {
    name: 'Courses',
    url: 'courses',
    icon: 'fa fa-book',
    viewAccess: ['user', 'mentor', 'admin', 'member'],
  },
  {
    name: 'Events',
    url: 'events',
    icon: 'fa fa-calendar-check-o',
    viewAccess: ['user', 'mentor', 'institution', 'admin', 'member'],
  },
  {
    name: 'Mentors',
    url: 'experts',
    icon: 'fa fa-user',
    viewAccess: ['user', 'mentor', 'admin', 'member']
  },
  {
    name: 'Schedule',
    url: 'schedule',
    icon: 'fa fa-calendar',
    badge: true,
    renderBadge: 'schedule', // name of function to get total badge
    viewAccess: ['user', 'mentor', 'institution', 'admin', 'member'],
  },

  // - most bottom menu will be placed on bottom of page
  {
    name: 'LOG OUT',
    url: 'logout',
    icon: 'fa fa-sign-out',
    viewAccess: ['user', 'mentor', 'institution', 'admin'],
  },

  // Add another routes here!
];
